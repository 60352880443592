import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayoutCustom from "examples/LayoutContainers/DashboardLayoutCustom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import { getSingleBusinessAysnc } from "features/actions/auth";
import Loader from "assets/loader/loader";
import backDashboard from "assets/images/backDashboard.svg";
import { Link } from "react-router-dom";

export default function ViewBusiness() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state || {};
  const { authLoading, getSingleBusiness } = useSelector((state) => state.auth);

  const [values, setValues] = useState({
    restaurant_name: "",
    business_address: "",
    business_yelp_page: "",
    business_fb_page: "",
    business_google_page: "",
    business_twitter_page: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(getSingleBusinessAysnc(id));
    }
  }, [id]);

  useEffect(() => {
    if (getSingleBusiness) {
      setValues({
        restaurant_name: getSingleBusiness?.restaurant_name,
        business_address: getSingleBusiness?.business_address,
        business_yelp_page: getSingleBusiness?.business_yelp_page,
        business_fb_page: getSingleBusiness?.business_fb_page,
        business_google_page: getSingleBusiness?.business_google_page,
        business_twitter_page: getSingleBusiness?.business_twitter_page,
      });
    }
  }, [getSingleBusiness]);

  return (
    <MDBox sx={{ backgroundColor: "#F9F9F9", minHeight: "100vh" }}>
      <DashboardLayoutCustom>
        {authLoading && <Loader />}
        <DashboardNavbar />
        <MDBox mb={4} />
        <MDBox
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "45px",
              p: 2,
              "@media(max-width:786px)": { mx: 2 },
            }}
          >
          <MDTypography
            variant="subtitle1"
            fontWeight="medium"
            mt={2}
            mb={2}
            sx={{
              color: "#292929",
              fontFamily: "Urbanist",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28.8px",
            }}
          >
            Business Information
          </MDTypography>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  fontWeight="regular"
                  sx={{ color: "#212121" }}
                  mt={1}
                >
                  Business Name
                </MDTypography>

                <MDInput
                  type="text"
                  inputProps={{ readOnly: true }}
                  placeholder="Business name"
                  value={values?.restaurant_name || "N/A"}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": { borderRadius: "45px" },
                    "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  fontWeight="regular"
                  inputProps={{ readOnly: true }}
                  sx={{ color: "#212121" }}
                  mt={1}
                >
                  Business Address
                </MDTypography>
                <MDInput
                  type="text"
                  inputProps={{ readOnly: true }}
                  placeholder="Business address"
                  value={values?.business_address || "N/A"}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": { borderRadius: "45px" },
                    "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  fontWeight="regular"
                  inputProps={{ readOnly: true }}
                  sx={{ color: "#212121" }}
                  mt={1}
                >
                  Google
                </MDTypography>
                <MDInput
                  type="text"
                  placeholder="https//www.google.com"
                  value={values?.business_google_page || "N/A"}
                  fullWidth
                  readOnly
                  sx={{
                    "& .MuiInputBase-root": { borderRadius: "45px" },
                    "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  fontWeight="regular"
                  mt={1}
                  inputProps={{ readOnly: true }}
                  sx={{ color: "#212121" }}
                >
                  Yelp
                </MDTypography>
                <MDInput
                  type="email"
                  placeholder="https//www.yelp.com"
                  value={values?.business_yelp_page || "N/A"}
                  fullWidth
                  readOnly
                  sx={{
                    "& .MuiInputBase-root": { borderRadius: "45px" },
                    "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                  }}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  fontWeight="regular"
                  inputProps={{ readOnly: true }}
                  sx={{ color: "#212121" }}
                  mt={1}
                >
                  Facebook
                </MDTypography>
                <MDInput
                  type="text"
                  placeholder="https//www.facebook.com"
                  value={values?.business_fb_page || "N/A"}
                  fullWidth
                  readOnly
                  sx={{
                    "& .MuiInputBase-root": { borderRadius: "45px" },
                    "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                  }}
                />
              </MDBox>
            </Grid>

            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox>
                <MDTypography
                  variant="subtitle2"
                  fontWeight="regular"
                  inputProps={{ readOnly: true }}
                  sx={{ color: "#212121" }}
                  mt={1}
                >
                  X
                </MDTypography>
                <MDInput
                  type="text"
                  placeholder="https//www.x.com"
                  value={values?.business_twitter_page || "N/A"}
                  fullWidth
                  readOnly
                  sx={{
                    "& .MuiInputBase-root": { borderRadius: "45px" },
                    "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                  }}
                />
              </MDBox>
            </Grid> */}
          </Grid>

          <MDButton
            sx={{
              fontFamily: "urbanist",
              padding: 0,
              color: "#318BEC",
             mt:1
            }}
            component={Link}
            className="backD"
            to="/my-business"
          >
            <img
              src={backDashboard}
              style={{ marginRight: "10px", borderRadius: "20px" }}
            />
            Back
          </MDButton>
        </MDBox>
      </DashboardLayoutCustom>
    </MDBox>
  );
}
