import { createSlice } from "@reduxjs/toolkit";
import { SubscriptionStatus } from "constant";
import { loadSubscriptionAsync } from "features/actions/subscription";

const initialState = {
    status: SubscriptionStatus.NONE,
    quantity: null,
    billingAnchor: null,
    isTrialAvailed: false,
    loading: false,
    error: null,
};

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        updateSubscriptionStatus: (state, action) => {
            state.status = action.payload.status;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSubscriptionAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loadSubscriptionAsync.fulfilled, (state, action) => {
                state.status = action.payload.status;
                state.quantity = action.payload.quantity;
                state.isTrialAvailed = action.payload.is_trial_availed;
                state.billingAnchor = action.payload.billing_anchor;
                state.loading = false;
                state.error = null;
            })
            .addCase(loadSubscriptionAsync.rejected, (state) => {
                state.loading = false;
                state.error = "Unexpected error occurred!";
            });
    },
});

export const { updateSubscriptionStatus } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
