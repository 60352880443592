import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Skeleton,
  Rating,
  TablePagination,
} from "@mui/material";
import moment from "moment";
import MDBox from "@mui/material/Box";
import { allReviewAsync } from "features/actions/dashboard";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const ReviewTable = ({
  reviewType,
  selectedRows,
  handleRowSelection,
  handleRowClick,
  AlltoggleRowExpansion,
  allExpandedRows,
  getSource,
  loading,
  allreviews,
  selectedStartDate,
  selectedEndDate,
  businessId,
  selectedAttention,
  selectedSource,
  selectedRating,
  authorName,
  selectedReply,
  selectedTab,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handlePageAllReviews = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageAllReviews = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(
        allReviewAsync({
          common_id: businessId,
          page_size: rowsPerPage || 10,
          page_number: page+1,
          need_attention: selectedAttention?.value,
          source: selectedSource?.value,
          rating: selectedRating?.value,
          author: authorName,
          reply_sent: selectedReply?.value,
          from_date: selectedStartDate
            ? dayjs(selectedStartDate).format("YYYY-MM-DD")
            : null,
          end_date: selectedEndDate
            ? dayjs(selectedEndDate).format("YYYY-MM-DD")
            : null,
        })
      );
  }, [
    page,
    rowsPerPage,
    selectedStartDate,
    selectedEndDate,
    dispatch,
    businessId,
    selectedAttention,
    selectedSource,
    selectedRating,
    authorName,
    selectedReply,
  ]);



  return (
    <MDBox
      sx={{
        width: "100%",
        "@media(max-width:900px)": { overflowX: "auto" },
      }}
    >
      <Table
        sx={{
          tableLayout: "fixed",
          "@media(max-width:900px)": {
            maxWidth: "750px",
          },
        }}
        aria-label="simple table"
      >
        <TableHead
          sx={{
            borderBottom: "1px solid #F4F4F4",
            width: "100%",
            display: "table-header-group ",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                width: "7%",
                "@media(max-width:900px)": {
                  width: "100px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Pick
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "9%",
                "@media(max-width:900px)": {
                  width: "100px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Needs Attention
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "9%",
                "@media(max-width:900px)": {
                  width: "100px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Source
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "38%",
                "@media(max-width:900px)": {
                  width: "200px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Review
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "15%",
                "@media(max-width:900px)": {
                  width: "150px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Author Name
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "15%",
                "@media(max-width:900px)": {
                  width: "150px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Rating
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "8%",
                "@media(max-width:900px)": {
                  width: "100px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Reply Sent
            </TableCell>
            <TableCell
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19px",
                color: "#707EAE",
                textAlign: "left",
                fontFamily: "urbanist",
                width: "14%",
                "@media(max-width:900px)": {
                  width: "100px",
                },
                pt: 3,
                px: 3,
                pb: 3,
              }}
            >
              Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reviewType?.data?.length > 0 ? (
            reviewType?.data?.map((row, index) => {
              const fontBold = row?.read === true ? "500" : "bolder";
              return (
                <TableRow key={index} sx={{ fontBold }}>
                  <TableCell
                    padding="checkbox"
                    align="left"
                    onClick={() => handleRowSelection(row)}
                  >
                    <Checkbox checked={selectedRows?.includes(row.id)} />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      px: 3,
                      textTransform: "capitalize",
                      fontFamily: "urbanist",
                      fontWeight: fontBold,
                      cursor: "pointer",
                      color:
                        row?.["Needs Attention"] === "yes" ? "red" : "green",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                        width={35}
                        height={37}
                      />
                    ) : row?.["Needs Attention"] === "yes" ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </TableCell>

                  <TableCell
                    onClick={() => handleRowClick(row)}
                    sx={{
                      px: 3,
                      fontFamily: "urbanist",
                      fontWeight: "500",
                      fontWeight: fontBold,
                      cursor: "pointer",
                    }}
                    align="left"
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                        width={70}
                        height={37}
                      />
                    ) : (
                      getSource(row?.Source)
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      fontFamily: "urbanist",
                      fontWeight: fontBold,
                      cursor: "pointer",
                    }}
                    align="left"
                    onClick={() => handleRowClick(row)}
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                        width={500}
                        height={37}
                      />
                    ) : row?.Review !== undefined && row?.Review !== null ? (
                      allExpandedRows?.includes(index) ? (
                        <span onClick={() => AlltoggleRowExpansion(index)}>
                          {row?.Review + " ...Show less"}
                        </span>
                      ) : row?.Review?.length > 100 ? (
                        <span onClick={() => AlltoggleRowExpansion(index)}>
                          {row?.Review?.slice(0, 100) + " ...Read more"}
                        </span>
                      ) : (
                        row?.Review
                      )
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => handleRowClick(row)}
                    sx={{
                      px: 3,
                      fontFamily: "urbanist",
                      fontWeight: fontBold,
                      cursor: "pointer",
                    }}
                    align="left"
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                        width={120}
                        height={37}
                      />
                    ) : (
                      row?.author || "N/A"
                    )}
                  </TableCell>
                  <TableCell sx={{ px: 3, cursor: "pointer" }} align="left">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ paddingTop: "5px" }}> </span>

                      {loading ? (
                        <Skeleton
                          variant="rectangular"
                          style={{
                            marginTop: "5px",
                            borderRadius: "5px",
                          }}
                          width={120}
                          height={37}
                        />
                      ) : (
                        <Rating
                          readOnly
                          name="half-rating"
                          value={row?.Rating || 0}
                          // precision={0.5}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 3,
                      fontFamily: "urbanist",
                      fontWeight: fontBold,
                      cursor: "pointer",
                    }}
                    align="left"
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                        width={40}
                        height={37}
                      />
                    ) : (
                      (row?.reply_sent ? "Yes" : "No") || "N/A"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 3,
                      fontFamily: "urbanist",
                      fontWeight: fontBold,
                      cursor: "pointer",
                    }}
                    align="left"
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        style={{
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                        width={80}
                        height={37}
                      />
                    ) : (
                      moment(row?.Date).format("LL")
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan="8">
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "19px",
                    color: "#707EAE",
                    padding: "16px",
                    fontFamily: "urbanist",
                  }}
                >
                  No data found
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {selectedTab === 1 && (
        <TablePagination
          sx={{
            fontFamily: "urbanist",
            fontWeight: "700",
            "& .css-p33a0u-MuiTablePagination-selectLabel": {
              fontFamily: "urbanist",
              fontWeight: "500",
            },
            "& .css-r9d6bn-MuiSelect-select-MuiInputBase-input.css-r9d6bn-MuiSelect-select-MuiInputBase-input.css-r9d6bn-MuiSelect-select-MuiInputBase-input":
              { fontFamily: "urbanist", fontWeight: "600" },
            "& .css-188flkz-MuiTablePagination-displayedRows ": {
              fontFamily: "urbanist",
              fontWeight: "500",
            },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allreviews?.total_reviews}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageAllReviews}
          onRowsPerPageChange={handleRowsPerPageAllReviews}
        />
      )}
    </MDBox>
  );
};

export default ReviewTable;
