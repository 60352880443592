import MDTypography from "components/MDTypography";
import React from "react";
import { Bar } from "react-chartjs-2";


const StackedBarChart = ({ data, scale }) => {


  const totalPercentages = scale.map((year, index) =>
    data?.reduce((total, category) => total + category.data[index], 0)
  );

  // Prepare dataset for Chart.js
  const chartData = {
    labels: scale,
    datasets: data?.map(({ name, data, color }) => ({
      label: name,
      backgroundColor: color,
      data: data?.map((val, index) => {
        const totalPercentage = totalPercentages[index];
        return (val / totalPercentage) * 100;
      }),
      stack: "Stack 1",
      borderWidth: 1,
      datalabels: {
        display: true,
        anchor: "end",
        align: "end",
        color: "black",
        formatter: (value) => `${value.toFixed(2)}%`,
      },
    })),
  };

  // Chart options
  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Percentage",
        },
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y.toFixed(2)}%`;
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div>
      <MDTypography sx={{'@media(max-width:576px)':{marginBottom:"4rem"},marginBottom:"2.3rem",color:"#292929",fontFamily: "Urbanist",fontSize:"18px",fontWeight:"700",lineHeight:"21.6px"}}>Overall Sentiments</MDTypography>
      <Bar data={chartData} options={options} height={150}/>
    </div>
  );
};

export default StackedBarChart;
