import React, { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import { config } from "constant";
import { gapi } from "gapi-script";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import googleIcon from "../../assets/images/icons/googleIcon.svg";
import facebookIcon from "../../assets/images/icons/facebook.svg";
import toast from "react-hot-toast";
import TwitterLogin from "react-twitter-auth";
import modallogo from "assets/images/icons/modal-logo.svg";
import modalcross from "assets/images/icons/modal-cross.svg";

const AuthModal = ({
  sourceAuthModal,
  setSourceAuthModal,
  setSourceModal,
  platform,
}) => {
  const CLIENT_ID = config?.url?.CLIENT_ID;

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/userinfo.profile", // Specify the scopes here
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);



  const handleAuthClick = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((response) => {
        const authResponse = response.getAuthResponse();
        if (authResponse?.access_token && authResponse.expires_in) {
          const expirationDate = new Date(
            new Date().getTime() + authResponse.expires_in * 1000
          );

          Cookies.set("googleAccessToken", authResponse?.access_token, {
            expires: expirationDate,
          });
          toast.success(`Authenticated successfully with ${platform}`);
          setSourceAuthModal(false);
          setSourceModal(false);
        }
      })
      .catch((error) => {
        console.error("Google Login Failed:", error);
      });
  };


  const handleFacebookClick = () => {
    const url = 'https://www.facebook.com/v12.0/dialog/oauth?client_id=4475162612701279&redirect_uri=https://staging.reviewattendant.com/&scope=pages_show_list'; 
    window.open(url, '_blank')
      .catch((error) => {
        console.error("Facebook Login Failed:", error);
      });
  };

  const fbRef = useRef(null);
  
  useEffect(() => {
    // Render Facebook login button after SDK is loaded
    if (fbRef.current) {
      window.FB?.XFBML.parse(fbRef.current);
    }
  }, [fbRef.current]);
  const onTwitterSuccess = (response) => {
    response.json().then((body) => {
      Cookies.set("xAccessToken", body.accessToken, { expires: 1 });
      // setUserData(body.user);
      toast.success(`Authenticated successfully with ${platform}`);
      setSourceAuthModal(false);
      setSourceModal(false);
    });
  };
  useEffect(() => {
    const GetUserAccessToken = async () => {
      let params = {
        'client_id': config?.url?.Facebook_APPID,
        'redirect_uri': config?.url?.redirect_uri,
        'client_secret': config?.url?.app_secret,
        'code': Cookies.get("fbAccessCode"),
      };

      try {
        const response = await fetch(`https://graph.facebook.com/v12.0/oauth/access_token`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });

        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        handleFacebookResponse();  // Assuming this is your function to handle the response
        console.log("Access Token Response:", data);
        return data;

      } catch (error) {
        console.error("Error during access token request:", error);
      }
    };
    window.addEventListener('cookieChanged', GetUserAccessToken);

    return () => {
      window.removeEventListener('cookieChanged', GetUserAccessToken);
    };
  }, []);

 
  const onTwitterFailure = (res) => {
    setSourceAuthModal(false);
    setSourceModal(false);
    toast.error(`Login failed with ${platform}`);
  };
  const handleFacebookResponse = (response) => {
    if (response.accessToken) {
      Cookies.set("fbAccessToken", response.accessToken, { expires: 1 });
      toast.success(`Authenticated successfully with ${platform}`);
      setSourceAuthModal(false);
      setSourceModal(false);
    } else {
      toast.error(`Login failed with ${platform}`);
    }
  };

 

  return (
    <Modal
      open={sourceAuthModal}
      onClose={() => setSourceAuthModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MDBox
        sx={{
          width: 420,
          bgcolor: "#ffffff",
          p: 3,
          "& button": { borderRadius: "45px" },
          borderRadius: "24px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "-7px",
            top: "-7px",
            cursor: "pointer",
          }}
          onClick={() => setSourceAuthModal(false)}
        >
          <img src={modalcross} alt="status icon" />
        </div>
        <div>
          <img src={modallogo} alt="status icon" />
          <h2
            id="modal-modal-title"
            style={{
              paddingTop: "1rem",
              paddingBottom: "2rem",
              color: "#318BEC",
              paddingRight: "3.8rem",
              fontSize: "32px",
              fontFamily: "urbanist",
              lineHeight: "42px",
            }}
          >
            Authenticate to access various features
          </h2>
          <div
            style={{
              border: `${platform === "Facebook"?'':'2px solid #d4cfc3'}`,
              padding: " 0.2rem 1rem",
              borderRadius: "45px",
              marginBottom: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ overflow: "hidden", border: "1px solid white", cursor: "pointer" }}>
                {platform === "Google" ? (
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={googleIcon}
                        alt="icon"
                        style={{ marginRight: "15px", marginTop: "10px" }}
                      />
                    </div>
                    <div onClick={handleAuthClick} style={{ marginTop: "7px" }}>
                      Authenticate with Google
                    </div>
                  </div>
                ) : platform === "X" ? (
                  <TwitterLogin
                    loginUrl="http://localhost:4000/api/v1/auth/twitter"
                    onFailure={onTwitterFailure}
                    onSuccess={onTwitterSuccess}
                    requestTokenUrl="Enter your email and password to login!host:4000/api/v1/auth/twitter/reverse"
                  />
                ) : platform === "Facebook" ? (
                  
                  <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={facebookIcon}
                      alt="icon"
                      style={{ marginRight: "15px", marginTop: "10px" }}
                    />
                  </div>
                  <div onClick={handleFacebookClick} style={{ marginTop: "7px" }}>
                    Authenticate with Facebook
                  </div>
                </div>
                ) : null}

              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              color: "#7a7a78",
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            By proceeding, you agree to our{" "}
            <a href="/">
              <span
                className="underline-imp"
                style={{
                  textDecoration: "underline!important",
                  cursor: "pointer",
                  color: "#7a7a78",
                  "&:visited": { textDecoration: "underline!important" },
                  display: "inline",
                }}
              >
                Terms of Use
              </span>
            </a>{" "}
            and confirm you have read our{" "}
            <a href="/">
              <span
                className="underline-imp"
                style={{
                  textDecoration: "underline!important",
                  cursor: "pointer",
                  color: "#7a7a78",
                  display: "inline",
                }}
              >
                Privacy and Cookie Statement
              </span>
            </a>
            .
          </p>
        </div>
      </MDBox>
    </Modal>
  );
 
};

AuthModal.propTypes = {
  sourceAuthModal: PropTypes.bool.isRequired,
  setSourceAuthModal: PropTypes.func.isRequired,
  setSourceModal: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
};

export default AuthModal;
