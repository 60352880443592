import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  registerUser,
  loginUser,
  verifyEmail,
  updateProfile,
  getProfile,
  forgotPassword,
  resetPassword,
  updateBusinessPages,
  getAllRestaurants,
  GoogleSearch,
  GoogleSearchNearByAddress,
  addBusiness,
  getMyBusiness,
  getSingleBusiness,
  editBusiness,
  resendVerifyEmail,
  googleToken,
  addFacebookReviews,
  addYelpReviews,
  addTwitterReviews,
  addUpdatedTwitterReviews,
  addUpdatedYelpReviews,
  addUpdatedfacebookReviews,
  addUpdatedGoogleReviews,
  scrapeUrl,
  scrapeAll,
  updateAll,
  removeBusiness,
  deleteUser,
  updateSrapping,
} from "../../services/auth";

// register
export const registerUserAsync = createAsyncThunk("auth/register", async (userData) => {
  try {
    const response = await registerUser(userData);
    return response;
  } catch (error) {
    throw error;
  }
});

// login
export const loginUserAsync = createAsyncThunk("auth/login", async (userData) => {
  try {
    const response = await loginUser(userData);

    return response;
  } catch (error) {
    return error.response.data;
  }
});

//verify email
export const verifyEmailAsync = createAsyncThunk("auth/verifyEmail", async (userData) => {
  try {
    const response = await verifyEmail(userData);
    return response;
  } catch (error) {
    throw error;
  }
});

export const resendVerifyEmailAsync = createAsyncThunk("auth/ResendVerifyEmail", async (payload) => {
  try {
    const response = await resendVerifyEmail(payload);
    return response;
  } catch (error) {
    throw error;
  }
});

//update Profile
export const updateProfileAsync = createAsyncThunk(
  'auth/update-profile',
  async (userData) => {
    try {
      const response = await updateProfile(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

//update Profile
export const getProfileAysnc = createAsyncThunk(
  'auth/get-profile',
  async (userData) => {
    try {
      const response = await getProfile(userData);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAccountAsync = createAsyncThunk(
  'auth/delete-profile',
  async (userData) => {
    try {
      const response = await deleteUser(userData);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const forgotPasswordAysnc = createAsyncThunk(
  'auth/forgot-password',
  async (userData) => {
    try {
      const response = await forgotPassword(userData);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const resetPasswordAysnc = createAsyncThunk(
  'auth/reset-password',
  async ({ payload, config }) => {
    try {
      const response = await resetPassword({ payload, config });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const updateBusinessPagesAysnc = createAsyncThunk(
  'auth/update-business-pages',
  async (payload) => {
    try {
      const response = await updateBusinessPages(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllRestaurantsAysnc = createAsyncThunk(
  'auth/get-all-restaurant-name',
  async (payload) => {
    try {
      const response = await getAllRestaurants(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GoogleSearchAysnc = createAsyncThunk(
  'auth/Google-search',
  async (payload) => {
    try {
      const response = await GoogleSearch(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GoogleSearchNearByAddressAysnc = createAsyncThunk(
  'auth/Google-search-nearby-address',
  async (payload) => {
    try {
      const response = await GoogleSearchNearByAddress(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getGoogleReviewAysnc = createAsyncThunk(
  'auth/add-google-Business',
  async (payload) => {
    try {
      const response = await addBusiness(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getFacebookReviewAysnc = createAsyncThunk(
  'auth/add-facebook-Business',
  async (payload) => {
    try {
      const response = await addFacebookReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const getYelpReviewAysnc = createAsyncThunk(
  'auth/add-yelp-Business',
  async (payload) => {
    try {
      const response = await addYelpReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getTwitterReviewAysnc = createAsyncThunk(
  'auth/add-twitter-Business',
  async (payload) => {
    try {
      const response = await addTwitterReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUpdatedGoogleReviewAysnc = createAsyncThunk(
  'auth/add-updated-Google-Business',
  async (payload) => {
    try {
      const response = await addUpdatedGoogleReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUpdatedFacebookReviewAysnc = createAsyncThunk(
  'auth/add-updated-facebook-Business',
  async (payload) => {
    try {
      const response = await addUpdatedfacebookReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUpdatedYelpReviewAysnc = createAsyncThunk(
  'auth/add-updated-yelp-Business',
  async (payload) => {
    try {
      const response = await addUpdatedYelpReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const getUpdatedTwitterReviewAysnc = createAsyncThunk(
  'auth/add-updated-twitter-Business',
  async (payload) => {
    try {
      const response = await addUpdatedTwitterReviews(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);



export const scrapeUrlAsync = createAsyncThunk(
  'auth/scrape-url',
  async (payload) => {
    try {
      const response = await scrapeUrl(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const scrapeAllAsync = createAsyncThunk(
  'auth/scrape-all',
  async (payload) => {
    try {
      const response = await scrapeAll(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSrappingAsync = createAsyncThunk(
  'auth/update-srapping',
  async (payload) => {
    try {
      console.log(payload, "payload")
      const response = await updateSrapping(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAllAsync = createAsyncThunk(
  'auth/update-all',
  async (payload) => {
    try {
      const response = await updateAll(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getMyBusinessAysnc = createAsyncThunk(
  'auth/get-my-Business',
  async (payload) => {
    try {
      const response = await getMyBusiness(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSingleBusinessAysnc = createAsyncThunk(
  'auth/get-single-Business',
  async (params) => {
    try {
      const response = await getSingleBusiness(params);
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const editBusinessAysnc = createAsyncThunk(
  'auth/view-Business',
  async (payload) => {
    try {
      const response = await editBusiness(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const googleTokenAsync = createAsyncThunk(
  'auth/google-token',
  async (payload) => {
    try {
      const response = await googleToken(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


export const removeBusinessAsync = createAsyncThunk(
  'auth/remove-business',
  async (payload) => {
    try {
      const response = await removeBusiness(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

