//mui components
import MDBox from "components/MDBox";
import { Container, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

//images
import logoMain from "assets/images/logo-main.svg";
import worldIcon from "assets/images/small-logos/world-icon.svg";
import messageIcon from "assets/images/small-logos/message-icon.svg";
import callIcon from "assets/images/small-logos/call-icon.svg";

function Footer() {
  return (
    <MDBox sx={{ backgroundColor: "#318BEC", py: 5 }}>
      <Container sx={{ '@media (min-width:1580px)': { maxWidth: '1600px!important', pt: 0.5 } }}>
        <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xxl={4} lg={3} md={6}>
            <MDBox
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                justifyContent: "start",
                '@media(max-width:767px)': { py: 2 },
                '@media(min-width:1200px)': { mr: 3 },
                '@media(min-width:1400px)': { mr: 4 },

              }}
            >
              <MDBox>
                <img src={logoMain} alt="logo" />
              </MDBox>

            </MDBox>
          </Grid>
          <Grid item xxl={8} lg={9} md={6} sx={{ display: "flex", justifyContent: "space-between", '@media(min-width:1200px)': { flexDirection: "row" }, flexDirection: "column" }}>
            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "100%",
                  width: "100%",
                  '@media(min-width:1200px)': { justifyContent: "center" },
                  '@media(min-width:768px)': { justifyContent: "end" },
                  '@media(max-width:767px)': { py: 2 },
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox
                    sx={{ mr: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <img src={worldIcon} alt="world icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "500", letterSpacing: "0.1rem", '@media(max-width:340px)': { letterSpacing: "0rem" } }}>
                      WWW.GRATITUDESYSTEMS.COM
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "100%",
                  width: "100%",
                  '@media(min-width:1200px)': { justifyContent: "center" },
                  '@media(min-width:768px)': { justifyContent: "end" },
                  '@media(max-width:767px)': { py: 2 },
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox sx={{ mr: 0.6, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={messageIcon} alt="message icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "500", letterSpacing: "0.1rem", '@media(max-width:340px)': { letterSpacing: "0rem" } }}>
                      INFO@GRATITUDESYSTEMS.COM
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>

            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "100%",
                  width: "100%",
                  '@media(min-width:1200px)': { justifyContent: "center" },
                  '@media(min-width:768px)': { justifyContent: "end" },
                  '@media(max-width:767px)': { py: 2 },

                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                  <MDBox
                    sx={{ mr: 0.3, display: "flex", alignItems: "center", justifyContent: "center", pr: 0 }}
                  >
                    <img src={callIcon} alt="call icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "500", letterSpacing: "0.1rem", '@media(max-width:340px)': { letterSpacing: "0rem" } }}>
                      (202)240-7754
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Container>
    </MDBox>
  );
}

export default Footer;
