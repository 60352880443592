import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./features/store";
import { Toaster } from "react-hot-toast";

import "./index.css";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import SubscriptionGate from "components/Subscribe/SubscriptionGate";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
        <Provider store={store}>
          <SubscriptionGate>
            <Toaster />
            <App />
          </SubscriptionGate>
        </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
