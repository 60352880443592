import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatBot } from "features/services/chat";

// register
export const chatResponseAsync = createAsyncThunk(
  "chat/chat-bot",
  async (userData) => {
    try {
      const response = await chatBot(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
