// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


//added components
import Header from "./header";
import { Container, Grid } from "@mui/material";


//images
import logoMain from "assets/images/logo-main.svg";
import worldIcon from "assets/images/small-logos/world-icon.svg";
import messageIcon from "assets/images/small-logos/message-icon.svg";
import callIcon from "assets/images/small-logos/call-icon.svg";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { verifyEmailAsync } from "../../../features/actions/auth";

function EmailVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = window.location.search;

  const email = search?.split("?email=");

  useEffect(() => {
    if (email?.[1]) {
      dispatch(verifyEmailAsync(email?.[1]));
    }
  }, [dispatch, email]);

  const handleclick = () => {
    navigate("/sign-in");
  };

  return (
    <>
    <Header />
      <PageLayout>
        <MDBox
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <MDBox sx={{ height: "100%" }}>
            <MDTypography variant="h2" fontWeight="medium" color="#212121" mt={1}>
              Email Verification Successful!
            </MDTypography>

            <MDTypography variant="button" color="text" mt={2}>
            Your email has been successfully verified. You can now access to the portal.
            </MDTypography>

            <MDBox display="flex" justifyContent="center" alignItems="center" fullWidth mt={4}>
              <MDButton
                onClick={handleclick}
                sx={{
                  color: "#FFFFFF",
                  border: "2px solid transparent",
                  padding: "12px 24px 12px 24px",
                  borderRadius: "188px",
                  ml: 2,
                  background: "linear-gradient(63.54deg, #49A3F1 -5.54%, #1A73E8 100%)",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  textAlign: "left",
                  fontFamily: "Urbanist",
                }}
              >
                Go To Homepage
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox sx={{ backgroundColor: "#318BEC", py: 5 }}>
        <Container sx={{ '@media (min-width:1580px)': { maxWidth: '1600px!important' } }}>
          <Grid container>
            <Grid item xxl={4} lg={3} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <MDBox>
                  <img src={logoMain} alt="logo" />
                </MDBox>

              </MDBox>
            </Grid>

            <Grid item xxl={3} lg={4} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox
                    sx={{ mr: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <img src={worldIcon} alt="icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="button" sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "700" }}>
                      WWW.GRATITUDESYSTEMS.COM
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item lg={3} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox sx={{ mr: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={messageIcon} alt="icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="button" sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "700" }}>
                      INFO@GRATITUDESYSTEMS.COM
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item lg={2} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox
                    sx={{ mr: 0, display: "flex", alignItems: "center", justifyContent: "center", pr: 0 }}
                  >
                    <img src={callIcon} alt="icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="button" sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "700" }}>
                      (202)240-7754
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Container>
      </MDBox>
      </PageLayout>
    </>
  );
}

export default EmailVerification;
