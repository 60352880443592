import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadSubscriptionAsync } from "features/actions/subscription";
import { SubscriptionStatus } from "constant";
import { SubscribeToGo } from "components/Subscribe";

const SubscriptionGate = ({ children }) => {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.subscription);
  const profileData = useSelector((state) => state.auth.getprofile);

  useEffect(() => {
    if(profileData?.userProfile?.id) {
        dispatch(loadSubscriptionAsync(profileData.userProfile.id));
    }
  }, [profileData]);

  // if (status === SubscriptionStatus.CANCELED || status === SubscriptionStatus.EXPIRED) {
  //   return <SubscribeToGo />
  // }

  return <>{children}</>;
};

export default SubscriptionGate;
