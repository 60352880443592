const dev = {
  url: {
    GOOGLE_API_URL: process.env.REACT_APP_GOOGLE_API_URL,
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI_DEV,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    Facebook_APPID: process.env.REACT_APP_FACEBOOK_APPID_DEV,
    app_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
    API_URL: process.env.REACT_APP_API_URL_DEV,
    PROFILEURI: process.env.REACT_APP_PROFILEURI_DEV,
    Login_Url: process.env.REACT_APP_LOGIN_URL_DEV,
    token_url: process.env.REACT_APP_TOKEN_URL_DEV,
  },
};
const prod = {
  url: {
    GOOGLE_API_URL: process.env.REACT_APP_GOOGLE_API_URL,
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    Facebook_APPID: process.env.REACT_APP_FACEBOOK_APPID_PROD,
    API_URL: process.env.REACT_APP_API_URL_PROD,
    PROFILEURI: process.env.REACT_APP_PROFILEURI_PROD,
  },
};

const dev1 = {
  url: {
    GOOGLE_API_URL: `https://maps.googleapis.com/maps/api/`,
    GOOGLE_API_KEY: `AIzaSyD_vGGVm-ATDIapeInR3hPNzRHAMkKnqfw`,

    CLIENT_ID:
      "417720805067-a7n9mlo7ciae5dfebflun1e12m1cdqlq.apps.googleusercontent.com",
    // Facebook_APPID: "4475162612701279",
    Facebook_APPID: "4475162612701279",

    // //local
    // API_URL: `http://10.8.18.79:8003/`,
    // PROFILEURI: "http://10.8.18.79:8003/static/img/",

    //stagging
    // API_URL: "https://api.reviewattendant.com/",
    API_URL: "http://localhost:5050/",
    PROFILEURI: "https://api.reviewattendant.com/static/img/",

    // Production - URL
    // API_URL: "https://prodapi.reviewattendant.com/",
    // PROFILEURI: "https://prodapi.reviewattendant.com/static/img/",
  },
};


export const SubscriptionStatus = Object.freeze({
  TRIAL: "trial",
  ACTIVE: "active",
  CANCELED: "canceled",
  EXPIRED: "expired",
  CANCELED_AT_END: "canceled_at_end",
  NONE: "none",
});
export const config = process.env.NODE_ENV === "development" ? dev : dev;
