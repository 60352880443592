import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Container, Link } from "@mui/material";
import logoHeader from "assets/images/color-logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAysnc } from "features/actions/auth";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import React from "react";
import menuIcon from "assets/images/small-logos/new-burger-icon.svg";
import { useMediaQuery } from "react-responsive";


function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [colorChange, setColorchange] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isProfileFetched, setIsProfileFetched] = useState(false);
  const { getprofile } = useSelector((state) => state.auth);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const [loggedInUser, setLoggedInUser] = useState(false);

  useLayoutEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      setLoggedInUser(true);
      dispatch(getProfileAysnc());
    }
  }, []);

  const ToggleBar = () => {
    setToggle(!toggle);
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 5,
      marginTop: theme.spacing(1),
      Width: 40,
      color: "#318BEC",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: "318BEC",
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleDashboard = async () => {
    setIsProfileFetched(false);
    try {
      await dispatch(getProfileAysnc());
      setIsProfileFetched(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isProfileFetched) {
      if (getprofile?.businesses?.length > 0) {
        navigate("/dashboard");
      } else {
        navigate("/my-business");
      }
    }
  }, [isProfileFetched, getprofile, navigate]);


  return (
    <MDBox
      className={colorChange ? "navbar colorChange" : "navbar"}
      sx={{ position: "fixed", top: "0px", zIndex: "3", width: "100%" }}
    >
      <MDBox
        sx={{ borderBottom: " 1px solid #318BEC1A", position: "relative" }}
      >

        <Container
          sx={{ "@media (min-width:1580px)": { maxWidth: "1600px!important" } }}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "1rem",
              paddingBottom: "0.6rem",
            }}
          >
            <MDBox
              sx={{
                zIndex: "4",
                width: "120px",
                "@media (min-width:768px)": { width: "200px" },
              }}
            >
              <NavLink to="/">
                <img
                  src={logoHeader}
                  alt="logo"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </NavLink>
            </MDBox>
            {toggle || !isTabletOrMobile ? (
              <MDBox
               
                sx={{
                  mb: 1,
                  "@media (min-width:576px)": {
                    display: "block",
                    position: "static",
                    width: "auto",
                    background: "none",
                    height: "auto",
                  },
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  bottom: "-200%",
                  left: 0,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "white",
                  height: "150px",
                }}
              >
                <MDBox
                  sx={{
                    "@media (min-width:576px)": { display: "none" },
                    display: "block",
                    color: "#2929292",
                    fontWeight: "700",
                    fontSize: "20px",
                    position: "absolute",
                    right: "10px",
                    top: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => setToggle(false)}
                >
                  x
                </MDBox>

                <MDBox >
                  {loggedInUser ? (
                    <MDButton
                      component={Link}
                      sx={{
                        color: "#FFFFFF",
                        border: "2px solid transparent",
                        padding: "9px 40px 9px 40px",
                        borderRadius: "188px",

                        background:
                          "linear-gradient(63.54deg, #49A3F1 -5.54%, #1A73E8 100%)",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        textAlign: "left",
                        fontFamily: "urbanist",
                      }}
                      onClick={handleDashboard}
                    >
                      Dashboard
                    </MDButton>
                  ) : (
                    <>
                      <MDButton
                        component={Link}
                        sx={{
                          background: "transparent",
                          color: "#318BEC",
                          border: "2px solid #318BEC",
                          padding: "9px 40px 9px 40px",
                          borderRadius: "188px",
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          textAlign: "left",
                          fontFamily: "urbanist",
                          textTransform: "none",
                        }}
                        onClick={() => navigate("/sign-in")}
                      >
                        Login
                      </MDButton>

                      <MDButton
                        component={Link}
                        sx={{
                          color: "#FFFFFF",
                          border: "2px solid transparent",
                          padding: "9px 32px 9px 32px",
                          borderRadius: "188px",
                          ml: 2,
                          background:
                            "linear-gradient(63.54deg, #49A3F1 -5.54%, #1A73E8 100%)",
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          textAlign: "left",
                          fontFamily: "urbanist",
                          textTransform: "none",
                        }}
                        onClick={() => navigate("/sign-up")}
                      >
                        Register
                      </MDButton>
                    </>
                  )}
                </MDBox>
              </MDBox>
            ) : null}
            <MDBox
              sx={{
                "@media (min-width:576px)": { display: "none" },
                display: "block",
              }}
            >
              <div sx={{ maxWidth: "40px" }}>
                <Button onClick={ToggleBar}>
                  <img src={menuIcon} alt="menu icon" />
                </Button>
              </div>
            </MDBox>
          </MDBox>
        </Container>
      </MDBox>
    </MDBox>
  );
}

export default Header;
