import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dropdownIcon from "assets/images/dropdown-icon.svg";
import { useDispatch, useSelector } from "react-redux";

import { commonIdState } from "features/reducers/dashboard";
import { dashboardPage1 } from "features/reducers/auth";

function NavbarSelect() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = useState({});
  const { getprofile, dashboardFalse1 } = useSelector((state) => state.auth);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSelectRestturant = (restaurant) => {
    dispatch(dashboardPage1(true));
    setSelected(restaurant);
    setAnchorEl(null);
  };
  useEffect(() => {
    if (getprofile?.businesses?.length > 0) {
      getprofile?.businesses?.map((ele) => {
        if (ele?.common_id == localStorage.getItem("common_id")) {
          dispatch(commonIdState(ele?.common_id));
          setSelected(ele);
        }
      });
    }
  }, [getprofile]);

  useEffect(() => {
    if (Object.keys(selected)?.length > 0)
      localStorage.setItem("common_id", selected?.common_id);
    dispatch(commonIdState(selected?.common_id));
  }, [selected]);
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleClick}
        sx={{
          wordWrap: "break-word",
          background: "none !important",
          width:'100%',
          "&:hover": {
            background: "none !important",
          },
          "&:active": {
            background: "none !important",
          },
          "&:focus": {
            background: "none !important",
          },

          zIndex: "1",
          
          px: 0,
         

          "@media(max-width:991px)": {
            px: 0,
          },
          
        }}
      >
        
        <MDBox
          sx={{
            gap: "10px",
            display: "flex",
            alignitems: "center",
            height: "auto",
            justifyContent: "space-between",
            width: "auto",
            borderRadius: "100px",
            border: "1px solid #318BEC",
            backgroundColor: "#F0F7FF",
            cursor: "pointer",
            "@media(min-width:320px)": { width: "100%" },
            "@media(min-width:440px)": { width: "100%" },
            "@media(min-width:992px)": { width: "auto" },
            "@media(min-width:1600px)": {
              width: "350px",
              padding: "10px 30px",
            },
            "&:hover": {
              boxShadow:
                "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2)",
            },
          }}
        >
          <MDTypography
            sx={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "17px",
              color: "#318BEC",
              alignitems: "center",
              pl: 2,
              pt: 0.3,
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "3px",
              }}
            >
              {selected?.restaurant_name}
            </p>
            <p className="clamp-add" style={{ fontSize: "12px", fontWeight: "bold" }}>
              {selected?.business_address
                ? selected?.business_address?.length > 50
                  ? selected?.business_address?.slice(0, 50) + "..."
                  : selected?.business_address
                : "Choose Business"}
            </p>
          </MDTypography>
          <MDBox sx={{ display: "flex", alignitems: "center", pl: 1, mr: 1 }}>
            <img
              src={dropdownIcon}
              alt="dropdown icon"
              style={{ maxWidth: "100%" }}
            />
          </MDBox>
        </MDBox>
      </Button>
      <Menu
        sx={{
          "& .MuiPaper-root ": {
            padding: 0,
            borderRadius: "14px",
            maxHeight: "350px",
            overflowY: "scroll",
            boxShadow: 9,
            width: "400px",
          },
          "& .MuiPaper-root::-webkit-scrollbar ": { width: "7px" },
          "& .MuiPaper-root::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",
          },
          "& .MuiPaper-root::-webkit-scrollbar-thumb": {
            background: "grey",
            borderRadius: "10px",
          },
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button1",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {getprofile?.businesses &&
          getprofile?.businesses
            .filter((ele) => ele?.onboarding_status === "true")
            .map((ele) => {
              const isSelected =
                selected?.restaurant_name === ele?.restaurant_name;
              return (
                <MenuItem
                  key={ele?.restaurant_name}
                  onClick={() => handleClickSelectRestturant(ele)}
                  sx={{
                    padding: "12px 14px",
                    borderRadius: "0",
                    borderBottom: "1px solid #E5E4E2",
                    "& .MuiTypography-root": {
                      fontWeight: "700",
                      fontSize: "16px",
                      lineHeight: "19.2px",
                      color: "#292929",
                      fontFamily: "urbanist",
                      height: "auto",
                    },
                    "&:hover": {
                      "& .MuiTypography-root": {
                        color: isSelected ? "#292929" : "#ffffff",
                      },
                      backgroundColor: isSelected ? "#ffffff" : "#318BEC",
                    },
                    backgroundColor: isSelected ? "#318BEC" : "transparent",
                  }}
                  selected={isSelected}
                >
                  <Typography>
                    <p
                      style={{
                        fontSize: "18px",
                        marginBottom: "3px",
                        fontWeight: "bold",
                      }}
                    >
                      {ele?.restaurant_name}
                    </p>
                    <p style={{ fontSize: "12px", fontWeight: "lighter" }}>
                      {ele?.business_address}
                    </p>
                  </Typography>
                </MenuItem>
              );
            })}
      </Menu>
    </div>
  );
}

export default NavbarSelect;
