//
import { Container, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import styled from "@emotion/styled";
import MDTypography from "components/MDTypography";
import * as React from "react";

//images
import aggregateReviews from "assets/images/aggregate-reviews-img.png";
import askRa from "assets/images/ask-ra.png";
import compose from "assets/images/compose.png";
import catchimg from "assets/images/catch.png";
// import agrRev1 from "assets/images/agr-rev-1.png";
import agrRev1 from "assets/images/agr-rev-1-shadow-n.png";
// import composeAdorn from "assets/images/compose-adorn.png";
import composeAdorn from "assets/images/compose-adorn-shadow-n.png";
// import catchAdorn from "assets/images/catch-adorn.png";
import catchAdorn from "assets/images/catch-adorn-shadow-n.png";

//list
import List from "@mui/material/List";

//styled components
const StyledListItem = styled.li`

  border-left: 4px solid #318bec;
  background-color: #ffffff;
  box-shadow: 0px 16px 50px 0px #b9c0c933;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-bottom: 17px;
  padding-left: 1rem;
  padding-right: 1rem;
  width:"100%";

`;
const StyledListText = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #6b6b6b;
`;
const StyledSectionHeading1 = styled.h2`
@media only screen and (min-width: 580px){
  font-size: 42px;
  line-height: 50px;
},
font-size:25px;
font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #318bec;
   margin-bottom:2rem;

`;
const StyledSectionHeading2 = styled.span`
@media only screen and (min-width: 580px){
  font-size: 42px;
  line-height: 50px;
  
},
 font-size:25px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #292929;
  padding-left:0.7rem
 
`;

function BodySection() {
  return (
    <Container  sx={{'@media (min-width:1580px)': { maxWidth: '1600px!important' },'@media (min-width:992px)': { py:5 } ,py:0}}>
      <Grid
        container
        
        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py:0,pb:3, '@media(min-width:1500px)':{py:5} }}
      >
        <Grid item lg={5} order={{sm:1 , xs:2}}>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              '@media(max-width:575px)':{marginTop:"32px"},            
              '@media(min-width:576px)':{ marginBottom:"72px"},
            }}
          >
          
              <StyledSectionHeading1>Aggregate<StyledSectionHeading2>Reviews</StyledSectionHeading2></StyledSectionHeading1>
             
          

            <List sx={{width:"100%"}}>
              <StyledListItem>
                <StyledListText>
                  Look at all your reviews in one locations from  
                  <MDTypography
                      component="span"
                      variant="button"
                      color="info"
                      fontWeight="bold"
                      fontSize="18px"
                  >
                     Yelp, Facebook, Google, Instagram,
                  Twitter
                    </MDTypography>
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  We give your unrated reviews a 1-5 score with  <MDTypography
                      component="span"
                      to="/sign-up"
                      variant="button"
                      color="info"
                      fontWeight="bold"
                      fontSize="18px"                    
                    >
                    98%
                    </MDTypography> accuracy (twitter)
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  Look at average scores, number of reviews and so on combined or per platform
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  Categorize all reviews into good, neutral and bad buckets for quick sentiment
                  analysis
                </StyledListText>
              </StyledListItem>
            </List>
          </MDBox>
        </Grid>
        <Grid item lg={6} order={{sm:2 , xs:1}}>
          <MDBox sx={{ position: "relative",zIndex:2,  marginBottom:"54px",
              '@media(min-width:320px)':{ marginBottom:"50px"},
              '@media(min-width:476px)':{ marginBottom:"52px"},
              '@media(min-width:0px)':{ marginBottom:"0px"},
              '@media(min-width:1500px)':{ marginBottom:"112px"},
              }}>
            <MDBox sx={{ position: "absolute", left: "0%", bottom: "-5%", '@media(min-width:425px)':{ position: "absolute", left: "10%", bottom: "-5%"},'@media(min-width:562px)':{ position: "absolute", left: "15%", bottom: "-5%"},'@media(min-width:880px)':{position: "absolute", left: "-72px", top: "45px",bottom:"auto"}}}>
              {" "}
              <img src={agrRev1} alt="aggregate platform metrics" style={{ maxWidth: "100%",display:"block",boxShadow:" 0px 0px 22px 5.48px #5F5F5F24",borderRadius:"8px" }} />
            </MDBox>
            
            <img src={aggregateReviews} alt="Two individuals seated at a table, using a laptop for either work or communication purposes." style={{ maxWidth: "100%" }} />
          </MDBox>
        </Grid>
      </Grid>

      <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" ,pb:0,mb:0,mt:0, '@media(min-width:1024px)':{pb:5,mb:3},'@media(min-width:1900px)':{paddingTop:"4rem",mt:2,mb:0} }}>
        <Grid item lg={7}>
          <MDBox sx={{pr:"0rem",'@media (min-width:992px)': { marginTop: '0px',pr:"4rem" },marginTop: '35px'}}  >
            <img src={askRa} alt="Woman using a laptop on her lap with a message on the screen" style={{ maxWidth: "100%",display:"block" }} />
          </MDBox>
        </Grid>

        <Grid item lg={5} >
          <MDBox 
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",             
              '@media(min-width:320px)':{marginTop:"25px",marginBottom:"26px"},
              '@media(min-width:576px)':{marginBottom:"0px"},
             
            }}
          >
        
              <StyledSectionHeading1>Ask RA<StyledSectionHeading2>Any Questions About Your Reviews</StyledSectionHeading2></StyledSectionHeading1>
             
           
            <List sx={{width:"100%"}}>
              <StyledListItem>
                <StyledListText>What is my average review score this month?</StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  Did I get any bad reviews I should look into this week?
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>How many reviews did I get on Google this month?</StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  Which menu items do my customers seem to like or talk about?
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>Are there any complaints about our staff members?</StyledListText>
              </StyledListItem>
            </List>
            <MDBox><List></List></MDBox>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",pt:0,pb:2,mb:3, '@media(min-width:1024px)':{py:5},'@media(min-width:1900px)':{paddingTop:"13rem",margintop:"5rem"}  }}>
        <Grid item lg={5} order={{sm:1 , xs:2}}>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",             
              '@media(min-width:320px)':{marginBottom:"25px"},
              '@media(min-width:576px)':{marginTop:"0px",paddingTop:0,marginBottom:"56px"},
            }}
          >
       
              <StyledSectionHeading1>Compose<StyledSectionHeading2>a Professional and Personal Response Assisted with RA AI</StyledSectionHeading2></StyledSectionHeading1>
        
            <List sx={{width:"100%"}}>
              <StyledListItem>
                <StyledListText>
                  RA can help you draft a personal and professional response for every review
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  Give RA a few phrases and RA will compose a professional response for you to
                  review. You can tweak it or just press send
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  Or RA can draft a complete professional response for you based on what the
                  customer review says.
                </StyledListText>
              </StyledListItem>
            </List>
          </MDBox>
        </Grid>

        <Grid item lg={6} order={{sm:2 , xs:1}}>
          <MDBox sx={{ position: "relative",   '@media(min-width:320px)':{marginBottom:"130px",marginTop:"35px"},'@media(min-width:576px)':{marginBottom:"130px",marginTop:"0"},'@media(min-width:992px)':{marginBottom:"0"} }}>
            <MDBox sx={{ position: "absolute", bottom: "-100px", right: "10%",'@media(max-width:485px)':{bottom:"-100px",left:"20px",right:"auto"} }}>
              {" "}
              <img src={composeAdorn} alt="review attendant" style={{ maxWidth:"100%", display:"block",boxShadow:" 0px 0px 22px 5.48px #5F5F5F24",borderRadius:"12px"}} />
            </MDBox>
            <img src={compose} alt="A young woman wearing headphones uses a smartphone on a subway, with digital icons representing ai technology floating around her." style={{ maxWidth: "100%",display:"block" }} />
          </MDBox>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",paddingTop:"0rem",paddingBottom:"3rem",'@media(min-width:992px)':{marginTop:"40px",paddingBottom:"5rem"}, '@media(min-width:1900px)':{paddingTop:"13rem",paddingBottom:"7rem"} }}
      >
        <Grid item lg={6}>
          <MDBox sx={{ position: "relative",}}>
            <MDBox sx={{position:"absolute",bottom:"-20%", right:"0%",'@media(min-width:425px)':{bottom:"-10%", right:"5%"},'@media(min-width:768px)':{bottom:"-9%", right:"17%"}, '@media(min-width:849px)':{ bottom: "102px", right: "-62px"}}}>
              {" "}
              <img src={catchAdorn} alt="Screenshot of a webpage section titled 'Bad Reviews' with '322+ reviews' in blue text. Below it, there is a review by a user named 'Alex' with a profile picture icon and the initial 'M' on it. Alex's review is rated with one out of five stars and the text says 'Itaque dolor fuga natus eveniet.'" style={{ maxWidth: "100%",display:"block",boxShadow:" 0px 0px 22px 5.48px #5F5F5F24",borderRadius:"8px" }} />
            </MDBox>
            <img src={catchimg} alt="Woman sitting with a laptop on a small table, holding a cup of coffee, and a smartphone beside her. The laptop screen displays a webpage with reviews and a 10k badge." style={{ maxWidth: "100%",display:"block" }} />
          </MDBox>
        </Grid>

        <Grid item lg={5}>
          <MDBox
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",           
              '@media(min-width:320px)':{marginTop:"65px"},
              '@media(min-width:576px)':{marginTop:"70px"},              
            }}
          >
           
            <StyledSectionHeading1>Catch<StyledSectionHeading2>Bad Reviews Early</StyledSectionHeading2></StyledSectionHeading1>
      
         

            <List sx={{width:"100%"}}>
              <StyledListItem>
                <StyledListText>
                  RA can notify you when a customer posts a bad or not so good review
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  RA will catch a “bad” review in review language, not just the review stars
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  RA can send a personalized response to the customer addressing the issue until you
                  get to it
                </StyledListText>
              </StyledListItem>

              <StyledListItem>
                <StyledListText>
                  RA can send an apologetic, thank you or we will get back to you response based on
                  the review and anything else you may want to add.
                </StyledListText>
              </StyledListItem>
            </List>
          </MDBox>
        </Grid>
      </Grid>
    </Container>
  );
}

export default BodySection;
