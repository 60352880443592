import Dashboard from "layouts/dashboard";
import Home from "layouts/home";
import GoogleDashboard from "layouts/google-dashboard";
import YelpDashboard from "layouts/yelp-dashboard";
import FacebookDashboard from "layouts/facebook-dashboard";
import TwitterDashboard from "layouts/twitter-dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import OtpVerification from "layouts/authentication/OtpVerification";
import EmailVerification from "layouts/authentication/verify-email";
import Chatbot from "layouts/Chatbot";
// @mui icons
import Icon from "@mui/material/Icon";
import ClientDetail from "layouts/authentication/ClientDetail";
import Protected from "./protectedRoutes";
import { CheckRoutes, CheckAuth } from "./restrictRoutes";
import ForgetPassword from "layouts/authentication/forget-password";
import NewPassword from "layouts/authentication/new-password";
import MyBusiness from "layouts/authentication/my-business";
import ViewBusiness from "layouts/authentication/ClientDetail/view-business";
import SocialInformation from "layouts/authentication/social-information";
import ThankYou from "layouts/authentication/thank-you";
import ScrapePage from "layouts/authentication/components/scrape-page";
import PrivacyPolicy from "layouts/authentication/components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "layouts/authentication/components/termsCondition";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: (
      <CheckRoutes>
        {" "}
        <Dashboard />
      </CheckRoutes>
    ),
  },
  {
    type: "collapse",
    name: "yelp-dashboard",
    key: "yelp-dashboard",
    icon: <Icon fontSize="small">Yelp</Icon>,
    route: "/yelp-dashboard",
    component: (
      <CheckRoutes>
        {" "}
        <YelpDashboard />
      </CheckRoutes>
    ),
  },
  {
    type: "collapse",
    name: "Google",
    key: "google-dashboard",
    icon: <Icon fontSize="small">Google</Icon>,
    route: "/google-dashboard",
    component: (
      <CheckRoutes>
        {" "}
        <GoogleDashboard />
      </CheckRoutes>
    ),
  },
  {
    type: "collapse",
    name: "facebook-dashboard",
    key: "facebook-dashboard",
    icon: <Icon fontSize="small">Facebook</Icon>,
    route: "/facebook-dashboard",
    component: (
      <CheckRoutes>
        {" "}
        <FacebookDashboard />
      </CheckRoutes>
    ),
  },
  {
    type: "collapse",
    name: "x-dashboard",
    key: "x-dashboard",
    icon: <Icon fontSize="small">X</Icon>,
    route: "/x-dashboard",
    component: (
      <CheckRoutes>
        {" "}
        <TwitterDashboard />
      </CheckRoutes>
    ),
  },
  {
    type: "collapse",
    name: "home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Details",
    key: "details",
    icon: <Icon fontSize="small">Detail</Icon>,
    route: "/business-detail",
    component: (
      <Protected>
        {" "}
        <ClientDetail />
      </Protected>
    ),
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: (
      <Protected>
        {" "}
        <Profile />
      </Protected>
    ),
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: (
      <CheckAuth>
        {" "}
        <SignIn />
      </CheckAuth>
    ),
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: (
      <CheckAuth>
        {" "}
        <SignUp />
      </CheckAuth>
    ),
  },
  {
    type: "collapse",
    name: "OTP Verification",
    key: "otp-verification",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/otp-verification",
    component: <OtpVerification />,
  },

  {
    type: "collapse",
    name: "email Verification",
    key: "email-verification",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/email-verification",
    component: <EmailVerification />,
  },

  {
    type: "collapse",
    name: "privacy policy",
    key: "privacy-policy",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
  },

  {
    type: "collapse",
    name: "terms condition",
    key: "terms-condition",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/terms-conditions",
    component: <TermsAndConditions />,
  },
  {
    type: "collapse",
    name: "Forget Password",
    key: "forget-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/forget-password",
    component: <ForgetPassword />,
  },
  {
    type: "collapse",
    name: "New Password",
    key: "new-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/new-password/:token",
    component: <NewPassword />,
  },
  {
    type: "collapse",
    name: "chatbot",
    key: "chatbot",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/chatbot",
    component: (
      <CheckRoutes>
        {" "}
        <Chatbot />
      </CheckRoutes>
    ),
  },

  {
    type: "collapse",
    name: "Social Information",
    key: "social-information",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/social-information",
    component: <SocialInformation />,
  },
  {
    type: "collapse",
    name: "my business",
    key: "my-business",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/my-business",
    component: (
      <Protected>
        {" "}
        <MyBusiness />
      </Protected>
    ),
  },

  {
    type: "collapse",
    name: "view business",
    key: "view-business",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/view-business",
    component: <ViewBusiness />,
  },
  {
    name: "Thank You",
    key: "thank-you",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/thank-you",
    component: <ThankYou />,
  },
  {
    name: "scrape page",
    key: "scrape-page",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/scrape-page",
    component: <ScrapePage />,
  },
];

export default routes;

