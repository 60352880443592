import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

// @mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import * as React from "react";
import Modal from "@mui/material/Modal";
// import './PasswordValidator.css';

// Authentication layout components
// import CoverLayout from "layouts/authentication/components/CoverLayout";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Loader from "../../../assets/loader/loader";

// Images
import SignUpImage from "assets/images/sign-up-bg.jpg";
import {
  GoogleSearchAysnc,
  // getAllRestaurantsAysnc,
  registerUserAsync,
} from "../../../features/actions/auth";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import logoHeader from "assets/images/color-logo.svg";
import showHideIcon from "assets/images/small-logos/show-hide-icon.svg";
import dontShowIcon from "assets/images/small-logos/dont-show.svg";
import correctIcon from "assets/images/small-logos/icon-right.svg";
import wrongIcon from "assets/images/small-logos/wrong-icon.svg";

import { clearGoogleAddress } from "features/reducers/auth";
import { clearRegister } from "features/reducers/auth";
import ModalTickIcon from "assets/images/modal-tick-icon.svg";

function Cover() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    py: 5,
  };

  const hasUpperCase = (str) => /[A-Z]/.test(str);
  const hasLowerCase = (str) => /[a-z]/.test(str);
  const hasNumber = (str) => /[0-9]/.test(str);
  const hasSpecialChar = (str) =>
    /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str);
  const isLongEnough = (str) => str.length >= 8;

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [typing, setTyping] = useState(false);
 const [isLocalhost, setIsLocalhost] = useState(false);

 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllRestaurants, googleAddress, registerData } = useSelector(
    (state) => state.auth
  );
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [businessNameHint, setBusinessNameHint] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [googleApiCall, setGoogleApiCall] = useState(false);
  const [values, setValues] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
    phone_number: "",
    businessName: "",
    business_address: "",
    business_google_page: "",
    business_yelp_page: "",
    business_fb_page: "",
    business_twitter_page: "",
  });

  const [filterDataClose, setFilterDataClose] = useState(false);

  const [errors, setErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    confirmPassword: "",
    phone_number: "",
    businessName: "",
    business_address: "",
    business_google_page: "",
    business_yelp_page: "",
    business_fb_page: "",
    business_twitter_page: "",
  });

  const checkName = /^[a-zA-Z\s]+$/;
  const checkEmail = /\S+@\S+\.\S+/;
  const checkPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).*$/;
  const checkUrl =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
  const checkPhoneNumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  const eyeButton = () => {
    setShowPassword(!showPassword);
  };

  const eyeButton2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(true);
  };

  const handleRemoveMaskingChars = (phone_number) => {
    const cleanedPhoneNumber = phone_number.replace(/\D/g, ""); // Remove non-numeric characters
    return cleanedPhoneNumber;
  };

  const validateForm = (formData) => {
    const newErrors = {};

    const trimmedEmail = formData?.email?.trim();
    const trimmedPassword = formData?.password?.trim();
    const trimmedFirstName = formData?.first_name?.trim();
    const trimmedLastName = formData?.last_name?.trim();
    // Validate email
    if (!trimmedEmail) {
      newErrors.email = "Email is required";
    } else if (!checkEmail.test(trimmedEmail)) {
      newErrors.email = "Invalid email format";
    }

    // Validate name
    if (!trimmedFirstName) {
      newErrors.first_name = "First name is required";
    } else if (!checkName.test(trimmedFirstName)) {
      newErrors.first_name = "First name must be in alphabetic characters";
    }

    if (!trimmedLastName) {
      newErrors.last_name = "Last name is required";
    } else if (!checkName.test(trimmedLastName)) {
      newErrors.last_name = "Last name must be in alphabetic characters";
    }

    // Validate password
    if (!trimmedPassword) {
      newErrors.password = "Password is required.";
    } else if (!checkPassword.test(trimmedPassword)) {
      newErrors.password = "Password format is incorrect";
    } else if (trimmedPassword.length < 8) {
      newErrors.password = "Minimum 8 characters required";
    }

    // Validate Confirm Password
    if (!values.confirmPassword && values.password && errors.password === "") {
      newErrors.confirmPassword = "Confirm Password is required.";
    } else if (
      !(values.confirmPassword === values.password) &&
      values.password &&
      errors.password === ""
    ) {
      newErrors.confirmPassword = "Password doesn't match.";
    }

    // Validate phone number
    if (!values.phone_number) {
      newErrors.phone_number = "Phone number is required.";
    }
    if (
      values.phone_number &&
      !checkPhoneNumber.test(handleRemoveMaskingChars(values.phone_number))
    ) {
      newErrors.phone_number =
        "Please enter a numeric phone number with at least 10 digits.";
    }

    return newErrors;
  };

  const inputHandler = (fieldName) => {
    return (e) => {
      const { name, value } = e.target;
      let error = "";

      switch (fieldName) {
        case "email":
          if (!value) {
            error = "Email is required";
          } else if (!checkEmail.test(value)) {
            error = "Invalid email format";
          }
          break;
        case "first_name":
          if (!value) {
            error = "First name is required";
          } else if (!checkName.test(value)) {
            error = "First name must be in alphabetic characters";
          }
          break;
        case "last_name":
          if (!value) {
            error = "Last name is required";
          } else if (!checkName.test(value)) {
            error = "Last name must be in alphabetic characters";
          }
          break;
        case "password":

          if (value && !checkPassword.test(value)) {
            error = "Password format is incorrect";
          } else if (value.length < 8) {
            error = "Minimum 8 characters required";
          }
          break;
        case "confirmPassword":
          if (!value && !(value === values.password)) {
            error = "Confirm Password is required.";
          } else if (!(value === values.password)) {
            error = "password doesn't match.";
          }
          break;
        case "phone_number":
          if (
            value &&
            !checkPhoneNumber.test(handleRemoveMaskingChars(value))
          ) {
            error =
              "Please enter a numeric phone number with at least 10 digits.";
          }
          break;
        case "businessName":
          if (!value) {
            error = "Business Name is required.";
          } else {
            setFilterDataClose(false);
          }
          break;
        case "business_address":
          if (!value) {
            error = "Business Address is required.";
          }
          break;
        case "business_google_page":
        case "business_yelp_page":
        case "business_fb_page":
        case "business_twitter_page":
          if (value && !checkUrl.test(value)) {
            error = "Invalid URL format";
          }
          break;
        default:
          break;
      }

      setErrors({ ...errors, [fieldName]: error });
      setValues({ ...values, [fieldName]: value });
    };
  };


  const handleSubmit = async () => {
    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const formDataObject = new FormData();
      // Append trimmed and validated values to formDataObject
      Object.keys(values).forEach((key) => {
        formDataObject.append(key, values[key].trim());
      });

      try {
        await dispatch(registerUserAsync(formDataObject));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };

 

  useEffect(() => {
    if (registerData.status_code == 200) {
      localStorage.setItem("email", values.email);
      dispatch(clearRegister());
      dispatch(clearGoogleAddress());
      navigate("/thank-you");
    }
  }, [registerData]);

  useEffect(() => {
    if (!filterDataClose && values.businessName != "") {
      let filteredRestaurants =
        values.businessName != "" &&
        getAllRestaurants?.filter((restaurant) =>
          restaurant.toLowerCase().includes(values.businessName.toLowerCase())
        );
      setBusinessNameHint(filteredRestaurants);
    }
  }, [filterDataClose, values]);

  useEffect(() => {
    if (filterDataClose && values.businessName != "" && !googleApiCall) {
      dispatch(GoogleSearchAysnc(values.businessName));
      setGoogleApiCall(true);
    }
  }, [values, filterDataClose]);

  useEffect(() => {
    if (googleAddress.status == "OK") {
      setValues({
        ...values,
        business_address: googleAddress.results[0].formatted_address,
      });
    }
  }, [googleAddress]);


  useEffect(() => {
    const isLocal =
      window.location.hostname === "localhost" 
    setIsLocalhost(isLocal);
  }, []);

  

  return (
    <PageLayout>
      {loading && <Loader />}

      <Grid container sx={{ marginleft: "10px" }}>
        <Grid
          item
          md={6}
          px={0}
          sx={{
            py: 2,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            "@media(max-width:400px)": { px: 0, py: 2 },
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MDBox sx={{ width: "100%", textAlign: "start" }}>
              <NavLink to="/">
                <MDBox sx={{ ml: 1 }}>
                  <img src={logoHeader} alt="profile_image" />
                </MDBox>
              </NavLink>
            </MDBox>
            <MDBox>
              <MDBox
                px={5}
                pt={3}
                sx={{
                  "@media (min-width:768px)": {
                    paddingLeft: "2rem!important",
                    paddingRight: "2rem!important",
                  },
                  paddingLeft: "0!important",
                  paddingRight: "0!important",
                }}
              >
                <MDBox ml={1} mt={-2} py={1} mb={1} textAlign="left">
                  <MDTypography
                    sx={{
                      fontSize: "36px",
                      fontWeight: 700,
                      lineHeight: "45.36px",
                      color: "#212121",
                    }}
                    mt={1}
                  >
                    Register
                  </MDTypography>
                  <MDTypography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20.16px",
                      color: "#838383",
                    }}
                    mt={1}
                  >
                    Fill the form below to create an account
                  </MDTypography>
                </MDBox>

                <Grid container sx={{ width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      alignSelf: "start",
                      justifyContent: "start",
                      height: "100%",
                    }}
                  >
                    <MDBox p={1} mt={1}>
                      <MDInput
                        type="text"
                        placeholder="First name *"
                        value={values.first_name}
                        error={!!errors.first_name}
                        onChange={inputHandler("first_name")}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "45px",
                            border: "1px solid #9095A4",
                          },
                        }}
                      />
                    </MDBox>
                    {errors.first_name ? (
                      <MDBox px={1}>
                        <MDTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "15px",
                          }}
                          color="error"
                        >
                          {errors.first_name}
                        </MDTypography>
                      </MDBox>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      alignSelf: "start",
                      justifyContent: "start",
                      height: "100%",
                    }}
                  >
                    <MDBox p={1} mt={1}>
                      <MDInput
                        type="text"
                        placeholder="Last name *"
                        value={values.last_name}
                        error={!!errors.last_name}
                        onChange={inputHandler("last_name")}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "45px",
                            border: "1px solid #9095A4",
                          },
                        }}
                      />
                    </MDBox>
                    {errors.last_name ? (
                      <MDBox px={1}>
                        <MDTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "15px",
                          }}
                          color="error"
                        >
                          {errors.last_name}
                        </MDTypography>
                      </MDBox>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      alignSelf: "start",
                      justifyContent: "start",
                      height: "100%",
                    }}
                  >
                    <MDBox p={1} mt={1}>
                      <MDInput
                        type="email"
                        placeholder="Email *"
                        value={values.email}
                        error={!!errors.email}
                        onChange={inputHandler("email")}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "45px",
                            border: "1px solid #9095A4",
                          },
                        }}
                      />
                    </MDBox>
                    {errors.email ? (
                      <MDTypography
                        px={1}
                        sx={{
                          fontSize: "13px",
                          fontWeight: "400",
                          lineHeight: "15px",
                        }}
                        color="error"
                      >
                        {errors.email}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      alignSelf: "start",
                      justifyContent: "start",
                      height: "100%",
                    }}
                  >
                    <MDBox p={1} mt={1}>
                      <InputMask
                        mask="(999) 999-9999"
                        value={values.phone_number}
                        disabled={false}
                        onChange={inputHandler("phone_number")}
                      >
                        {() => (
                          <MDInput
                            placeholder="Phone number *"
                            error={!!errors.phone_number}
                            fullWidth
                            sx={{
                              "& .MuiInputBase-root": {
                                borderRadius: "45px",
                                border: "1px solid #9095A4",
                              },
                            }}
                          />
                        )}
                      </InputMask>
                    </MDBox>
                    {errors.phone_number ? (
                      <MDBox px={1}>
                        <MDTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "15px",
                          }}
                          color="error"
                        >
                          {errors.phone_number}
                        </MDTypography>
                      </MDBox>
                    ) : null}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      alignSelf: "start",
                      justifyContent: "start",
                      height: "100%",
                    }}
                  >
                    <MDBox p={1} mt={1} sx={{ position: "relative" }}>
                      <MDInput
                        type={showPassword ? "text" : "password"}
                        placeholder="Password *"
                        value={values.password}
                        error={!!errors.password}
                        onChange={inputHandler("password")}
                        onFocus={() => setTyping(true)}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "45px",
                            border: "1px solid #9095A4",
                          },
                        }}
                      />

                      <MDBox
                        sx={{
                          position: "absolute",
                          right: "20px",
                          top: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => eyeButton()}
                      >
                        {showPassword ? (
                          <img src={showHideIcon} alt="icon" />
                        ) : (
                          <img src={dontShowIcon} alt="icon" />
                        )}
                      </MDBox>
                    </MDBox>

                    {errors.password ? (
                      <MDTypography
                        px={1}
                        sx={{
                          fontSize: "13px",
                          fontWeight: "400",
                          lineHeight: "15px",
                        }}
                        color="error"
                      >
                        {errors.password}
                      </MDTypography>
                    ) : null}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      alignSelf: "start",
                      justifyContent: "start",
                      height: "100%",
                    }}
                  >
                    {values.password && errors.password === "" ? (
                      <>
                        <MDBox p={1} mt={1} sx={{ position: "relative" }}>
                          <MDInput
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password *"
                            value={values.confirmPassword}
                            error={!!errors.confirmPassword}
                            onChange={inputHandler("confirmPassword")}
                            fullWidth
                            sx={{
                              "& .MuiInputBase-root": {
                                borderRadius: "45px",
                                border: "1px solid #9095A4",
                              },
                            }}
                          />
                          <MDBox
                            sx={{
                              position: "absolute",
                              right: "20px",
                              top: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => eyeButton2()}
                          >
                            {showConfirmPassword ? (
                              <img src={showHideIcon} alt="icon" />
                            ) : (
                              <img src={dontShowIcon} alt="icon" />
                            )}
                          </MDBox>
                        </MDBox>
                        {errors.confirmPassword ? (
                          <MDTypography
                            px={1}
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              lineHeight: "15px",
                            }}
                            color="error"
                          >
                            {errors.confirmPassword}
                          </MDTypography>
                        ) : null}
                      </>
                    ) : null}
                  </Grid>

                  {typing ? (
                    <Grid item xs={12} className="password-validations">
                      <MDBox sx={{ my: 2, px: 1 }}>
                        <MDBox sx={{ display: "flex" }}>
                          <MDBox sx={{ mr: 0.5 }}>
                            <img
                              src={
                                isLongEnough(values.password)
                                  ? correctIcon
                                  : wrongIcon
                              }
                            />
                          </MDBox>
                          <Typography
                            className={
                              isLongEnough(values.password)
                                ? "password-checker-after"
                                : "password-checker-before"
                            }
                          >
                            Must be at least 8 characters long
                          </Typography>
                        </MDBox>

                        <MDBox sx={{ display: "flex" }}>
                          <MDBox sx={{ mr: 0.5 }}>
                            <img
                              src={
                                isLongEnough(values.password)
                                  ? correctIcon
                                  : wrongIcon
                              }
                            />
                          </MDBox>
                          <Typography
                            className={
                              hasUpperCase(values.password)
                                ? "password-checker-after"
                                : "password-checker-before"
                            }
                          >
                            Include at least 1 uppercase letter
                          </Typography>
                        </MDBox>
                        <MDBox sx={{ display: "flex" }}>
                          <MDBox sx={{ mr: 0.5 }}>
                            <img
                              src={
                                isLongEnough(values.password)
                                  ? correctIcon
                                  : wrongIcon
                              }
                            />
                          </MDBox>
                          <Typography
                            className={
                              hasLowerCase(values.password)
                                ? "password-checker-after"
                                : "password-checker-before"
                            }
                          >
                            Include at least 1 lowercase letter
                          </Typography>
                        </MDBox>

                        <MDBox sx={{ display: "flex" }}>
                          <MDBox sx={{ mr: 0.5 }}>
                            <img
                              src={
                                isLongEnough(values.password)
                                  ? correctIcon
                                  : wrongIcon
                              }
                            />
                          </MDBox>
                          <Typography
                            className={
                              hasNumber(values.password)
                                ? "password-checker-after"
                                : "password-checker-before"
                            }
                          >
                            Include at least 1 number
                          </Typography>
                        </MDBox>

                        <MDBox sx={{ display: "flex" }}>
                          <MDBox sx={{ mr: 0.5 }}>
                            <img
                              src={
                                isLongEnough(values.password)
                                  ? correctIcon
                                  : wrongIcon
                              }
                            />
                          </MDBox>
                          <Typography
                            className={
                              hasSpecialChar(values.password)
                                ? "password-checker-after"
                                : "password-checker-before"
                            }
                          >
                            Include at least 1 special character (!@#$%^&*){" "}
                          </Typography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    p={1}
                    mt={1}
                    sx={{ alignSelf: "center", justifyContent: "center" }}
                  >
                    {!isLocalhost && (
                      <ReCAPTCHA
                        sitekey="6Ldp3lspAAAAAPZoobA6vLHHHOnWa4eTm9B-GyxB"
                        onChange={handleCaptchaChange}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <MDBox
                      mt={3}
                      mb={3}
                      sx={{
                        "@media(max-width:400px)": { width: "95%", mx: "auto"},
                      }}
                    >
                      {!isLocalhost ? (
                        <MDButton
                          color="info"
                          variant="gradient"
                          sx={{
                            fontFamily: "urbanist",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20.16px",
                            color: "#ffffff",
                            borderRadius: "45px",
                          }}
                          onClick={() => handleSubmit()}
                          disabled={!isCaptchaVerified}
                          fullWidth
                        >
                          Register
                        </MDButton>
                      ) : (
                        <MDButton
                          color="info"
                          variant="gradient"
                          sx={{
                            fontFamily: "urbanist",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20.16px",
                            color: "#ffffff",
                            borderRadius: "45px",
                          }}
                          onClick={() => handleSubmit()}
                          fullWidth
                        >
                          Register
                        </MDButton>
                      )}
                    </MDBox>
                    
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <MDBox sx={style}>
                        <MDBox sx={{ position: "relative", pt: 4 }}>
                          <MDBox
                            sx={{
                              position: "absolute",
                              top: "-95px",
                              left: "35%",
                            }}
                          >
                            <img src={ModalTickIcon} alt="profile_image" />
                          </MDBox>
                          <Typography
                            id="modal-modal-title"
                            sx={{
                              fontFamily: "urbanist",
                              fontSize: "32px",
                              fontWeight: 700,
                              lineHeight: "40.32px",
                              color: "#353535",
                            }}
                          >
                            Registration Successful!
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{
                              fontFamily: "urbanist",
                              mt: 2,
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "21px",
                              color: "#838383",
                              px: 4,
                            }}
                          >
                            In order to start the analysis, please add some
                            social information
                          </Typography>
                          <MDButton
                            color="info"
                            variant="gradient"
                            sx={{
                              fontFamily: "urbanist",
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "20.16px",
                              color: "#ffffff",
                              mt: 3,
                              borderRadius: "45px",
                            }}
                          >
                            Add Details
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </Modal>
                   
                    <MDBox mt={1} mb={1} textAlign="center">
                      <MDTypography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "20.16px",
                          color: "#747474",
                        }}
                      >
                        Already have an account?{" "}
                        <MDTypography
                          component={Link}
                          to="/sign-in"
                          // textGradient
                          sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20.16px",
                            color: "#1A73E8",
                          }}
                          onClick={() => dispatch(clearGoogleAddress())}
                        >
                          Login
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDTypography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "17px",
                  color: "#838383",
                  textAlign: "center",
                  pb: 0,
                  mb: 0,
                  mt: 3,
                }}
              >
                © 2024. All Rights Reserved.
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid
          item
          md={6}
          sx={{
            "@media (min-width:768px)": { display: "block" },
            display: "none",
          }}
        >
          <MDBox
            sx={{
              minHeight: "100vh",
              height: "100%",
              borderRadius: "0 0 0 200px",
              overflow: "hidden",
              backgroundImage: `url(${SignUpImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <MDBox
              sx={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <MDTypography
                  variant="h2"
                  fontWeight="regular"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                  textAlign="center"
                >
                  You are about to become in control of your online reviews,
                </MDTypography>

                <MDTypography
                  variant="h1"
                  fontWeight="bold"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                  textAlign="center"
                >
                  way to go!”
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Cover;
