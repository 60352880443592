import React from 'react'
import { useSelector } from 'react-redux';
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getUpdateBillingInfoUrl } from 'services/subscription';

const UpdateBillingInfoMenuItem = () => {
  const profileData = useSelector((state) => state.auth.getprofile);

  async function onClickUpdatePaymentMethod() {

    const userId = profileData?.userProfile?.id;

    if (!userId) {
      return;
    }

    try {
      const data = await getUpdateBillingInfoUrl(userId, window.location.href);

      if (!data?.url) {
        throw new Error('Unable to get update payment method url');
      }

      window.location.href = data.url;

    } catch (err) {
      console.log(err);
    }

  }

  return (
    <MenuItem
      className="hoverChangeColor"
      onClick={onClickUpdatePaymentMethod}
      sx={{
        padding: "16px 14px",
        borderRadius: "0",
        "& .MuiTypography-root": {
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "19.2px",
          color: "#292929",
          fontFamily: "urbanist",
        },
        "&:hover": {
          "& .MuiTypography-root": { color: "#ffffff" },
          backgroundColor: "#318BEC",
        },
        "&:hover svg": {
          filter: "invert(1)"
        },
        "&:hover svg line, &:hover svg path": {
          // stroke: "#333",
        },
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20" style={{ marginRight: "8px" }}>
        <rect x="10" y="25" width="80" height="50" rx="5" fill="#161616" />
        <rect x="15" y="35" width="70" height="5" fill="#FFF" />
        <rect x="15" y="45" width="40" height="5" fill="#FFF" />
        <rect x="60" y="15" width="30" height="60" rx="3" fill="#262626" />
        <line x1="65" y1="25" x2="85" y2="25" stroke="#fff" stroke-width="2" />
        <line x1="65" y1="35" x2="85" y2="35" stroke="#fff" stroke-width="2" />
        <line x1="65" y1="45" x2="85" y2="45" stroke="#fff" stroke-width="2" />
        <path d="M65,70 L70,65 L75,70 L80,65 L85,70" fill="none" stroke="#000" stroke-width="2" />
      </svg>
      <Typography>Billing Info</Typography>
    </MenuItem>
  )
}

export default UpdateBillingInfoMenuItem;
