import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const CheckAuth = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const Auth = localStorage.getItem("token");
    const businessId = localStorage.getItem("common_id");

    if (!Auth) {
      if (window.location.pathname != "/sign-up") {
        navigate("/sign-in");
      }
    } else {
      if (!businessId) {
        // if (getprofile?.businesses?.length > 0) {
        navigate("/my-business");
        // navigate("/business-detail");
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  return children;
};

export const CheckRoutes = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const Auth = localStorage.getItem("token");
    const businessId = localStorage.getItem("common_id");

    if (!Auth) {
      navigate("/");
    } else {
      if (!businessId) {
        // if (getprofile?.businesses?.length > 0) {
        navigate("/my-business");
        // navigate("/business-detail");
      }
    }
  }, [navigate]);

  return children;
};

