// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//added components
import Header from "../../verify-email/header";
import { Container, Grid } from "@mui/material";

//images
import logoMain from "assets/images/logo-main.svg";
import worldIcon from "assets/images/small-logos/world-icon.svg";
import messageIcon from "assets/images/small-logos/message-icon.svg";
import callIcon from "assets/images/small-logos/call-icon.svg";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";


function PrivacyPolicy() {

  return (
    <>
      <Header />
      <PageLayout>
        <MDBox
          sx={{
            minHeight: "100vh",
            textAlign: "start",
            maxWidth: "700px",
            margin: "0 auto",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <MDBox sx={{ textAlign: "start" }}>
            <h1 style={{ fontSize: "54px", color: "#292929" }}>
              Privacy Policy
            </h1>
          </MDBox>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>Introduction</li></ul></h3>
          </MDBox>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            Welcome to Review Attendant ("we," "our," or "us"). We are committed
            to protecting your privacy and ensuring that your personal
            information is handled in a safe and responsible manner. This
            Privacy Policy outlines the types of personal information we may
            collect, how we use and protect that information, and your rights
            regarding your personal data.
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>Information We Collect</li></ul></h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            We may collect and process the following types of information about
            you:
          </p>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            1. <b>Personal Identification Information:</b> Name, email address,
            phone number, mailing address, etc.
            <br />
            2. <b>Account Information:</b> Username, password, profile details,
            etc.
            <br />
            3. <b>Usage Data:</b> Information on how you use our web
            application, including your IP address, browser type, access times,
            pages viewed, and referring URL.
            <br />
            3. <b>Cookies and Tracking Technologies:</b> We use cookies and
            similar technologies to track the activity on our web application
            and hold certain information.
            <br />
            5. <b>Transaction Information:</b> Details of any transactions you
            carry out through our web application, including payment
            information.
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>How We Use Your Information </li></ul></h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>We use the information we collect for the following purposes: </p>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            1. <b>To Provide and Maintain Our Service:</b> To operate and
            maintain our web application, including providing customer support.
            <br />
            2. <b>To Improve Our Service:</b> To understand how our users
            interact with our web application, allowing us to improve our
            services and user experience.
            <br />
            3. <b>To Communicate with You:</b> To send you updates, newsletters,
            marketing materials, and other information that may be of interest
            to you.
            <br />
            4. <b>To Process Transactions:</b> To process payments and
            transactions made through our web application.
            <br />
            5. <b>To Comply with Legal Obligations:</b> To comply with
            applicable laws, regulations, and legal processes.
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>How We Share Your Information </li></ul></h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties except in the following
            circumstances:{" "}
          </p>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            1. <b>With Your Consent:</b> We may share your information with
            third parties if you give us your explicit consent.
            <br />
            2. <b>Service Providers:</b> We may share your information with
            third-party service providers who assist us in operating our web
            application, conducting our business, or providing services to you.
            <br />
            3. <b>Legal Requirements:</b> We may disclose your information if
            required to do so by law or in response to valid requests by public
            authorities (e.g., a court or government agency).
          </p>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>How We Share Your Information </li></ul></h3>
          </MDBox>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            We implement a variety of security measures to maintain the safety
            of your personal information. These measures include encrypted data
            storage, secure access controls, and regular security assessments.
            However, please note that no method of transmission over the
            Internet or method of electronic storage is 100% secure.{" "}
          </p>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>Your Rights</li></ul>  </h3>
          </MDBox>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>You have the right to: </p>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            1. <b>Access Your Information:</b> Request access to the personal
            information we hold about you.
            <br />
            2. <b>Correct Your Information:</b> Request that we correct any
            inaccuracies in your personal information.
            <br />
            3. <b>Delete Your Information:</b> Request that we delete your
            personal information, subject to certain legal restrictions. Object
            to Processing:
            <br />
            4. <b>Object to Processing: </b>Object to the processing of your
            personal information in certain circumstances.
            <br />
            5. <b>Data Portability:</b> Request a copy of your personal
            information in a structured, commonly used, and machine-readable
            format.{" "}
          </p>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            To exercise any of these rights, please contact us using the contact
            information provided below.{" "}
          </p>

          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>Changes to This Privacy Policy</li></ul>  </h3>
          </MDBox>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page
            and updating the "Last Updated" date at the top of this policy. We
            encourage you to review this Privacy Policy periodically for any
            changes.{" "}
          </p>
          <MDBox
            sx={{ textAlign: "start", color: "#589cf5", marginTop: "20px" }}
          >
            <h3 style={{fontSize:"25px",fontWeight: 700,lineHeight:"32px",color:"#318bec", marginBottom:"1rem"}}><ul><li>Contact Us</li></ul>  </h3>
          </MDBox>

          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            If you have any questions about this Privacy Policy, please contact
            us at:{" "}
          </p>
          <p style={{ fontSize:"18px",fontWeight: 500,lineHeight:"30px",color:"#6b6b6b"}}>
            Email: info@gratitudesystems.com
            <br />
            Address: 1050 Connecticut Ave, NW, Suite 500, Washington, DC 20036
            <br />
            Phone: +1 (202) 240-7754
          </p>
        </MDBox>
        <MDBox sx={{ backgroundColor: "#318BEC", py: 5 }}>
          <Container
            sx={{
              "@media (min-width:1580px)": { maxWidth: "1600px!important" },
            }}
          >
            <Grid container>
              <Grid item xxl={4} lg={3} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <MDBox>
                    <img src={logoMain} alt="logo" />
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item xxl={3} lg={4} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        mr: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={worldIcon} alt="icon" />
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="button"
                        sx={{
                          color: "#ffffff",
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "700",
                        }}
                      >
                        WWW.GRATITUDESYSTEMS.COM
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item lg={3} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        mr: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={messageIcon} alt="icon" />
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="button"
                        sx={{
                          color: "#ffffff",
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "700",
                        }}
                      >
                        INFO@GRATITUDESYSTEMS.COM
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>

              <Grid item lg={2} md={6}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDBox
                      sx={{
                        mr: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        pr: 0,
                      }}
                    >
                      <img src={callIcon} alt="icon" />
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="button"
                        sx={{
                          color: "#ffffff",
                          fontSize: "16px",
                          lineHeight: "19px",
                          fontWeight: "700",
                        }}
                      >
                        (202)240-7754
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Container>
        </MDBox>
      </PageLayout>
    </>
  );
}

export default PrivacyPolicy;
