import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Paper from "@mui/material/Paper";
import { Skeleton, Typography, Tabs, Tab, Box } from "@mui/material"; 
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import SentimentChart from "components/HighChart/SentimentChart";
import PerformanceChart from "components/HighChart/PerformanceChart";
import DoughnutChart from "components/HighChart/TopMentionedChart/positiveChart";
import DoughnutChartNegative from "components/HighChart/TopMentionedChart/negativeChart";
import ReviewSourceChart from "components/HighChart/SourceChart";
import MDTypography from "components/MDTypography";
import TableContainer from "@mui/material/TableContainer";
import dropdownIcon from "assets/images/dropdown-icon.svg";
import dashcol1 from "assets/images/dash-col1.png";
import dashcol2 from "assets/images/dash-col2.png";
import dashcol3 from "assets/images/dash-col3.png";
import dashcol4 from "assets/images/dash-col4.png";
import dashcol5 from "assets/images/dash-col5.png";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import PerformanceFilter from "../dashboard/performance-filter";
import DistributionFilter from "../dashboard/distribution-filter";
import DoughnutFilter from "../dashboard/doughnut-filter";
import PieChartFilter from "../dashboard/piechart-filter";
import TableFliterIcon from "assets/images/small-logos/table-filter-icon.svg";
import Loader from "../../assets/loader/loader";
import Cookies from "js-cookie";
import FilterModal from "../../examples/tableFilter/tableFilter";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {
  reviewPerformanceAsync,
  ratingsCountsAsync,
  reviewDistributionAsync,
  allReviewAsync,
  latestReviewAsync,
  itemCountAsync,
  sentimentCountAsync,
  markAsReadAsync,
  replayReviewsAsync,
  singleReviewAsync,
} from "features/actions/dashboard";
import {
  attentionArr,
  sourceArr,
  ratingArr,
  replyArr,
  chartColor,
  sourceLabels,
  dummyYearArr,
  dummyArr,
  sourceAbbr,
  sourceName,
} from "../../examples/commonFolder/commonfolder";
import {
  itemName,
  positiveItemName,
  replyState,
  reviewState,
} from "features/reducers/dashboard";
import { autoGenerateReplyAsync } from "features/actions/dashboard";
import { clearAIResponse } from "features/reducers/dashboard";
import { config } from "constant";
import { deleteCachesAsync } from "features/actions/dashboard";
import ReviewTable from "examples/table/reviewTable";
import ReviewDrawer from "components/Drawer";

function Dashboard() {
  const dispatch = useDispatch();
  const {
    counts,
    performance,
    sentiment,
    itemCount,
    distribution,
    lastestReviews,
    allreviews,
    singleReview,
    replyReview,
    autoReply,
    AIChatLoading,
  } = useSelector((state) => state.dashboard);
  const { getprofile } = useSelector((state) => state.auth);
  const PROFILEURI = config?.url?.PROFILEURI;
  const drawerRef = useRef(null);
  const [countsRatings, setCountsRatings] = useState();
  const [open, setOpen] = useState(false);
  const [handleReview, setHandleReview] = useState(false);
  const [doughnutNegativeArr, setDoughnutNegativeArr] = useState([]);
  const [doughnutNegativeData, setDoughnutNegativeData] = useState({});
  const [distKey, setDistKey] = useState();
  const [doughnutData, setDoughnutData] = useState({});
  const [doughnutArray, setDoughnutArray] = useState([]);
  const [currentReviews, setCurrentReviews] = useState([]);
  const [allReviewsData, setAllReviewsData] = useState([]);
  const [ratingCount, setRatingCount] = useState();
  const [checkComment, setCheckComment] = useState(false);
  const [buttonText, setButtonText] = useState("Copy");
  const [replayComment, setReplayComment] = useState();
  const [checkReply, setCheckReply] = useState();
  const [chartColorData, setChartColorData] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [singleReviewData, setSingleReviewData] = useState();
  const [botReply, setBotReply] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sourceAuthModal, setSourceAuthModal] = useState(false);
  const [chartColorData1, setChartColorData1] = useState();
  const [aIRepy, setAIRepy] = useState();
  const [aIRepyId, setAIRepyId] = useState();
  const [replies, setReplies] = useState([]);
  const [replyTextState, setReplyTextState] = useState(false);
  const [postiveCount, setPostiveCount] = useState();
  const [negativePercent, setNegativePercent] = useState();
  const [positivePercent, setPositivePercent] = useState();
  const [neutralPercent, setNeutralPercent] = useState();
  const [negativeCount, setNegativeCount] = useState();
  const [neutralCount, setNeutralCount] = useState();
  const [sentimentKey, setSentimentKey] = useState();
  const [yelpReviews, setYelpReviews] = useState();
  const [twitterReviews, setTwitterReviews] = useState();
  const [googleReviews, setGoogleReviews] = useState();
  const [facebookReviews, setFacebookReviews] = useState();
  const [replyText, setReplyText] = useState();
  const [userId, setUserId] = useState("");
  const [postButton, setPostButton] = useState(false);
  const [checkToken, setCheckToken] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const businessId = localStorage.getItem("common_id");
  const [error, setError] = useState("");
  const [facebookSideNav, setFacebookSideNav] = useState(null);
  const [xSideNav, setXSideNav] = useState(null);
  const [googleSideNav, setGoogleSideNav] = useState(null);
  const [yelpSideNav, setYelpSideNav] = useState(null);
  const [chatLoading, setChatLoading] = useState(false);
  const platform = sourceAbbr?.x;
  const platformName = sourceName?.x;

  const [allExpandedRows, setAllExpandedRows] = useState([]);
  const [pageReviews, setPageReviews] = useState(null);
  const [rowsPerPageAll, setRowsPerPageAll] = useState(10);
  const [paginationAllReviews, setPaginationAllReviews] = useState(false);
  const [selectedAttention, setSelectedAttention] = useState({
    label: "",
    value: "",
  });
  const [selectedSource, setSelectedSource] = useState({
    label: platformName,
    value: platform,
  });
  const [selectedRating, setSelectedRating] = useState({
    label: "",
    value: "",
  });
  const [selectedReply, setSelectedReply] = useState({ label: "", value: "" });
  const [authorName, setAuthorName] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const hasReviews = allReviewsData?.data?.length > 0;
  const hasSelectedRows = selectedRows?.length > 0;
  const reviewType = selectedTab === 0 ? currentReviews : allReviewsData;

  // api triggered on render

  useLayoutEffect(() => {
    setLoading(true);
    const businessId = localStorage.getItem("common_id");
    if (businessId) {
      dispatchApi();
    }
  }, [businessId]);

  const dispatchApi = () => {
    dispatch(ratingsCountsAsync({ common_id: businessId, source: platform }));
    dispatch(
      reviewPerformanceAsync({
        id: businessId,
        year: "",
        month: "",
        source: platform,
      })
    );
    dispatch(
      reviewDistributionAsync({
        id: businessId,
        year: "",
        source: platform,
      })
    );
    dispatch(
      sentimentCountAsync({
        common_id: businessId,
        year: "",
        source: platform,
      })
    );
    dispatch(
      itemCountAsync({
        id: businessId,
        service_type: "food",
        source: platform,
      })
    );
    dispatch(
      latestReviewAsync({
        common_id: businessId,
        source: platform,
      })
    );
    dispatch(
      allReviewAsync({
        page_number: pageReviews,
        page_size: rowsPerPageAll,
        source: platform,
        common_id: businessId,
      })
    );
  };

  useEffect(() => {
    if (
      (counts,
      performance,
      sentiment,
      itemCount,
      distribution,
      lastestReviews,
      allreviews)
    ) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [
    counts,
    performance,
    sentiment,
    itemCount,
    distribution,
    lastestReviews,
    allreviews,
  ]);

  // -------------------------------------------------------------------------------------------------------------------------------- //

  const getSource = (source) => {
    return sourceLabels[source] || "N/A";
  };

  const getAccessToken = () => {
    return Cookies.get("accessToken");
  };

  useEffect(() => {
    if (getprofile?.userProfile?.id) {
      setUserId(getprofile?.userProfile?.id);
    }
  }, [getprofile?.userProfile?.id]);

  // ----------------------------------------------------------------------------------------------------------------------------------- //

  // rating and count function

  useEffect(() => {
    if (counts) {
      setCountsRatings(counts);
    }
  }, [counts]);

  // --------------------------------------------------------------------------------------------------------------------- //

  // review performance chart function start

  useEffect(() => {
    if (performance) {
      setRatingCount(performance);
    }
  }, [performance]);

  // ------------------------------------------------------------------------------------------------------------------------------- //

  // sentiment chart function start

  useEffect(() => {
    if (sentiment) {
      const positive = Object.values(sentiment)?.map(
        (item) => item?.positive_count
      );
      const positivePercentage = Object.values(sentiment)?.map(
        (item) => item?.positive_percentage
      );
      const negative = Object.values(sentiment)?.map(
        (item) => item?.negative_count
      );
      const negativePercentage = Object.values(sentiment)?.map(
        (item) => item?.negative_percentage
      );
      const neutral = Object.values(sentiment)?.map(
        (item) => item?.neutral_count
      );
      const neutralPercentage = Object.values(sentiment)?.map(
        (item) => item?.neutral_percentage
      );
      const sentimentScale = Object.keys(sentiment);
      setNeutralCount(neutral);
      setNegativeCount(negative);
      setPostiveCount(positive);
      setSentimentKey(sentimentScale);
      setNeutralPercent(neutralPercentage);
      setPositivePercent(positivePercentage);
      setNegativePercent(negativePercentage);
    }
  }, [sentiment]);

  const sentimentChart = {
    categories: sentimentKey?.length > 0 ? sentimentKey : dummyYearArr,
    data: [
      {
        name: "Good",
        data: postiveCount?.length > 0 ? postiveCount : dummyArr,
        percentage:
          positivePercent?.length > 0 ? positivePercent : dummyArr + "%",
        color: chartColor[18],
      },
      {
        name: "Neutral",
        data: neutralCount?.length > 0 ? neutralCount : dummyArr,
        percentage:
          neutralPercent?.length > 0 ? neutralPercent : dummyArr + "%",
        color: chartColor[0],
      },
      {
        name: "Bad",
        data: negativeCount?.length > 0 ? negativeCount : dummyArr,
        percentage:
          negativePercent?.length > 0 ? negativePercent : dummyArr + "%",
        color: chartColor[1],
      },
    ],
  };

  // ----------------------------------------------------------------------------------------------------------------------------- //

  // most mentioned item chart function start

  useEffect(() => {
    if (itemCount !== undefined) {
      setDoughnutArray([itemCount?.positive]);
      setDoughnutData(itemCount?.positive);
      setDoughnutNegativeArr([itemCount?.negative]);
      setDoughnutNegativeData(itemCount?.negative);
    }
  }, [itemCount]);

  useEffect(() => {
    let doughnutDataTabArray = [];
    if (doughnutArray?.length > 0 && doughnutArray[0]) {
      for (const [index, name] of Object.keys(doughnutArray[0]).entries()) {
        doughnutDataTabArray.push({
          name,
          value: doughnutArray[0][name],
          color: chartColor[index],
        });
      }
    }
    setChartColorData(doughnutDataTabArray);
  }, [doughnutArray, chartColor]);

  useEffect(() => {
    let doughnutDataTabArray1 = [];
    if (doughnutNegativeArr?.length > 0 && doughnutNegativeArr[0]) {
      for (const [index, name] of Object.keys(
        doughnutNegativeArr[0]
      ).entries()) {
        doughnutDataTabArray1.push({
          name,
          value: doughnutNegativeArr?.[0][name],
          color: chartColor[index],
        });
      }
    }
    setChartColorData1(doughnutDataTabArray1);
  }, [doughnutNegativeArr]);

  const listNegativeReviews = (e) => {
    dispatch(itemName(e));
  };

  const listPositiveReviews = (e) => {
    dispatch(positiveItemName(e));
  };

  // ------------------------------------------------------------------------------------------------------------------------------ //

  // review count by source chart function start

  useEffect(() => {
    if (distribution) {
      const google = Object.values(distribution)?.map((item) => item?.g);
      const yelp = Object.values(distribution)?.map((item) => item?.y);
      const facebook = Object.values(distribution)?.map((item) => item?.f);
      const twitter = Object.values(distribution)?.map((item) => item?.t);
      const reviewCount = Object.keys(distribution);

      setGoogleReviews(google);
      setYelpReviews(yelp);
      setFacebookReviews(facebook);
      setTwitterReviews(twitter);
      setDistKey(reviewCount);
    }
  }, [distribution]);

  useEffect(() => {
    const businessId = localStorage.getItem("common_id");
    if (getprofile?.businesses?.length > 0) {
      const business = getprofile?.businesses?.find(
        (x) => x?.common_id == businessId
      );
      if (business) {
        setFacebookSideNav(business?.facebook_reviews_count);
        setXSideNav(business?.twitter_reviews_count);
        setGoogleSideNav(business?.google_reviews_count);
        setYelpSideNav(business?.yelp_reviews_count);
      }
    }
  }, [getprofile, businessId]);

  const reviewSourceChart = {
    categories: distKey?.length > 0 ? distKey : dummyArr,
    data: [
      ...(xSideNav
        ? [
            {
              name: "X",
              color: chartColor[22],
              data: twitterReviews?.length > 0 ? twitterReviews : dummyArr,
            },
          ]
        : []),
    ],
  };

  // ---------------------------------------------------------------------------------------------------------------------------- //

  // table function start

  useEffect(() => {
    if (allreviews?.data?.length > 0 || allreviews?.data?.length == 0) {
      setAllReviewsData(allreviews);
    }
  }, [allreviews]);

  const handleChangeTab = (event, newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  };

  const handlePageAllReviews = (data, newPage) => {
    setPaginationAllReviews(true);
    setPageReviews(newPage);
  };

  const handleRowsPerPageAllReviews = (event) => {
    setPaginationAllReviews(true);
    setRowsPerPageAll(parseInt(event.target.value));
    setPageReviews(0);
  };
  useEffect(() => {
    if (paginationAllReviews) {
      dispatch(
        allReviewAsync({
          common_id: businessId,
          page_size: rowsPerPageAll || 10,
          page_number: pageReviews + 1,
          need_attention: selectedAttention?.value,
          source: selectedSource?.value,
          rating: selectedRating?.value,
          author: authorName,
          reply_sent: selectedReply?.value,
          from_date: selectedStartDate
            ? dayjs(selectedStartDate).format("YYYY-MM-DD")
            : null,
          end_date: selectedEndDate
            ? dayjs(selectedEndDate).format("YYYY-MM-DD")
            : null,
        })
      );
      setPaginationAllReviews(false);
    }
  }, [
    paginationAllReviews,
    pageReviews,
    rowsPerPageAll,
    selectedAttention,
    selectedSource,
    selectedRating,
    selectedReply,
    selectedStartDate,
    selectedEndDate,
    dispatch,
    businessId,
  ]);
  useEffect(() => {
    if (lastestReviews?.data?.length > 0 || lastestReviews?.data?.length == 0) {
      setCurrentReviews(lastestReviews);
    }
  }, [lastestReviews]);

  const handleRowClick = async (row) => {
    try {
      setLoading(true);
      await dispatch(singleReviewAsync({ review_id: row?.id }));
      dispatch(clearAIResponse());
      setReplies([]);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleRowSelection = (x) => {
    const isSelected = selectedRows.includes(x.id);
    let updatedSelectedRows;

    if (isSelected) {
      updatedSelectedRows = selectedRows.filter(
        (selectedId) => selectedId !== x.id
      );
    } else {
      updatedSelectedRows = [...selectedRows, x.id];
    }

    setSelectedRows(updatedSelectedRows);
  };

  const AlltoggleRowExpansion = (index) => {
    setAllExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };

  useEffect(() => {
    if (handleReview && selectedRows) {
      markAsRead();
    }
  }, [handleReview, selectedRows]);

  const markAsRead = async () => {
    setLoading(true);
    await dispatch(
      markAsReadAsync({
        ids: selectedRows?.length > 0 ? selectedRows : [],
        common_id: businessId,
      })
    );
    dispatch(
      latestReviewAsync({
        common_id: businessId,
        source: platform,
      })
    );
    dispatch(
      allReviewAsync({
        common_id: businessId,
        page_number: pageReviews,
        page_size: rowsPerPageAll,
        source: platform,
      })
    )?.finally(() => setLoading(false));
    setHandleReview(false);
    setSelectedRows([]);
  };

  // --------------------------------------------------------------------------------------------------------------------------- //

  // table filter function start

  const handleFilter = async (filters) => {
    setLoading(true);
    try {
      setSelectedAttention(filters.selectedAttention);
      setSelectedSource(filters.selectedSource);
      setSelectedRating(filters.selectedRating);
      setSelectedReply(filters.selectedReply);
      setAuthorName(filters.authorName);
      setSelectedStartDate(filters.selectedStartDate);
      setSelectedEndDate(filters.selectedEndDate);
      setPaginationAllReviews(true);
      setRowsPerPageAll(10);
      setPageReviews(null);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleClearFilter = async () => {
    try {
      setLoading(true);
      await dispatch(
        allReviewAsync({
          common_id: businessId,
          page_size: 10,
          page_number: 1,
          source: platform,
        })
      );
      setSelectedAttention({
        label: "",
        value: "",
      });
      setSelectedSource({
        label: "",
        value: "",
      });
      setSelectedRating({
        label: "",
        value: "",
      });
      setSelectedReply({
        label: "",
        value: "",
      });
      setAuthorName("");
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------- //

  // Drawer function start

  useEffect(() => {
    if (singleReview?.data?.length > 0) {
      setSingleReviewData(singleReview?.data);
      setCheckToken(true);
      setOpenDrawer(true);
      setTimeout(() => {
        dispatch(
          allReviewAsync({
            common_id: businessId,
            page_size: rowsPerPageAll || 10,
            page_number: pageReviews + 1,
            source: selectedSource?.value,
            need_attention: selectedAttention?.value,
            rating: selectedRating?.value,
            reply_sent: selectedReply?.value,
            from_date: selectedStartDate,
            end_date: selectedEndDate,
          })
        );

        dispatch(
          latestReviewAsync({
            common_id: businessId,
            source: platform,
          })
        );
      }, 1000);
    }
  }, [singleReview, checkComment]);

  useEffect(() => {
    if (AIChatLoading) {
      setChatLoading(true);
    } else {
      setChatLoading(false);
    }
  }, [AIChatLoading]);

  useEffect(() => {
    if (replyReview?.data?.comment && replyReview?.data?.reply_sent) {
      setReplayComment(replyReview?.data?.comment);
      setCheckReply(replyReview?.data?.reply_sent);
      setCheckComment(true);
      toast.success("Reply sent Successfully");
    }
  }, [replyReview?.data?.reply_sent, replyReview?.data?.comment]);

  const toggleDrawer = () => {
    setOpenDrawer(false);
    setReplyText("");
    dispatch(reviewState());
    setCheckToken(false);
    setPostButton(false);
    dispatch(replyState());
    setCheckComment(false);
  };

  const openNav = () => {
    document.getElementById("drawerchat").style.width = "100%";
    document.getElementById("drawer-toggle").style.display = "none";
    setReplyTextState(true);
    setAIRepy(replies?.length == 0 && singleReviewData?.[0]?.Review);
    setAIRepyId(singleReviewData?.[0]?.id);
  };

  const reverseNav = () => {
    document.getElementById("drawer-toggle").style.display = "block";
    document.getElementById("drawerchat").style.width = "0%";
  };

  useEffect(() => {
    if (Object.keys(autoReply)?.length !== 0 && autoReply?.result) {
      const reply = { receiver: autoReply?.result };
      setReplies([...replies, reply]);
    }
  }, [autoReply]);

  useEffect(() => {
    if (replyTextState && (Object.keys(aIRepy)?.length !== 0 || botReply)) {
      const senderMessage = { sender: botReply ? botReply : aIRepy };
      setReplies([...replies, senderMessage]);
      dispatch(
        autoGenerateReplyAsync({
          review_id: aIRepyId?.toString(),
          response: senderMessage?.sender,
        })
      );
      setBotReply("");
      setAIRepy({});
      setReplyTextState(false);
    }
  }, [replyTextState, aIRepy, botReply]);

  const scrollToBottom = () => {
    drawerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [replies]);

  const handleReviewReply = () => {
    setReplyTextState(true);
  };

  const handleChat = (e) => {
    setReplyTextState(false);
    setBotReply(e.target.value);
  };

  const handleSelectResponse = (e, index) => {
    setReplyText(e.currentTarget.textContent);
    reverseNav();
  };

  const handleReply = async (reviewData) => {
    if (replyText?.trim() === "") {
      setError("Please enter a valid reply.");
      return;
    }
    setError("");
    const token = getAccessToken();
    if (reviewData?.Source == "g") {
      try {
        setLoading(true);
        await dispatch(
          replayReviewsAsync({
            rev_id: reviewData?.id,
            user_id: userId,
            common_id: businessId,
            access_token: token,
            reply: replyText,
          })
        );
        await dispatch(
          deleteCachesAsync({ review_id: reviewData?.id?.toString() })
        );
        setLoading(false);
        setReplyText("");
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    } else if (reviewData?.Source == "g") {
      setSourceAuthModal(true);
    }
  };

  const handleCopyResponse = () => {
    navigator.clipboard
      .writeText(replyText)
      .then(() => {
        console.log("Reply text copied to clipboard:", replyText);
        toast.success("Reply copied");
        setButtonText("Copied");
        setTimeout(() => {
          setButtonText("Copy");
        }, 2000);
      })
      .catch((error) => {
        console.error("Could not copy text: ", error);
      });
  };

  useEffect(() => {
    if (checkToken && singleReviewData) {
      const token = getAccessToken();
      if (singleReviewData?.[0]?.Source == "g" && token) {
        setPostButton(true);
      }
    }
  }, [checkToken]);

  // ------------------------------------------------------------------------------------------------------------------------------- //

  return (
    <>
      {loading && <Loader />}
      <MDBox sx={{ background: "#f9f9f9" }}>
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox
            sx={{
              display: "flex",
              alignitems: "center",
              justifyContent: "end",
              px: 3,
            }}
          ></MDBox>

          <MDBox
            sx={{ padding: 3, "@media(max-width:430px)": { padding: "4px" } }}
          >
            <Grid
              container
              spacing={2}
              marginBottom={2}
              sx={{ flexWrap: "wrap" }}
            >
              <Grid
                item
                sx={{
                  "@media(max-width: 1920px)": { width: "20%" },
                  "@media(max-width: 1600px)": { width: "33.33%" },
                  "@media(max-width: 1300px)": { width: "50%" },
                  "@media(max-width: 768px)": { width: "100%" },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{ height: "100%", p: 1, borderRadius: "20px" }}
                >
                  <MDBox sx={{ display: "flex", py: 1, px: 1.5 }}>
                    <MDBox sx={{ height: "100%", mr: 1 }}>
                      <img
                        src={dashcol1}
                        alt="list-icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </MDBox>
                    <MDBox sx={{ my: "auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#707EAE",
                          fontFamily: "urbanist",
                        }}
                      >
                        Total Number of Reviews
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "32px",
                          color: "#292929",
                          fontFamily: "urbanist",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                            width={100}
                            height={17}
                          />
                        ) : (
                          countsRatings?.total_reviews || 0
                        )}
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Paper>
              </Grid>

              <Grid
                item
                sx={{
                  "@media(max-width: 1920px)": { width: "20%" },
                  "@media(max-width: 1600px)": { width: "33.33%" },
                  "@media(max-width: 1300px)": { width: "50%" },
                  "@media(max-width: 768px)": { width: "100%" },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{ height: "100%", p: 1, borderRadius: "20px" }}
                >
                  <MDBox sx={{ display: "flex", py: 1, px: 1.5 }}>
                    <MDBox sx={{ height: "100%", mr: 1 }}>
                      <img
                        src={dashcol2}
                        alt="list-icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </MDBox>

                    <MDBox sx={{ my: "auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#707EAE",
                          fontFamily: "urbanist",
                        }}
                      >
                        Average Rating
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "32px",
                          color: "#292929",
                          fontFamily: "urbanist",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                            width={50}
                            height={10}
                          />
                        ) : (
                          countsRatings?.average_rating || 0
                        )}

                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={17}
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                          />
                        ) : (
                          <Rating
                            readOnly
                            style={{ marginLeft: "5px" }}
                            name="half-rating"
                            value={countsRatings?.average_rating || 0}
                            precision={0.5}
                          />
                        )}
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Paper>
              </Grid>

              <Grid
                item
                sx={{
                  "@media(max-width: 1920px)": { width: "20%" },
                  "@media(max-width: 1600px)": { width: "33.33%" },
                  "@media(max-width: 1300px)": { width: "50%" },
                  "@media(max-width: 768px)": { width: "100%" },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{ height: "100%", p: 1, borderRadius: "20px" }}
                >
                  <MDBox sx={{ display: "flex", py: 1, px: 1.5 }}>
                    <MDBox sx={{ height: "100%", mr: 1 }}>
                      <img
                        src={dashcol3}
                        alt="list-icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </MDBox>
                    <MDBox sx={{ my: "auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#707EAE",
                          fontFamily: "urbanist",
                        }}
                      >
                        Average Food Rating
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "32px",
                          color: "#292929",
                          fontFamily: "urbanist",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            style={{ marginTop: "5px", borderRadius: "20px" }}
                            width={50}
                            height={10}
                          />
                        ) : (
                          countsRatings?.average_food_rating || 0
                        )}

                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={17}
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                          />
                        ) : (
                          <Rating
                            readOnly
                            style={{ marginLeft: "5px" }}
                            name="half-rating"
                            value={countsRatings?.average_food_rating || 0}
                            precision={0.5}
                          />
                        )}
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Paper>
              </Grid>

              <Grid
                item
                sx={{
                  "@media(max-width: 1920px)": { width: "20%" },
                  "@media(max-width: 1600px)": { width: "33.33%" },
                  "@media(max-width: 1300px)": { width: "50%" },
                  "@media(max-width: 768px)": { width: "100%" },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{ height: "100%", p: 1, borderRadius: "20px" }}
                >
                  <MDBox sx={{ display: "flex", py: 1, px: 1.5 }}>
                    <MDBox sx={{ height: "100%", mr: 1 }}>
                      <img
                        src={dashcol4}
                        alt="list-icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </MDBox>
                    <MDBox sx={{ my: "auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#707EAE",
                          fontFamily: "urbanist",
                        }}
                      >
                        Average Beverage Rating
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "32px",
                          color: "#292929",
                          fontFamily: "urbanist",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                            width={50}
                            height={10}
                          />
                        ) : (
                          countsRatings?.average_drink_rating || 0
                        )}

                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={17}
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                          />
                        ) : (
                          <Rating
                            readOnly
                            style={{ marginLeft: "5px" }}
                            name="half-rating"
                            value={countsRatings?.average_drink_rating || 0}
                            precision={0.5}
                          />
                        )}
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Paper>
              </Grid>

              <Grid
                item
                sx={{
                  "@media(max-width: 1920px)": { width: "20%" },
                  "@media(max-width: 1600px)": { width: "33.33%" },
                  "@media(max-width: 1300px)": { width: "50%" },
                  "@media(max-width: 768px)": { width: "100%" },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{ height: "100%", p: 1, borderRadius: "20px" }}
                >
                  <MDBox sx={{ display: "flex", py: 1, px: 1.5 }}>
                    <MDBox sx={{ height: "100%", mr: 1 }}>
                      <img
                        src={dashcol5}
                        alt="list-icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </MDBox>
                    <MDBox sx={{ my: "auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "19px",
                          color: "#707EAE",
                          fontFamily: "urbanist",
                        }}
                      >
                        Average Service Rating
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "32px",
                          color: "#292929",
                          fontFamily: "urbanist",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                            width={50}
                            height={10}
                          />
                        ) : (
                          countsRatings?.average_service_rating || 0
                        )}

                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={17}
                            style={{ marginTop: "5px", borderRadius: "5px" }}
                          />
                        ) : (
                          <Rating
                            readOnly
                            style={{ marginLeft: "5px" }}
                            name="half-rating"
                            value={countsRatings?.average_service_rating}
                            precision={0.5}
                          />
                        )}
                      </Typography>
                    </MDBox>
                  </MDBox>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Paper
                  elevation={0}
                  sx={{
                    "@media(max-width:550px)": {
                      py: "10px",
                      px: "4px",
                    },
                    px: 3,
                    py: 3,
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  <MDBox
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: "50px",
                      "@media(min-width:551px)": {
                        right: "10px",
                        top: "4px",
                      },
                    }}
                  >
                    <PerformanceFilter optionsPerf={platformName} />
                  </MDBox>
                  <MDBox mb={1.5}>
                    <PerformanceChart data={ratingCount} />
                  </MDBox>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Paper
                  elevation={0}
                  sx={{
                    "@media(max-width:550px)": {
                      py: "10px",
                      px: "7px",
                    },
                    py: 3,
                    px: 3,
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  <MDBox
                    sx={{
                      position: "absolute",
                      top: "50px",
                      right: "10px",
                      "@media(min-width:551px)": {
                        right: "10px",
                        top: "4px",
                      },
                    }}
                  >
                    <PieChartFilter optionsPie={platformName} />
                  </MDBox>
                  <MDBox mb={1.5}>
                    <SentimentChart
                      // title="Overall Sentiment"
                      data={sentimentChart?.data}
                      scale={sentimentChart?.categories}
                      // loading={loading}
                    />
                  </MDBox>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  elevation={0}
                  sx={{
                    px: "1rem",
                    pb: "1rem",
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  <MDBox
                    sx={{
                      py: "1.2rem",
                      mb: 0,
                      "@media(max-width:575px)": {
                        pb: "3rem",
                        pt: "1.2rem",
                        mb: "2rem",
                      },
                    }}
                  >
                    <MDTypography
                      sx={{
                        color: "#292929",
                        fontFamily: "Urbanist",
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "21.6px",
                      }}
                    >
                      Most Mentioned Items
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    sx={{
                      position: "absolute",
                      right: "15px",
                      top: "63px",
                      "@media(min-width:551px)": {
                        right: "20px",
                        top: "10px",
                      },
                    }}
                  >
                    <DoughnutFilter optionsDough={platformName} />
                  </MDBox>

                  <Grid container spacing={1.8} alignItems={'stretch'} sx={{ height: {xs:'auto', lg:'400px', xxl:'320px'}}}>
                  <Grid item xs={12} md={12} lg={6} sx={{ height: "100%" }}>
                      <Paper
                        elevation={0}
                        sx={{
                          px: "18px",
                          pb: "23px",
                          pt: "15px",
                          borderRadius: "12px",
                          border: "1px solid #00000014",
                          position: "relative",
                          height:'100%'
                        }}
                      >
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"start"}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={4}
                          >
                            <MDBox>
                              <DoughnutChart
                                DoughnutChart={doughnutData}
                                colorArr={chartColor}
                                setLoading={setLoading}
                                source={platformName}
                                positiveSentiment={"positive"}
                              />
                            </MDBox>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={7}
                            sx={{ marginTop: "2rem" }}
                          >
                            {chartColorData?.length > 0 && (
                              <div className="main-wrapper-outer">
                                <div className="main-wrapper">
                                  {chartColorData?.map((item) => {
                                    return (
                                      <div
                                        className="innerItems-outer"
                                        onClick={() =>
                                          listPositiveReviews(item?.name)
                                        }
                                        key={item.name}
                                      >
                                        <div className="innerItems">
                                          <p className="item-name">
                                            <span
                                              className="item-dot"
                                              style={{
                                                backgroundColor: item?.color,
                                              }}
                                            ></span>
                                            <span className="item-name-inner">
                                              {item.name}
                                            </span>
                                          </p>
                                          <p className="item-value">
                                            {item.value}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} sx={{ height: "100%" }}>
                      <Paper
                        elevation={0}
                        sx={{
                          px: "18px",
                          pb: "23px",
                          pt: "15px",
                          borderRadius: "12px",
                          border: "1px solid #00000014",
                          position: "relative",
                           height:'100%'
                        }}
                      >
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"start"}
                          sx={{
                            // marginTop: 4,
                            "@media(min-width:576px)": { marginTop: 0 },
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={4}
                          >
                            <MDBox>
                              <DoughnutChartNegative
                                DoughnutChart={doughnutNegativeData}
                                colorArr={chartColor}
                                setLoading={setLoading}
                                source={platformName}
                                negativeSentiment={"negative"}
                              />
                            </MDBox>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={7}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "2rem",
                            }}
                          >
                            {chartColorData1?.length > 0 && (
                              <div className="main-wrapper-outer">
                                <div className="main-wrapper">
                                  {chartColorData1?.map((item) => {
                                    return (
                                      <div
                                        className="innerItems-outer"
                                        onClick={() =>
                                          listNegativeReviews(item?.name)
                                        }
                                        key={item.name}
                                      >
                                        <div className="innerItems">
                                          <p className="item-name">
                                            <span
                                              className="item-dot"
                                              style={{
                                                backgroundColor: item?.color,
                                              }}
                                            ></span>
                                            <span className="item-name-inner">
                                              {item.name}
                                            </span>
                                          </p>
                                          <p className="item-value">
                                            {item.value}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  elevation={0}
                  sx={{
                    "@media(max-width:550px)": {
                      py: "7px",
                      px: "7px",
                    },
                    px: 3,
                    py: 3,
                    height: "100%",
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  <MDBox
                    sx={{
                      position: "absolute",
                      right: "15px",
                      top: "40px",
                      "@media(min-width:551px)": {
                        right: "15px",
                        top: "6px",
                      },
                    }}
                  >
                    <DistributionFilter optionsDist={platformName} />
                  </MDBox>

                  <MDBox mb={1.5}>
                    <ReviewSourceChart
                      multiColumnChartCategories={reviewSourceChart?.categories}
                      multiChartData={reviewSourceChart?.data}
                    />
                  </MDBox>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  elevation={0}
                  sx={{
                    py: 0,
                    height: "100%",
                    borderRadius: "12px",
                    background: "transparent",
                  }}
                >
                  <MDBox></MDBox>

                  <MDBox>
                    <TableContainer
                      sx={{ width: "100%" }}
                      component={Paper}
                      elevation={0}
                    >
                      <MDBox>
                        <MDBox
                          sx={{
                            paddingBottom: "0.5rem",
                            px: 1,
                            pt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            alignItems: "baseline",
                            "@media(min-width:690px)": {
                              flexDirection: "row",
                            },
                            "@media(min-width:550px)": {
                              flexDirection: "row",
                              px: 3,
                            },
                            width: "100%",
                          }}
                        >
                          <MDTypography
                            sx={{
                              fontSize: "22px",
                              fontWeight: "700",
                              lineHeight: "26px",
                              textAlign: "left",
                              color: "#292929",
                              "@media(max-width:576px)": {
                                fontSize: "18px",
                              },
                            }}
                          >
                            Reviews
                          </MDTypography>
                          <MDBox
                            sx={{
                              mr: 0,
                              mt: 3,
                              mb: 1,
                              "@media(min-width:690px)": {
                                mt: 0,
                                mb: 0,
                                ml: 0,
                                mr: 3,
                              },
                              "@media(max-width:348px)": {
                                display: "flex",
                                flexDirection: "column",
                              },
                            }}
                          >
                            {(hasReviews && hasSelectedRows) ||
                            (hasReviews && !hasSelectedRows) ? (
                              <MDButton
                                color="info"
                                elevation={0}
                                boxShadow={0}
                                onClick={() => setHandleReview(true)}
                                sx={{
                                  borderRadius: "45px",
                                  fontFamily: "urbanist",
                                  mr: 3,
                                  ...(hasSelectedRows && {
                                    "@media(max-width:348px)": { mb: 1.5 },
                                  }),
                                }}
                              >
                                {hasSelectedRows
                                  ? "Mark as read"
                                  : "Mark all as read"}
                              </MDButton>
                            ) : null}
                            {selectedTab == 1 && (
                              <MDButton
                                color="info"
                                elevation={0}
                                sx={{
                                  fontFamily: "urbanist",
                                  backgroundColor: "#F0F7FF",
                                  borderRadius: "45px",
                                  boxShadow: "none",
                                  padding: "0px 30px",
                                  border: "1px solid #318BEC",
                                  color: "#318BEC",
                                  "&:hover": {
                                    border: "1px solid #318BEC",
                                    backgroundColor: "#318BEC14",
                                  },

                                  "&:focus:not(:hover)": {
                                    border: "1px solid #318BEC",
                                    backgroundColor: "#318BEC14",
                                    boxShadow: "none",
                                  },
                                }}
                                onClick={() => setOpen(true)}
                              >
                                {" "}
                                <MDBox sx={{ mr: 1, mt: 0.5 }}>
                                  <img src={TableFliterIcon} alt="list-icon" />
                                </MDBox>
                                Filter
                                <MDBox sx={{ ml: 1, mb: 0.3 }}>
                                  <img
                                    src={dropdownIcon}
                                    alt="icon"
                                    style={{ maxWidth: "100%" }}
                                  />
                                </MDBox>
                              </MDButton>
                            )}

                            {/* Table Filter Component */}
                            <FilterModal
                              open={open}
                              handleClose={() => setOpen(false)}
                              attentionArr={attentionArr}
                              sourceArr={sourceArr}
                              ratingArr={ratingArr}
                              replyArr={replyArr}
                              handleFilter={handleFilter}
                              handleClearFilter={handleClearFilter}
                              platform={platform}
                              platformName={platformName}
                              initialFilters={{
                                selectedAttention,
                                selectedSource,
                                selectedRating,
                                selectedReply,
                                authorName,
                                selectedStartDate,
                                selectedEndDate,
                              }}
                            />
                          </MDBox>
                        </MDBox>
                        <MDBox
                          sx={{
                            borderBottom: "2px solid #F4F4F4",
                          }}
                        >
                          <MDBox
                            sx={{
                              ml: 1,
                              width: "90%",
                              "@media(min-width:690px)": { width: "40%" },
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Tabs
                                value={selectedTab}
                                onChange={handleChangeTab}
                                variant="fullWidth"
                                sx={{
                                  background: "transparent !important",
                                  overflow: "visible",
                                }}
                                classes={{ indicator: "indicator" }}
                              >
                                <Tab
                                  sx={{
                                    zIndex: 1,
                                    fontSize: "18px",
                                    "@media(max-width:576px)": {
                                      fontSize: "16px",
                                    },
                                    fontWeight: "600",
                                    lineHeight: "21px",
                                    borderRadius: "0",
                                    textAlign: "center",
                                    marginBottom: "-4px",
                                    fontFamily: "urbanist",
                                    py: 2,
                                    mr: 2,
                                    borderBottom:
                                      selectedTab === 0
                                        ? "4px solid #318BEC"
                                        : "4px solid transparent",
                                    color:
                                      selectedTab === 0
                                        ? "#318BEC !important"
                                        : "#292929 !important",
                                  }}
                                  label="Latest Reviews"
                                />
                                <Tab
                                  sx={{
                                    fontSize: "18px",
                                    "@media(max-width:576px)": {
                                      fontSize: "16px",
                                    },
                                    fontWeight: "600",
                                    lineHeight: "21px",
                                    borderRadius: "0",
                                    textAlign: "center",
                                    marginBottom: "-4px",
                                    fontFamily: "urbanist",
                                    borderBottom:
                                      selectedTab === 1
                                        ? "4px solid #318BEC"
                                        : "4px solid transparent",
                                    color:
                                      selectedTab === 1
                                        ? "#318BEC !important"
                                        : "#292929 !important",
                                  }}
                                  label="All Reviews"
                                />
                              </Tabs>
                            </Box>
                          </MDBox>
                        </MDBox>
                      </MDBox>

                      {/* Table component */}
                      <ReviewTable
                        reviewType={reviewType}
                        selectedRows={selectedRows}
                        handleRowSelection={handleRowSelection}
                        handleRowClick={handleRowClick}
                        AlltoggleRowExpansion={AlltoggleRowExpansion}
                        allExpandedRows={allExpandedRows}
                        getSource={getSource}
                        loading={loading}
                        allreviews={allreviews}
                        rowsPerPageAll={rowsPerPageAll}
                        pageReviews={pageReviews}
                        handlePageAllReviews={handlePageAllReviews}
                        handleRowsPerPageAllReviews={
                          handleRowsPerPageAllReviews
                        }
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        selectedTab={selectedTab}
                        businessId={businessId}
                        selectedSource={selectedSource}
                      />
                    </TableContainer>
                  </MDBox>
                </Paper>
              </Grid>
            </Grid>
          </MDBox>

         
          <ReviewDrawer
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            checkComment={false}
            singleReviewData={singleReviewData}
            replyText={replyText}
            setReplyText={setReplyText}
            replayComment={replayComment}
            handleCopyResponse={handleCopyResponse}
            handleReply={handleReply}
            loading={loading}
            buttonText={buttonText}
            postButton={postButton}
            sourceAuthModal={sourceAuthModal}
            setSourceAuthModal={setSourceAuthModal}
            openNav={openNav}
            reverseNav={reverseNav}
            drawerRef={null}
            replies={replies}
            handleSelectResponse={handleSelectResponse}
            chatLoading={chatLoading}
            botReply={botReply}
            handleChat={handleChat}
            handleReviewReply={handleReviewReply}
            error={error}
            getprofile={getprofile}
            userProfileImage={PROFILEURI}
            checkReply={checkReply}
          />
        </DashboardLayout>
      </MDBox>
    </>
  );
}

export default Dashboard;
