import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";
import logoHeader from "assets/images/color-logo.svg";

// Images
import { useEffect, useState } from "react";
import Loader from "../../../assets/loader/loader";

import showHideIcon from "assets/images/small-logos/show-hide-icon.svg"
import dontShowIcon from "assets/images/small-logos/dont-show.svg"
import SignUpImage from "assets/images/sign-up-bg.jpg";

import { resetPasswordAysnc } from "features/actions/auth";
import { clearResetPasswordState } from "features/reducers/auth";

function NewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { resetPassword } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirPassword, setShowConfirmPassword] = useState(false);

  // const { registerData } = useSelector((state) => state.auth);

  const [values, setValues] = useState({
    new_password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    new_password: "",
    confirmPassword: "",
  });


  const validateForm = () => {
    const newErrors = {};
    const checkPassword =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    // Validate email

    // Validate password
    if (!values.new_password) {
      newErrors.new_password = "Password is required.";
    } else if (!checkPassword.test(values.new_password)) {
      newErrors.new_password = "Invalid password format";
    }
    if (!values.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required.";
    } else if (!checkPassword.test(values.confirmPassword)) {
      newErrors.confirmPassword = "Invalid password format";
    } else if (values.new_password !== values.confirmPassword) {
      newErrors.confirmPassword =
        "Password and Confirm password is not matched";
    }

    return newErrors;
  };



  const payload = new FormData();
  Object.keys(values).forEach((key) => {
    if (key == "new_password") {
      payload.append(key, values[key]);
    } else {
    }
  });

  useEffect(() => {
    if (submit) {
      if (Object.keys(errors).length === 0) {
        setLoading(true);
        // Dispatch the asynchronous action
        let config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            token: params?.token,
          },
        };
        dispatch(resetPasswordAysnc({ payload, config }))
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
      setSubmit(false);
    }
  }, [submit]);

  const inputHandler = (fieldName) => {
    return (e) => {
      setValues({ ...values, [fieldName]: e.target.value });
    };
  };

  const handleSubmit = async () => {
    setErrors(validateForm(values));
    setSubmit(true);
  };
  useEffect(() => {
    if (resetPassword?.statusCode == 200) {
      navigate("/sign-in");
      dispatch(clearResetPasswordState());
    }
  }, [resetPassword]);


  const eyeButtonPassword = () => {
    setShowPassword(!showPassword);
  };
  const eyeButtonConfirmPassword = () => {
    setShowConfirmPassword(!showConfirPassword);
  };

  return (
    <PageLayout>
      {loading && <Loader />}
      <Grid
        container
        sx={{ height: "100vh", marginleft: "10px" }}
        justifyContent="space-between"
      >
        <Grid item md={5} px={5} sx={{ alignSelf: "center", justifyContent: "center", p: 2, width: "100%" }}>
          <NavLink to="/">
            <MDBox sx={{ ml: 1, pt:0, mt:0 }}><img src={logoHeader} alt="profile_image" /></MDBox>
          </NavLink>

          <MDBox px={5} pt={5} mt={5} sx={{ '@media (min-width:768px)': { paddingLeft: "2rem!important", paddingRight: "2rem!important", }, paddingLeft: "0!important", paddingRight: "0!important",minHeight:"80vh" }}>
            <MDBox ml={1} mt={-2} py={1} mb={1} textAlign="left">

              <MDTypography variant="h2" fontWeight="medium" mt={1}>
             Enter New Password
              </MDTypography>


            </MDBox>
            <MDBox pt={1} pb={3} px={1} >
              <MDBox component="form" role="form">
               <Grid container>
               <Grid item xs={12}  sx={{ alignSelf: "center", justifyContent: "center", my:3 }}>
                  <MDBox  mt={1} sx={{position:"relative"}}>

                      <MDInput
                       type={showPassword ? "text" : "password"}
                       placeholder="Password *"
                       value={values.new_password}
                       fullWidth
                       error={!!errors.new_password}
                       onChange={inputHandler("new_password")}
                       sx={{
                        "& .MuiInputBase-root": { borderRadius: "45px" },"& .MuiOutlinedInput-root":{borderRadius:"45px"}
                      }}
                      />
                       <MDBox sx={{position:'absolute',right:"15px",top:"5px",cursor:"pointer"}}
                     onClick={() => eyeButtonPassword()}>{showPassword ? <img src={showHideIcon} alt="icon" /> : <img src={dontShowIcon} alt="icon" />}</MDBox>
                    </MDBox>
                    {errors.new_password ? (
                    <MDTypography px={1} variant="button" color="error">
                      {errors.new_password}
                    </MDTypography>
                  ) : null}
                  </Grid>
                  <Grid item xs={12}  sx={{ alignSelf: "center", justifyContent: "center" }}>
                  <MDBox  mt={1} sx={{position:"relative"}}>

                      <MDInput
                         type={showConfirPassword ? "text" : "password"}
                         placeholder="Confirm Password *"
                         fullWidth
                         value={values.confirmPassword}
                         error={!!errors.confirmPassword}
                         onChange={inputHandler("confirmPassword")}
                         sx={{
                          "& .MuiInputBase-root": { borderRadius: "45px" },"& .MuiOutlinedInput-root":{borderRadius:"45px"}
                        }}
                      />
                       <MDBox sx={{position:'absolute',right:"15px",top:"5px",cursor:"pointer"}}
                     onClick={() => eyeButtonConfirmPassword()}>{showConfirPassword ? <img src={showHideIcon} alt="icon" /> : <img src={dontShowIcon} alt="icon" />}</MDBox>
                    </MDBox>
                    {errors.confirmPassword ? (
                    <MDTypography px={1} variant="button" color="error">
                      {errors.confirmPassword}
                    </MDTypography>
                  ) : null}
                  </Grid>
               </Grid>

                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" sx={{borderRadius:"45px"}} onClick={handleSubmit} fullWidth>
                   Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item md={6} sx={{ '@media (min-width:768px)': { display: "block" }, display: "none" }}>
              <MDBox
                sx={{
                  minHeight: "100vh",
                  height: "100%",
                  borderRadius: "0 0 0 65px",
                  overflow: "hidden",
                  backgroundImage: `url(${SignUpImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <MDBox
                  sx={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "60%"
                    }}
                  >
                    <MDTypography
                      variant="h2"
                      fontWeight="regular"
                      sx={{ color: "#ffffff" }}
                      mt={1}
                      textAlign="center"
                    >
                     Reset your password and rediscover the power of uplifting app experiences
                    </MDTypography>

                    <MDTypography variant="h1" fontWeight="bold" sx={{ color: "#ffffff" }} mt={1} textAlign="center">
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
      </Grid>
    </PageLayout>
  );
}

export default NewPassword;
