import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import burceMars from "assets/images/dummy.png";
import Link from "@mui/material/Link";

import { useNavigate } from "react-router-dom";
import { config } from "constant";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { UpdateBillingInfoMenuItem, SubscribeMenuItem } from "components/Subscribe";

const PROFILEURI = config?.url?.PROFILEURI;
export default function CustomizedMenus2() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logout, setLogout] = useState(false);
  const businessId = localStorage.getItem("common_id");

  const { getprofile } = useSelector((state) => state.auth);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogout = async () => {
    try {
      await fetch("https://ticket.reviewattendant.com/logoutsupport.php", {
        method: "GET",
        credentials: "include",
        mode: "cors",
      });
    } catch (error) {
      console.error("Error during logout API request:", error);
    } finally {
      localStorage.clear();
      navigate("/sign-in");
      setLogout(false);
    }
  };

  useEffect(() => {
    if (logout) {
      handleLogout();
    }
  }, [logout]);


  const handleMyBusiness = () => {
    if (
      businessId == 0 &&
      getprofile?.businesses?.length > 0 &&
      getprofile?.user_business_status?.[0]?.true_count !== 0
    ) {
      toast.error("Please choose any business.");
    } else {
      navigate("/my-business");
    }
  };

  const handleProfile = () => {
    if (
      businessId == 0 &&
      getprofile?.businesses?.length > 0 &&
      getprofile?.user_business_status?.[0]?.true_count !== 0
    ) {
      toast.error("Please choose any business.");
    } else {
      navigate("/profile");
    }
  };

  const handleTicketUrl = () => {
    const url = `https://ticket.reviewattendant.com/login.php?luser=${getprofile?.userProfile?.email}`;
    window.location.href = url;
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        disableRipple
        onClick={handleClick}
        sx={{
          padding: 0,
          background: "none !important",
          "&:hover": {
            background: "none !important",
          },
          "&:active": {
            background: "none !important",
          },
          "&:focus": {
            background: "none !important",
          },
        }}
      >
        <MDBox
          component={Link}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              getprofile?.userProfile?.profile_picture && 
              getprofile?.userProfile?.profile_picture.startsWith('https://')
                ? getprofile?.userProfile?.profile_picture
                : burceMars
            }
            alt="User Image"
            className="img-data"
          />
        </MDBox>
      </Button>
      <Menu
        sx={{
          width: "200px",
          mt: 1,
          "& .MuiPaper-root ": { padding: 0, borderRadius: "14px" },
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button1",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className="hoverChangeColor"
          onClick={handleMyBusiness}
          sx={{
            padding: "16px 14px",
            borderRadius: "0",
            "& .MuiTypography-root": {
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "19.2px",
              color: "#292929",
              fontFamily: "urbanist",
            },
            "&:hover": {
              "& .MuiTypography-root": { color: "#ffffff" },
              backgroundColor: "#318BEC",
            },
          }}
        >
          <svg
            style={{ marginRight: "8px" }}
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3333 3.16654H14.1667V2.33318C14.1667 1.414 13.4192 0.666504 12.5 0.666504H7.5C6.58082 0.666504 5.83332 1.41396 5.83332 2.33318V3.1665H1.66668C0.747461 3.16654 1.73255e-08 3.914 1.73255e-08 4.83318V7.33318C1.73255e-08 8.2524 0.747461 8.99986 1.66668 8.99986H8.33336V8.58318C8.33332 8.52845 8.34408 8.47426 8.365 8.42369C8.38593 8.37312 8.41662 8.32717 8.45532 8.28847C8.49402 8.24977 8.53997 8.21908 8.59054 8.19815C8.64111 8.17722 8.69531 8.16647 8.75004 8.1665H11.25C11.3048 8.16647 11.359 8.17722 11.4095 8.19815C11.4601 8.21908 11.5061 8.24977 11.5448 8.28847C11.5835 8.32717 11.6141 8.37312 11.6351 8.42369C11.656 8.47426 11.6668 8.52845 11.6667 8.58318V8.99986H18.3334C19.2525 8.99986 20 8.2524 20 7.33318V4.83318C20 3.914 19.2525 3.16654 18.3333 3.16654ZM12.5 3.16654H7.5V2.33318H12.5V3.16654ZM19.7689 9.44947C19.6994 9.41503 19.6217 9.40064 19.5445 9.4079C19.4673 9.41515 19.3937 9.44378 19.3318 9.49057C19.0356 9.71479 18.6905 9.83319 18.3333 9.83319H11.6667V11.0832C11.6667 11.1379 11.656 11.1921 11.635 11.2427C11.6141 11.2933 11.5834 11.3392 11.5447 11.3779C11.506 11.4166 11.4601 11.4473 11.4095 11.4682C11.3589 11.4891 11.3047 11.4999 11.25 11.4999H8.75C8.69527 11.4999 8.64107 11.4891 8.5905 11.4682C8.53993 11.4473 8.49398 11.4166 8.45528 11.3779C8.41658 11.3392 8.38589 11.2933 8.36496 11.2427C8.34404 11.1921 8.33328 11.1379 8.33332 11.0832V9.83319H1.66668C1.30941 9.83319 0.964375 9.71479 0.668125 9.49057C0.606466 9.44342 0.532788 9.41456 0.455508 9.40729C0.378227 9.40003 0.300462 9.41464 0.231094 9.44947C0.161664 9.48401 0.103249 9.53723 0.0624228 9.60316C0.0215961 9.66908 -2.23413e-05 9.74509 1.73255e-08 9.82264L1.73255e-08 15.6665C1.73255e-08 16.5857 0.747461 17.3332 1.66668 17.3332H18.3334C19.2525 17.3332 20 16.5858 20 15.6665V9.82264C20 9.74509 19.9784 9.66907 19.9376 9.60315C19.8967 9.53722 19.8383 9.484 19.7689 9.44947Z"
              fill="#292929"
            />
          </svg>

          <Typography>My Businesses</Typography>
        </MenuItem>

        {/* {getprofile?.businesses?.length > 0 ? ( */}
        <MenuItem
          className="hoverChangeColor"
          onClick={handleProfile}
          sx={{
            padding: "16px 14px",
            borderRadius: "0",
            "& .MuiTypography-root": {
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "19.2px",
              color: "#292929",
              fontFamily: "urbanist",
            },
            "&:hover": {
              "& .MuiTypography-root": { color: "#ffffff" },
              backgroundColor: "#318BEC",
            },
          }}
        >
          {/* <img src={bellIcon} alt="icon" /> */}
          <svg
            style={{ marginRight: "8px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2 9.8164C12.6471 9.8164 14.6307 7.8427 14.6307 5.4082C14.6307 2.9737 12.648 1 10.2 1C7.752 1 5.7693 2.9737 5.7693 5.4082C5.7693 7.8427 7.752 9.8164 10.2 9.8164ZM12.5076 11.6533H7.8924C5.2158 11.6533 3 13.8574 3 16.5205C3 17.1631 3.2772 17.7148 3.8307 17.9902C4.6614 18.4492 6.5073 19 10.2 19C13.8927 19 15.7386 18.4492 16.5693 17.9902C16.8213 17.8361 17.0299 17.6202 17.1752 17.3631C17.3206 17.1059 17.3979 16.8159 17.4 16.5205C17.4 13.7656 15.1851 11.6533 12.5076 11.6533Z"
              fill="#292929"
            />
          </svg>

          <Typography>Profile</Typography>
        </MenuItem>

        <MenuItem
          className="hoverChangeColor"
          onClick={() => setLogout(true)}
          sx={{
            padding: "16px 14px",
            borderRadius: "0",
            "& .MuiTypography-root": {
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "19.2px",
              color: "#292929",
              fontFamily: "urbanist",
            },
            "&:hover": {
              "& .MuiTypography-root": { color: "#ffffff" },
              backgroundColor: "#318BEC",
            },
          }}
        >
          <svg
            style={{ marginRight: "8px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2313_2796)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.85657 16.8146V17.8114C9.85657 18.6107 9.45821 19.3007 8.76599 19.7003C8.43294 19.8926 8.06122 19.9998 7.67583 20C7.29009 20.0002 6.91849 19.8928 6.58521 19.7003L2.15333 17.1416C1.46103 16.7419 1.06274 16.052 1.06274 15.2526V2.18117C1.06274 0.978398 2.04118 0 3.24392 0H13.0045C14.2073 0 15.1858 0.97832 15.1858 2.18117V4.9366C15.1858 5.33187 14.8649 5.65281 14.4696 5.65281C14.0742 5.65281 13.7536 5.33191 13.7536 4.9366V2.18117C13.7536 1.76816 13.4175 1.43211 13.0045 1.43211H4.45169L8.76599 3.9234C9.45798 4.32301 9.85657 5.01285 9.85657 5.81195V15.3824H13.0045C13.4175 15.3824 13.7536 15.0465 13.7536 14.6335V12.218C13.7536 11.8225 14.074 11.5018 14.4696 11.5018C14.865 11.5018 15.1858 11.8225 15.1858 12.218V14.6335C15.1858 15.8363 14.2073 16.8146 13.0045 16.8146H9.85657ZM16.4926 9.12336L15.7039 9.91207C15.4243 10.1917 15.4243 10.6451 15.7039 10.9248C15.7704 10.9913 15.8493 11.0441 15.9362 11.08C16.0231 11.116 16.1162 11.1344 16.2103 11.1343C16.3044 11.1345 16.3975 11.1161 16.4845 11.0801C16.5714 11.0441 16.6504 10.9914 16.7168 10.9248L18.7277 8.91352C19.0073 8.63391 19.0074 8.18078 18.7277 7.90117L16.7168 5.89023C16.4372 5.61055 15.9838 5.61066 15.704 5.8902C15.4244 6.16957 15.4245 6.62312 15.704 6.90258L16.4926 7.69109H11.209C10.8134 7.69109 10.493 8.01172 10.493 8.40734C10.493 8.80297 10.8134 9.1234 11.209 9.1234H16.4926V9.12336Z"
                fill="#292929"
              />
            </g>
            <defs>
              <clipPath id="clip0_2313_2796">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <Typography>Logout</Typography>
        </MenuItem>

        <SubscribeMenuItem />
        <UpdateBillingInfoMenuItem />
      </Menu>
    </div>
  );
}
