import React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { updateOrganizationSubscription } from 'services/subscription';
import { SubscriptionStatus } from 'constant';

const SubscriptionPricing = ({ visible, setVisibility, onPriceSelectionCompleted }) => {
  const { status, quantity } = useSelector((state) => state.subscription);
  const profileData = useSelector((state) => state.auth.getprofile);
  const isRecreatingFirstBusiness = status === SubscriptionStatus.ACTIVE && quantity === 0

  const onClickSubscriptionHandler = async () => {

    const userId = profileData?.userProfile?.id;

    if (!userId) {
      return;
    }

    try {

      await updateOrganizationSubscription(userId);
      alert('Subscription Updated Successfully!');
      onPriceSelectionCompleted?.()

    } catch (err) { }
    finally {
      setVisibility(false)
    }
  }

  const getPricingMessage = () => {
    if(isRecreatingFirstBusiness) {
      return 'Recreating the first business this billing cycle incurs a charge!'
    }
    return status === 'trial' ?
    `You will upgrade from a free trial to a paid subscription and you will be charged for ${quantity + 1} businesses` :
    'Add a business subscription to add your new business!'
  }

  if (!visible) {
    return null;
  }

  return (
    <Box sx={{
      display: 'grid',
      placeItems: 'center',
      position: 'fixed',
      inset: 0,
      zIndex: 1000,
      background: '#fff'
    }}>
      <Stack direction="column">

        <Typography variant="h4" gutterBottom>
          {getPricingMessage()}
        </Typography>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginInline: '1rem',
        }}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ color: '#FFFFFF', width: '100%' }}
            onClick={onClickSubscriptionHandler}
          >
            {isRecreatingFirstBusiness ? 'Charge' : 'Subscribe'}
          </Button>
        </div>
      </Stack>
      <span style={{
        position: 'absolute',
        top: 30,
        right: 30,
        fontSize: '2rem',
        cursor: 'pointer'
      }} onClick={() => setVisibility(false)}>&times;</span>
    </Box>
  )
}

export default SubscriptionPricing
