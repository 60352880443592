import { combineReducers } from "@reduxjs/toolkit";
import authSlice  from "../reducers/auth";
import dashboardSlice from "../reducers/dashboard";
import chatSlice from "../reducers/chatbot";
import subscriptionSlice from "./subscription";

const rootReducer = combineReducers({
  auth: authSlice,
  dashboard: dashboardSlice,
  chat: chatSlice,
  subscription: subscriptionSlice,
});

export default rootReducer;
