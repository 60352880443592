import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { alpha } from "@mui/system";

function Loader() {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: 999,
        backdropFilter: "blur(3px)",
        backgroundColor: alpha("#000", 0.1), 
      }}
      open={true}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
}

export default Loader;
