// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { NavLink } from "react-router-dom";

//added components


// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";  
import logoHeader from "assets/images/color-logo.svg";

// Images
import SignInImage from "assets/images/sign-in-bg.jpg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUserAsync,
  resendVerifyEmailAsync,
} from "../../../features/actions/auth";
import Loader from "../../../assets/loader/loader";

import showHideIcon from "assets/images/small-logos/show-hide-icon.svg";
import dontShowIcon from "assets/images/small-logos/dont-show.svg";
import { toast } from "react-hot-toast";

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loginData, resendVerifyEmail } = useSelector(
    (state) => state.auth
  );
  const [showVerify, setShowVerify] = useState(false);
  const token = localStorage.getItem("token");
  const [values, setValues] = useState({
    username: "",
    password: "",

    // keepMeLogin: false,
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    // keepMeLogin: "",
  });
  const eyeButton = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = (formData) => {
    const errors = {};

    // Trim and validate values
    const trimmedUsername = formData?.username?.trim();
    const trimmedPassword = formData?.password?.trim();
    const checkEmail = /^\S+@\S+\.\S+$/; // Email without leading and trailing spaces

    // Validate email
    if (!trimmedUsername) {
      errors.username = "Email is required";
    } else if (!checkEmail.test(trimmedUsername)) {
      errors.username = "Invalid email format";
    }
    if (!trimmedPassword) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  useEffect(() => {
    if (loginData?.data?.status_code == 200 || token) {
      if (
        loginData?.data?.restaurant_details?.length == 0 &&
        loginData?.data?.onboarding_status?.[0]?.true_count == 0
      ) {
        navigate("/my-business");
      } else if (
        loginData?.data?.restaurant_details?.length > 0 &&
        loginData?.data?.onboarding_status?.[0]?.true_count != 0
      ) {
        const firstOnboardedRestaurant =
          loginData?.data?.restaurant_details.find(
            (restaurant) => restaurant.onboarding_status === "true"
          );

        if (firstOnboardedRestaurant) {
          localStorage.setItem("common_id", firstOnboardedRestaurant.common_id);
        }

        navigate("/dashboard");
      }
    }
  }, [loginData?.data, token]);


  useEffect(() => {
    if (
      loginData?.detail?.message ==
      "Please verify your account, check the registered email for verification link."
    ) {
      setShowVerify(true);
    }
  }, [loginData]);

  useEffect(() => {
    if (resendVerifyEmail?.status_code == 200) {
    }
  }, [resendVerifyEmail]);
  
  const inputHandler = (fieldName) => {
    return (e) => {
      setValues({ ...values, [fieldName]: e.target.value });
    };
  };



  const handleSubmit = async () => {
    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const formDataObject = new FormData();
      // Append trimmed and validated values to formDataObject
      Object.keys(values).forEach((key) => {
        formDataObject.append(key, values[key].trim());
      });

      await dispatch(loginUserAsync(formDataObject));
      try {
        setLoading(false);
      } catch (error) {
        setLoading(false);
      } finally {
        setSubmit(false);
      }
    } else {
      setErrors(errors);
      setSubmit(true); // This will trigger displaying errors if any
    }
  };

  const verifyEmailButton = () => {
    if (values.username) {
      let payload = {
        email: values.username,
      };
      dispatch(resendVerifyEmailAsync(payload));
    } else {
      toast.error("Email is required");
    }
  };

  return (
    <PageLayout>
      {loading && <Loader />}

      <Grid
        container
        sx={{ minHeight: "100vh", marginleft: "10px" }}
        justifyContent="space-between"
      >
        <Grid
          item
          md={6}
          px={5}
          sx={{
            minHeight: "100vh",
            alignSelf: "center",
            justifyContent: "center",
            p: 2,
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <NavLink to="/">
                <MDBox sx={{ ml: 4 }}>
                  <img src={logoHeader} alt="profile_image" />
                </MDBox>
              </NavLink>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={9}
              lg={8}
              xxl={7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "calc(100vh - 117px)",
                mx: "auto",
              }}
            >
              <MDBox
                px={5}
                pt={3}
                sx={{
                  "@media (min-width:768px)": {
                    paddingLeft: "2rem!important",
                    paddingRight: "2rem!important",
                  },
                  paddingLeft: "0!important",
                  paddingRight: "0!important",
                  width: "100%",
                }}
              >
                <MDBox ml={1} mt={-2} py={1} mb={1} textAlign="left">
                  {showVerify ? (
                    <>
                      <MDTypography color="error" fontWeight="medium" mt={1}>
                        Please verify your account, check the registered email
                        for verification link. if you don't have received any
                        verification email then click here to
                        <span
                          className="resend-verify"
                          fontWeight="medium"
                          onClick={verifyEmailButton}
                        >
                          {" "}
                          Resend verify email
                        </span>
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      ></MDTypography>
                    </>
                  ) : (
                    ""
                  )}
                 
                  <MDTypography
                    sx={{
                      fontSize: "36px",
                      fontWeight: 700,
                      lineHeight: "45.36px",
                      color: "#212121",
                    }}
                    mt={1}
                  >
                    Login
                  </MDTypography>

                  <MDTypography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20.16px",
                      color: "#838383",
                    }}
                    mt={1}
                  >
                    Enter your email and password to login!
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={3} px={1}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDTypography
                        variant="subtitle2"
                        fontWeight="medium"
                        mt={1}
                        sx={{ color: "#212121" }}
                      >
                        Email
                      </MDTypography>
                      <MDInput
                        type="text"
                        placeholder="Email"
                        value={values.username}
                        error={!!errors.username}
                        onChange={inputHandler("username")}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": { borderRadius: "45px" },
                        }}
                      />
                      {errors.username ? (
                        <MDTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "15px",
                            mt: 1,
                          }}
                          color="error"
                        >
                          {errors.username}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                    <MDBox mb={2} sx={{ position: "relative" }}>
                      <MDTypography
                        variant="subtitle2"
                        fontWeight="medium"
                        mt={1}
                        sx={{ color: "#212121" }}
                      >
                        Password
                      </MDTypography>
                      <MDInput
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={values.password}
                        error={!!errors.password}
                        onChange={inputHandler("password")}
                        onKeyPress={(event) =>
                          event.key === "Enter" ? handleSubmit() : null
                        }
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": { borderRadius: "45px" },
                        }}
                      />
                      {errors.password ? (
                        <MDTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "15px",
                            mt: 1,
                          }}
                          color="error"
                        >
                          {errors.password}
                        </MDTypography>
                      ) : null}
                      <MDBox
                        sx={{
                          position: "absolute",
                          right: "10px",
                          top: "32px",
                          cursor: "pointer",
                        }}
                        onClick={() => eyeButton()}
                      >
                        {showPassword ? (
                          <img src={showHideIcon} alt="icon" />
                        ) : (
                          <img src={dontShowIcon} alt="icon" />
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDBox display="flex" alignItems="center" ml={-1}>
                      </MDBox>

                      <MDTypography
                        component={Link}
                        to="/forget-password"
                        sx={{
                          color: "#1A73E8",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "17.64px",
                        }}
                      >
                        Forgot password?
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        color="info"
                        variant="gradient"
                        sx={{
                          fontFamily: "urbanist",
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "20.16px",
                          color: "#ffffff",
                          borderRadius: "45px",
                        }}
                        onClick={handleSubmit}
                        fullWidth
                      >
                        Login
                      </MDButton>
                    </MDBox>
                    <MDBox mt={3} mb={1} textAlign="center">
                      <MDTypography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "20.16px",
                          color: "#747474",
                        }}
                      >
                        Don't have an account?{" "}
                        <MDTypography
                          component={Link}
                          to="/sign-up"
                          // textGradient
                          sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20.16px",
                            color: "#1A73E8",
                          }}
                        >
                          Register
                        </MDTypography>
                      </MDTypography>
                      <MDTypography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20.16px",
                          color: "#747474",
                          marginTop: "20px",
                        }}
                      >
                        By continuing, you agree to Review Attendant's{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#1A73E8",
                          }}
                          onClick={() => navigate("/terms-conditions")}
                        >
                          {" "}
                          <b style={{}}>Terms & condition</b>
                        </span>{" "}
                        and{" "}
                        <span
                          style={{ cursor: "pointer", color: "#1A73E8" }}
                          onClick={() => navigate("/privacy-policy")}
                        >
                          <b>Privacy Policy.</b>
                        </span>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDTypography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#838383",
                  textAlign: "center",
                  pb: 0,
                  mb: 0,
                }}
              >
                © 2024. All Rights Reserved.
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={6}
          sx={{
            "@media (min-width:768px)": { display: "block" },
            display: "none",
          }}
        >
          <MDBox
            sx={{
              height: "100vh",
              borderRadius: "0 0 0 200px",
              overflow: "hidden",
              backgroundImage: `url(${SignInImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <MDBox sx={{ height: "100%" }}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <MDTypography
                  variant="h2"
                  fontWeight="regular"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                >
                  Welcome to
                </MDTypography>

                <MDTypography
                  variant="h1"
                  fontWeight="bold"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                >
                  Review Attendant
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Basic;
