import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Container, Link } from "@mui/material";
import logoHeader from "assets/images/color-logo.svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";


function Header() {

  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <MDBox className={
      colorChange
        ? "navbar colorChange"
        : "navbar"
    } sx={{ position: "fixed", top: "0px", zIndex: "5", width: "100%" }}>
      <MDBox sx={{ borderBottom: " 1px solid #318BEC1A", position: "relative" }}>


        <Container sx={{ "@media (min-width:1580px)": { maxWidth: "1600px!important" } }}>
          <MDBox
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "1rem", paddingBottom: "0.6rem", }}
          >
            <MDBox sx={{ width: "120px", "@media (min-width:768px)": { width: "200px" } }}>
              <NavLink to="/" >
                <img src={logoHeader} alt="logo" style={{ maxWidth: "100%", height: "auto" }} />
              </NavLink>
            </MDBox>
          </MDBox>
        </Container>
      </MDBox>
    </MDBox>

  );
}

export default Header;
