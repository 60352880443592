// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import styled from "@emotion/styled";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";
import Cookies from "js-cookie";

//custom components
import Header from "./components/header.js";
import MainSection from "./components/main-section.js";
import Footer from "./components/footer.js";
import BodySection from "./components/body-section.js";

//styled components
const MainWrapper = styled.div`
  backgroundcolor: #ffffff;
  overflow: hidden;
`;

function Home() {
  const location = useLocation();

  useEffect(() => {
    let token = location?.search?.replace('?code=', '')
    if (token) {
      const in60Seconds = new Date(new Date().getTime() + 60 * 1000);
      Cookies.set("fbAccessCode", token, { expires: in60Seconds });
      window.close();
      window.dispatchEvent(new Event('cookieChanged'));
    }
  }, []);
  return (
    <PageLayout>
      <MainWrapper>
        <MDBox sx={{ position: "relative" }}>
          <MDBox sx={{ position: "absolute", right:"-22%", bottom: "-38%" }}>
            {" "}
            <MDBox sx={{ width: "803px", height: "803px", borderRadius: "803px", opacity: '0.1', background: "#318BEC", filter: "blur(150px)" }}></MDBox>
          </MDBox>

          <MDBox>
            <Header></Header>

            <MainSection></MainSection>
          </MDBox>
        </MDBox>
        
        <BodySection></BodySection>

        <Footer></Footer>       
      </MainWrapper>
    </PageLayout>
  );
}

export default Home;
