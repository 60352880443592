import { config } from '../constant';

const { API_URL } = config.url;

async function fetchWithErrorHandling(url, options = {}) {
    try {
      const response = await fetch(url, options);
  
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status} ${response.statusText}`);
      }
  
      return response.json();
    } catch (error) {
      console.error("Error during fetch:", error);
      throw error;
    }
}

export const getSubscriptionStatus = async (user_id) => {
  try {
    return fetchWithErrorHandling(`${API_URL}subscription/status`, {
      method: "POST",
      body: JSON.stringify({ user_id }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

  } catch (error) {
    throw error;
  }
}

export const createOrganizationSubscription = async ({ userId, successUrl, cancelUrl }) => {
    return fetchWithErrorHandling(`${API_URL}subscription/organization/create`, {
        method: "POST",
        body: JSON.stringify({
            user_id: userId,
            success_url: successUrl,
            cancel_url: cancelUrl,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
};

export const updateOrganizationSubscription = async (userId) => {
    return fetchWithErrorHandling(`${API_URL}subscription/organization/update`, {
        method: "POST",
        body: JSON.stringify({
            user_id: userId,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
};

export const checkAllowedBusinessCreation = async (userId) => {
    return fetchWithErrorHandling(`${API_URL}subscription/organization/allow-creation`, {
        method: "POST",
        body: JSON.stringify({
            user_id: userId,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
};

export const cancelOrganizationSubscription = async (userId) => {
    return fetchWithErrorHandling(`${API_URL}subscription/organization/cancel`, {
        method: "POST",
        body: JSON.stringify({
            user_id: userId,
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
};

export const getUpdateBillingInfoUrl = async (userId, returnUrl) => {
  return fetchWithErrorHandling(`${API_URL}subscription/update-billing-info-url`, {
      method: "POST",
      body: JSON.stringify({
          user_id: userId,
          return_url: returnUrl,
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    });
};

export const restartOrganizationSubscription = async (userId) => {
  return fetchWithErrorHandling(`${API_URL}subscription/organization/restart`, {
      method: "POST",
      body: JSON.stringify({
          user_id: userId,
      }),
      headers: {
        'Content-Type': 'application/json'
      },
    });
};
