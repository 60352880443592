
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { NavLink } from "react-router-dom";


// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";
import logoHeader from "assets/images/color-logo.svg";

// Images
import {
  forgotPasswordAysnc,
} from "../../../features/actions/auth";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../assets/loader/loader";
import SignUpImage from "assets/images/sign-up-bg.jpg";

function ForgetPassword() {
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({
    email: "",
  });

  const validateForm = (formData) => {
    const newErrors = {};

    const trimmedEmail = formData?.email?.trim();
    const checkEmail = /^\S+@\S+\.\S+$/;

    // Validate email

    if (!trimmedEmail) {
      newErrors.email = "Email is required";
    } else if (!checkEmail.test(trimmedEmail)) {
      newErrors.email = "Invalid email format";
    }
    return newErrors;
  };

  const formDataObject = new URLSearchParams();
  Object.keys(values).forEach((key) => {
    formDataObject.append(key, values[key]);
  });

  useEffect(() => {
    if (submit) {
      if (Object.keys(errors).length === 0) {
        setLoading(true);
        dispatch(forgotPasswordAysnc(formDataObject))
          .then((response) => {
            setValues({ email: "" });
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
      setSubmit(false);
    }
  }, [submit]);

  const inputHandler = (fieldName) => {
    return (e) => {
      setValues({ ...values, [fieldName]: e.target.value });
    };
  };

  const handleSubmit = async () => {
    setErrors(validateForm(values));
    setSubmit(true);
  };

  return (
    <PageLayout>
      {loading && <Loader />}
      <Grid
        container
        sx={{ height: "100vh", marginleft: "10px" }}
        justifyContent="space-between"
      >
        <Grid
          item
          md={5}
          px={5}
          sx={{
            alignSelf: "center",
            justifyContent: "center",
            p: 2,
            width: "100%",
          }}
        >
          <NavLink to="/">
            <MDBox sx={{ ml: 1, pt: 0, mt: 0 }}>
              <img src={logoHeader} alt="profile_image" />
            </MDBox>
          </NavLink>

          <MDBox
            px={5}
            pt={5}
            mt={5}
            sx={{
              "@media (min-width:768px)": {
                paddingLeft: "2rem!important",
                paddingRight: "2rem!important",
              },
              paddingLeft: "0!important",
              paddingRight: "0!important",
              minHeight: "80vh",
            }}
          >
            <MDBox ml={1} mt={-2} py={1} mb={1} textAlign="left">
              <MDTypography variant="h2" fontWeight="medium" mt={1}>
                Enter E-mail
              </MDTypography>

              <MDTypography variant="button" color="text" mt={1}>
                Enter the e-mail address associated with your account
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={3} px={0}>
              <MDBox component="form" role="form">
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ alignSelf: "center", justifyContent: "center" }}
                  >
                    <MDBox p={1} mt={1}>
                      <MDInput
                        type="email"
                        placeholder="Email *"
                        value={values.email}
                        error={!!errors.email}
                        onChange={inputHandler("email")}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault(); // Prevent the default form submission behavior
                            handleSubmit();
                          }
                        }}
                        fullWidth
                        sx={{
                          "& .MuiInputBase-root": { borderRadius: "45px" },
                          "& .MuiOutlinedInput-root": { borderRadius: "45px" },
                        }}
                      />
                    </MDBox>
                    {errors.email ? (
                      <MDTypography px={1} variant="button" color="error">
                        {errors.email}
                      </MDTypography>
                    ) : null}
                  </Grid>
                </Grid>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit}
                    fullWidth
                    sx={{
                      borderRadius: "45px",
                    }}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid
          item
          md={6}
          sx={{
            "@media (min-width:768px)": { display: "block" },
            display: "none",
          }}
        >
          <MDBox
            sx={{
              minHeight: "100vh",
              height: "100%",
              borderRadius: "0 0 0 65px",
              overflow: "hidden",
              backgroundImage: `url(${SignUpImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <MDBox
              sx={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <MDTypography
                  variant="h2"
                  fontWeight="regular"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                  textAlign="center"
                >
                  Lost your key to unlocking app joy? Let's reset and reignite
                  your journey with just a tap!
                </MDTypography>

                <MDTypography
                  variant="h1"
                  fontWeight="bold"
                  sx={{ color: "#ffffff" }}
                  mt={1}
                  textAlign="center"
                >
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default ForgetPassword;
