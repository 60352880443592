// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//added components
//mui components

import { Container, Grid } from "@mui/material";


//images
import logoMain from "assets/images/logo-main.svg";
import worldIcon from "assets/images/small-logos/world-icon.svg";
import messageIcon from "assets/images/small-logos/message-icon.svg";
import callIcon from "assets/images/small-logos/call-icon.svg";
import { resendVerifyEmailAsync } from "features/actions/auth";
import { useDispatch } from "react-redux";


function EmailVerification() {
  const navigate= useNavigate()
  const dispatch =  useDispatch()
  const handleLogin = () =>{
    navigate("/sign-in")
  }
  const verifyEmailButton = () => {
    localStorage.getItem("email")
    if(localStorage.getItem("email"))
    {
      let payload = {
        email: localStorage.getItem("email")
      }
      dispatch(resendVerifyEmailAsync(payload))
    }

  }
  return (
    <>
      <MDBox
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <MDBox sx={{ height: "100%" }}>
          <MDTypography variant="h1" fontWeight="medium" color="#212121" mt={1}>
            Thank You
          </MDTypography>

          <div style={{width:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
            <MDBox sx={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
            <MDTypography   fontWeight="small" color="#212121"   mt={2} style={{width:"50%",fontSize:"24px"}}>
            Thank you for registering! We've sent a verification link to your email. Please check your inbox and click on the link to complete the registration process.
          </MDTypography>

          <MDTypography   fontWeight="small" color="#212121"   mt={2} style={{width:"50%",fontSize:"14px"}}>
            If you don't see the email, kindly check your spam or junk folder or click here to <MDTypography fontWeight="small" sx={{fontSize:"14px",color:"#318BEC"}} component={Link} onClick={verifyEmailButton}>resend verification</MDTypography> . Once verified, you'll gain access to our platform. Thank you for choosing us!
          </MDTypography>
            </MDBox>


          </div>

          <MDBox display="flex" justifyContent="center" alignItems="center" fullWidth mt={4}>
            <MDButton

              sx={{
                color: "#FFFFFF",
                border: "2px solid transparent",
                padding: "20px 50px 20px 50px",
                borderRadius: "188px",
                ml: 2,
                background: "linear-gradient(63.54deg, #49A3F1 -5.54%, #1A73E8 100%)",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
                textAlign: "left",
                fontFamily: "Urbanist",
              }}
              onClick={handleLogin}
            >
              Login
            </MDButton>

          </MDBox>
        </MDBox>
      </MDBox>


      <MDBox sx={{ backgroundColor: "#318BEC", py: 5 }}>
        <Container sx={{ '@media (min-width:1580px)': { maxWidth: '1600px!important' } }}>
          <Grid container>
            <Grid item xxl={4} lg={3} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <MDBox>
                  <img src={logoMain} alt="logo" />
                </MDBox>

              </MDBox>
            </Grid>

            <Grid item xxl={3} lg={4} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox
                    sx={{ mr: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <img src={worldIcon} alt="icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="button" sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "700" }}>
                      WWW.GRATITUDESYSTEMS.COM
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item lg={3} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox sx={{ mr: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={messageIcon} alt="icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="button" sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "700" }}>
                      INFO@GRATITUDESYSTEMS.COM
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item lg={2} md={6}>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <MDBox
                    sx={{ mr: 0, display: "flex", alignItems: "center", justifyContent: "center", pr: 0 }}
                  >
                    <img src={callIcon} alt="icon" />
                  </MDBox>

                  <MDBox>
                    <MDTypography variant="button" sx={{ color: "#ffffff", fontSize: "16px", lineHeight: "19px", fontWeight: "700" }}>
                      (202)240-7754
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Container>
      </MDBox>

    </>
  );
}

export default EmailVerification;
