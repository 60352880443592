import { createSlice } from "@reduxjs/toolkit";
import {
  allReviewAsync,
  autoGenerateReplyAsync,
  averageRatingAsync,
  deleteCachesAsync,
  drinkRatingAsync,
  foodRatingAsync,
  itemCountAsync,
  itemCountNegativeAsync,
  latestReviewAsync,
  markAsReadAsync,
  processInstructionAsync,
  ratingsCountsAsync,
  replayReviewsAsync,
  reviewDistributionAsync,
  reviewPerformanceAsync,
  scrapeStatusAsync,
  sentimentCountAsync,
  serviceRatingAsync,
  singleReviewAsync,
  topNegativeReviewAsync,
  topReviewAsync,
} from "../../actions/dashboard";


const initialState = {
  status: "idle",
  authLoading: false,
  AIChatLoading: false,
  counts: {},
  performance: {},
  distribution: {},
  allreviews: {},
  lastestReviews: {},
  itemCount: {},
  sentiment: {},
  commonId: {},
  markRead: {},
  singleReview: {},
  replyReview: {},
  topReview: {},
  service: null,
  topReviewNeg: {},
  itemLabel: "",
  positiveItemLabel: "",
  autoReply: {},
  deleteCache: {},
  scrapeStatus: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearState: (state) => {
      state.performance = {};
      state.distribution = {};
      state.allreviews = {};
      state.lastestReviews = {};
      state.itemCount = {};
      state.sentiment = {};
    },
    commonIdState: (state, action) => {
      state.commonId = action?.payload;
    },
    reviewState: (state) => {
      state.singleReview = {};
    },
    replyState: (state) => {
      state.replyReview = {};
    },
    clearModalState: (state) => {
      state.topReview = {};
    },
    clearModalState1: (state) => {
      state.topReviewNeg = {};
    },
    serviceAsync: (state, action) => {
      state.service = action.payload;
    },
    itemName: (state, action) => {
      state.itemLabel = action.payload;
    },
    positiveItemName: (state, action) => {
      state.positiveItemLabel = action.payload;
    },
    clearAIResponse: (state, action) => {
      state.autoReply = {};
    },
    resetScrapeStatus: (state) => {
      state.scrapeStatus = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ratingsCountsAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(ratingsCountsAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.counts = action.payload;
      })
      .addCase(ratingsCountsAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(foodRatingAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(foodRatingAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.averageFood = action.payload;
      })
      .addCase(foodRatingAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(drinkRatingAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(drinkRatingAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.drinkRating = action.payload;
      })
      .addCase(drinkRatingAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(serviceRatingAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(serviceRatingAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.serviceRating = action.payload;
      })
      .addCase(serviceRatingAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(averageRatingAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(averageRatingAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.averageRatingData = action.payload;
      })
      .addCase(averageRatingAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(sentimentCountAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(sentimentCountAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.sentiment = action.payload;
      })
      .addCase(sentimentCountAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(itemCountAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(itemCountAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.itemCount = action.payload;
      })
      .addCase(itemCountAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(itemCountNegativeAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(itemCountNegativeAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.negativeItem = action.payload;
      })
      .addCase(itemCountNegativeAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(latestReviewAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(latestReviewAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.lastestReviews = action.payload;
      })
      .addCase(latestReviewAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(allReviewAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(allReviewAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.allreviews = action.payload;
      })
      .addCase(allReviewAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(reviewDistributionAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(reviewDistributionAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.distribution = action.payload;
      })
      .addCase(reviewDistributionAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(reviewPerformanceAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(reviewPerformanceAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.performance = action.payload;
      })
      .addCase(reviewPerformanceAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(processInstructionAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(processInstructionAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.instruction = action.payload;
      })
      .addCase(processInstructionAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(markAsReadAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(markAsReadAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.markRead = action.payload;
      })
      .addCase(markAsReadAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(singleReviewAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(singleReviewAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.singleReview = action.payload;
      })
      .addCase(singleReviewAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(replayReviewsAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(replayReviewsAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.replyReview = action.payload;
      })
      .addCase(replayReviewsAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })
      .addCase(topReviewAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(topReviewAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.topReview = action.payload;
      })
      .addCase(topReviewAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(topNegativeReviewAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(topNegativeReviewAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.topReviewNeg = action.payload;
      })
      .addCase(topNegativeReviewAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(autoGenerateReplyAsync.pending, (state) => {
        state.AIChatLoading = true;
        state.status = "idle";
      })
      .addCase(autoGenerateReplyAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.AIChatLoading = false;
        state.autoReply = action.payload;
      })
      .addCase(autoGenerateReplyAsync.rejected, (state, action) => {
        state.status = "idle";
        state.AIChatLoading = false;
        state.error = "invalid credential";
      })

      .addCase(deleteCachesAsync.pending, (state) => {
        state.authLoading = true;
        state.status = "idle";
      })
      .addCase(deleteCachesAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.authLoading = false;
        state.autoReply = action.payload;
      })
      .addCase(deleteCachesAsync.rejected, (state, action) => {
        state.status = "idle";
        state.authLoading = false;
        state.error = "invalid credential";
      })

      .addCase(scrapeStatusAsync.pending, (state) => {
        state.acrapeStatusLoading = true;
        state.status = "idle";
      })
      .addCase(scrapeStatusAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.acrapeStatusLoading = false;
        state.scrapeStatus = action.payload;
      })
      .addCase(scrapeStatusAsync.rejected, (state, action) => {
        state.status = "idle";
        state.acrapeStatusLoading = false;
        state.error = "invalid credential";
      });
  },
});
export const {
  clearResetPasswordState,
  commonIdState,
  clearState,
  reviewState,
  replyState,
  clearModalState,
  clearModalState1,
  serviceAsync,
  itemName,
  positiveItemName,
  clearAIResponse,
  resetScrapeStatus,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
