import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayoutCustom2 from "examples/LayoutContainers/DashboardLayoutCustom2";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import google from "assets/images/icons/google.svg";
import yelp from "assets/images/icons/yelp.svg";
import facebook from "assets/images/icons/facebook.svg";
import twitter from "assets/images/icons/twitter.svg";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { scrapeStatusAsync } from "features/actions/dashboard";
import { getProfileAysnc } from "features/actions/auth";
import {
  syncingStatus,
  statusColors,
} from "examples/commonFolder/commonfolder";
import refreshIcon from "assets/images/icons/refresh.svg";
import { getSingleBusinessAysnc } from "features/actions/auth";
import { statusBackgroundColor } from "examples/commonFolder/commonfolder";
import { statusIcons } from "examples/commonFolder/commonfolder";

function ScrapePage() {
  const dispatch = useDispatch();
  let { getprofile, getSingleBusiness } = useSelector((state) => state.auth);
  const { scrapeStatus } = useSelector((state) => state.dashboard);

  const [businessId, setBusinessId] = useState();
  const [facebookStatus, setFacebookStatus] = useState("");
  const [yelpStatus, setYelpStatus] = useState("");
  const [twitterStatus, setTwitterStatus] = useState("");
  const [googleStatus, setGoogleStatus] = useState("");
  const [showloader, setShowloader] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [scrapeStatusData, setScrapeStatusData] = useState({});

  const [yelpUrl, setyelpUrl] = useState("");
  const [fbUrl, setFbUrl] = useState("");
  const [googleUrl, setGoogleUrl] = useState("");
  const [xUrl, setXUrl] = useState("");
  const getStatusDisplay = (status) => {
    return syncingStatus[status] || syncingStatus?.NOT_STARTED;
  };

  const getStatusColor = (status) => {
    return statusColors[status] || "#000000"; // Default color is black
  };

  const getStatusBackgroundColor = (status) => {
    return statusBackgroundColor[status] || "#ffffff";
  };
  const getStatusIcon = (status) => {
    const displayStatus = getStatusDisplay(status);
    return statusIcons[displayStatus];
  };

  const checkStatus = () => {
    setShowloader(true);
    dispatch(getProfileAysnc());
  };
  useEffect(() => {
    if (getprofile) {
      setShowloader(true);
      if (
        getprofile?.businesses?.length > 0 &&
        getprofile?.businesses?.[0]?.common_id
      ) {
        setBusinessId(getprofile?.businesses?.[0]?.common_id);
      }
    }
  }, [getprofile]);

  useEffect(() => {
    if (businessId) {
      dispatch(getSingleBusinessAysnc(businessId));
      dispatch(scrapeStatusAsync({ common_id: businessId }));
      setBusinessId("");
      setErrorStatus("")
    } else {
      setShowloader(false);
    }
  }, [businessId]);

  useEffect(() => {
    if (getSingleBusiness) {
      setyelpUrl(getSingleBusiness?.business_yelp_page);
      setFbUrl(getSingleBusiness?.business_fb_page);
      setGoogleUrl(getSingleBusiness?.business_google_page);
      setXUrl(getSingleBusiness?.business_twitter_page);
    }
  }, [getSingleBusiness]);

  useEffect(() => {
    if (scrapeStatus) {
      setScrapeStatusData(scrapeStatus);
    }
  }, [scrapeStatus]);

  useEffect(() => {
    setShowloader(false);
    if (
      Object.keys(scrapeStatusData)?.length > 0 &&
      scrapeStatusData?.status_code == 200
    ) {
      setFacebookStatus(scrapeStatusData?.data?.facebook_status);
      setYelpStatus(scrapeStatusData?.data?.yelp_status);
      setTwitterStatus(scrapeStatusData?.data?.twitter_status);
      setGoogleStatus(scrapeStatusData?.data?.google_status);
    } else if (scrapeStatusData?.status_code == 400) {
      setErrorStatus(scrapeStatusData?.message);
    }
  }, [scrapeStatusData]);

  return (
    <>
      <MDBox sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
        <DashboardLayoutCustom2>
          <DashboardNavbar />
          <MDBox mb={4} />

          <MDBox
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "45px",
              p: 2,
              "@media(max-width:786px)": { mx: 2 },
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                "@media(max-width:900px)": { overflowX: "auto" },
              }}
            >
              <Paper
                elevation={0}
                sx={{ height: "100%", p: 1, borderRadius: "20px" }}
              >
                <MDBox
                  sx={{
                    minHeight: "calc(100vh - 140px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <MDBox sx={{ height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <MDTypography
                          fontWeight="small"
                          color="#212121"
                          mt={2}
                          style={{
                            width: "50%",
                            fontSize: "30px",
                            color: "#318BEC",
                            fontWeight: "700",
                          }}
                        >
                          Welcome aboard! Your business has been successfully
                          added
                        </MDTypography>

                        <MDTypography
                          fontWeight="small"
                          color="#212121"
                          mt={2}
                          mb={3}
                          style={{ width: "50%", fontSize: "24px" }}
                        >
                          We're in the process of synchronizing your business
                          reviews from Yelp, Facebook, Google, and X.com. Once
                          completed, you'll have a comprehensive overview of
                          feedback from these platforms, Empowering you to
                          better understand and engage with your customers. You will
                          receive a confirmation email once the synchronization
                          is complete.
                        </MDTypography>

                        <Button
                          onClick={() => checkStatus()}
                          sx={{
                            color: "#318BEC",
                            fontFamily: "urbanist",
                            fontSize: "15px",
                            border: "1px solid #318BEC",
                            "&:hover": {
                              boxShadow:
                                "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2)",
                            },
                            borderRadius: "100px",
                          }}
                        >
                          Refresh
                          <img
                            src={refreshIcon}
                            alt="icon"
                            style={{ marginLeft: "5px" }}
                          />
                        </Button>
                        {showloader ? (
                          <MDBox sx={{ position: "relative", pt: 4 }}>
                            <MDBox
                              sx={{
                                position: "absolute",
                                top: "105px",
                                left: "35%",
                              }}
                            ></MDBox>
                            <Typography
                              id="modal-modal-title"
                              sx={{
                                fontFamily: "urbanist",
                                fontSize: "24px",
                                fontWeight: 700,
                                lineHeight: "40.32px",
                                color: "#353535",
                                px: 4,
                              }}
                            >
                              <CircularProgress color="inherit" />
                              <br />
                              Please hold
                              <br />
                            </Typography>
                          </MDBox>
                        ) : errorStatus ? (
                          <MDBox>
                            <p>{errorStatus}</p>
                            <p>Try Again</p>
                          </MDBox>
                        ) : (
                          <>
                            <MDBox
                              sx={{
                                px: 2,
                                pt: 2,
                                borderRadius: "24px",
                              }}
                            >
                              {googleUrl && googleUrl != "N/A" && (
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    gap: "120px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #DBDBDB",
                                    py: 3,
                                    px: 1,
                                  }}
                                >
                                  <img src={google} alt="status icon" />
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      background: `${getStatusBackgroundColor(
                                        googleStatus
                                      )}`,
                                      borderRadius: "80px",
                                      padding: "9px 20px 9px 20px",
                                      border: `1px solid ${getStatusColor(
                                        googleStatus
                                      )}`,
                                      minWidth: "138px",
                                      justifyContent: "center",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <img
                                      src={getStatusIcon(googleStatus)}
                                      alt="status icon"
                                    />

                                    <p
                                      style={{
                                        color: getStatusColor(googleStatus),
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {getStatusDisplay(googleStatus)}
                                    </p>
                                  </div>
                                </MDBox>
                              )}

                              {yelpUrl && yelpUrl != "N/A" && (
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    gap: "120px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #DBDBDB",
                                    py: 3,
                                    px: 1,
                                  }}
                                >
                                  <img src={yelp} alt="status icon" />

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      background: `${getStatusBackgroundColor(
                                        yelpStatus
                                      )}`,
                                      borderRadius: "80px",
                                      padding: "9px 20px 9px 20px",
                                      border: `1px solid ${getStatusColor(
                                        yelpStatus
                                      )}`,
                                      minWidth: "138px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={getStatusIcon(yelpStatus)}
                                      alt="status icon"
                                    />
                                    <p
                                      style={{
                                        color: getStatusColor(yelpStatus),
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {getStatusDisplay(yelpStatus)}
                                    </p>
                                  </div>
                                </MDBox>
                              )}
                              {fbUrl && fbUrl != "N/A" && (
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    gap: "120px",
                                    borderBottom: "1px solid #DBDBDB",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    py: 3,
                                    px: 1,
                                  }}
                                >
                                  <img src={facebook} alt="status icon" />

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      background: `${getStatusBackgroundColor(
                                        facebookStatus
                                      )}`,
                                      borderRadius: "80px",
                                      padding: "9px 20px 9px 20px",
                                      border: `1px solid ${getStatusColor(
                                        facebookStatus
                                      )}`,
                                      minWidth: "138px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={getStatusIcon(facebookStatus)}
                                      alt="status icon"
                                    />
                                    <p
                                      style={{
                                        color: getStatusColor(facebookStatus),
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {getStatusDisplay(facebookStatus)}
                                    </p>
                                  </div>
                                </MDBox>
                              )}
                              {xUrl && xUrl != "N/A" && (
                                <MDBox
                                  sx={{
                                    display: "flex",
                                    gap: "120px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    pt: 3,
                                    px: 1,
                                  }}
                                >
                                  <img src={twitter} alt="status icon" />

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      background: `${getStatusBackgroundColor(
                                        twitterStatus
                                      )}`,
                                      borderRadius: "80px",
                                      padding: "9px 20px 9px 20px",
                                      border: `1px solid ${getStatusColor(
                                        twitterStatus
                                      )}`,
                                      minWidth: "138px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={getStatusIcon(twitterStatus)}
                                      alt="status icon"
                                    />
                                    <p
                                      style={{
                                        color: getStatusColor(twitterStatus),
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {getStatusDisplay(twitterStatus)}
                                    </p>
                                  </div>
                                </MDBox>
                              )}
                            </MDBox>
                          </>
                        )}
                      </MDBox>
                    </div>
                  </MDBox>
                </MDBox>
              </Paper>
            </MDBox>
          </MDBox>
        </DashboardLayoutCustom2>
      </MDBox>
    </>
  );
}

export default ScrapePage;
