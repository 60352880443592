import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import DashboardLayoutCustom2 from "examples/LayoutContainers/DashboardLayoutCustom2";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState, useLayoutEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAysnc } from "features/actions/auth";
import backDashboard from "assets/images/backDashboard.svg";
import { scrapeUrlAsync } from "features/actions/auth";
import Loader from "assets/loader/loader";
import toast from "react-hot-toast";
import { resetGetScrapeUrl } from "features/reducers/auth";
import { scrapeAllAsync } from "features/actions/auth";
import { commonIdState } from "features/reducers/dashboard";

function ClientDetail() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    py: 5,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getprofile, getScrapeUrl, getScrapeData } = useSelector(
    (state) => state.auth
  );

  const [predictions, setPredictions] = useState([]);
  const [placePredictions, setPlacePredictions] = useState([]);
  const [urlFieldVisible, setUrlFieldVisible] = useState(false);
  const [restroOptionsPopUp, setRestroOptionsPopUp] = useState(false);
  const [restroOptions, setRestroOptions] = useState([]);
  const [searchForAddress, setSearchForAddress] = useState(false);
  const [changeCommonId, setChangeCommonId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [modalType, setModalType] = useState(null);
  const [restroAddressOptions, setRestroAddressOptions] = useState([]);
  const [restroAddressOptionsPopUp, setRestroAddressOptionsPopUp] =
    useState(false);
  const [values, setValues] = useState([
    {
      restaurant_name: "",
      business_address: "",
      yelp_link: "",
      facebook_link: "",
      google_link: "",
      twitter_link: "",
    },
  ]);

  const [errors, setErrors] = useState([
    {
      restaurant_name: "",
      business_address: "",
      yelp_link: "",
      facebook_link: "",
      google_link: "",
      twitter_link: "",
    },
  ]);

  useLayoutEffect(() => {
    handleReset();
  }, []);

  const handleReset = () => {
    dispatch(resetGetScrapeUrl());
    setUrlFieldVisible(false);
    setValues([
      {
        id: "",
        restaurant_name: "",
        business_address: "",
        yelp_link: "",
        facebook_link: "",
        google_link: "",
        twitter_link: "",
      },
    ]);
    dispatch(getProfileAysnc());
  };

  // Form validation
  const validateSearchForm = () => {
    let isValid = true;
    const newErrors = [...errors];

    values.forEach((value, index) => {
      const newError = { ...newErrors[index] };

      if (!value.restaurant_name.trim()) {
        newError.restaurant_name = "Business name is required";
        isValid = false;
      } else {
        newError.restaurant_name = "";
      }

      if (!value.business_address.trim()) {
        newError.business_address = "Business address is required";
        isValid = false;
      } else {
        newError.business_address = "";
      }

      newErrors[index] = newError;
    });
    setErrors(newErrors);
    return isValid;
  };
  // ------------------------------------------------------------------------- //

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD_vGGVm-ATDIapeInR3hPNzRHAMkKnqfw&libraries=places&callback=initAutocomplete`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.initAutocomplete = () => {
      const input = document.getElementById("address-input");
      if (input) {
        const autocomplete = new window.google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", () =>
          handlePlaceChanged(autocomplete)
        );
      }
    };
    window.initAutocomplete();
    return () => {
      delete window.initAutocomplete;
    };
  }, [values]);

  const handlePlaceChanged = (autocomplete) => {
    const place = autocomplete.getPlace();
    const selectedText = place?.formatted_address;
    const index = 0;
    handleRestroAddress(selectedText, index);
  };

  // auto search place based on input

  const searchRestro = (inputValue) => {
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: inputValue }, (predictions) => {
      if (predictions) {
        setPlacePredictions(predictions);
      }
    });
  };

  useEffect(() => {
    let placeOptions = [];
    if (placePredictions?.length > 0) {
      placePredictions?.map((ele) => {
        placeOptions.push(ele?.structured_formatting?.main_text);
      });
      setRestroOptions(placeOptions);
      setRestroOptionsPopUp(true);
    } else {
      setRestroOptions([]);
    }
  }, [placePredictions]);

  // --------------------------------------------------------------------------------------- //

  // auto search address based on restaurant name provided

  const searchRestroAddress = (inputValue) => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps API is not loaded.");
      return;
    }
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request = {
      query: inputValue,
      region: "US",
      types: ["restaurant", "bar"],
    };
    service.textSearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const formattedAddresses = results?.map(
          (result) => result?.formatted_address
        );
        setPredictions(formattedAddresses);
      }
    });
  };

  useEffect(() => {
    let restroAddress = [];
    if (predictions?.length > 0) {
      predictions?.map((ele) => {
        restroAddress.push(ele);
      });
      setRestroAddressOptions(restroAddress);
      setRestroAddressOptionsPopUp(true);
    } else {
      setRestroAddressOptions([]);
    }
  }, [predictions]);

  // ------------------------------------------------------------------------------------------------ //

  const inputHandler = (fieldName, index, e) => {
    const updatedValues = [...values];
    updatedValues[index] = {
      ...values[index],
      [fieldName]: e.target.value,
    };

    const newErrors = [...errors];
    const newError = { ...newErrors[index] };
    if (fieldName === "restaurant_name") {
      if (!e.target.value.trim()) {
        newError.restaurant_name = "Business name is required";
        setRestroAddressOptionsPopUp(false);
        setRestroOptionsPopUp(false);
        setUrlFieldVisible(false);
        dispatch(resetGetScrapeUrl());
      } else {
        newError.restaurant_name = "";
        searchRestro(values?.[0]?.restaurant_name);
        setUrlFieldVisible(false);
        dispatch(resetGetScrapeUrl());
        setSearchForAddress(false);
      }

      // Reset specific fields
      updatedValues[index] = {
        ...updatedValues[index],
        business_address: "",
        yelp_link: "",
        facebook_link: "",
        google_link: "",
        twitter_link: "",
      };
    }

    if (fieldName === "business_address") {
      if (!e.target.value.trim()) {
        newError.business_address = "Business address is required";
        setRestroAddressOptionsPopUp(false);
        setUrlFieldVisible(false);
        dispatch(resetGetScrapeUrl());
        setRestroOptionsPopUp(false);
      } else {
        newError.business_address = "";
        setUrlFieldVisible(false);
        dispatch(resetGetScrapeUrl());
        setRestroAddressOptionsPopUp(false);
        setRestroOptionsPopUp(false);
      }

      updatedValues[index] = {
        ...updatedValues[index],
        yelp_link: "",
        facebook_link: "",
        google_link: "",
        twitter_link: "",
      };
    }

    newErrors[index] = newError;
    setErrors(newErrors);
    setValues(updatedValues);
  };

  useEffect(() => {
    if (getprofile?.userProfile?.id) {
      setUserId(getprofile?.userProfile?.id);
      setUserEmail(getprofile?.userProfile?.email);
      setUserFirstName(getprofile?.userProfile?.first_name);
    }
  }, [getprofile?.userProfile?.id]);

  const handleRestro = (name, index) => {
    setRestroOptionsPopUp(false);
    const updatedValues = values.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          restaurant_name: name,
        };
      } else {
        return item;
      }
    });
    setValues(updatedValues);
    setRestroAddressOptionsPopUp(false);
    setSearchForAddress(true);
  };

  useEffect(() => {
    if (searchForAddress) {
      searchRestroAddress(values?.[0]?.restaurant_name);
    }
  }, [searchForAddress]);

  const handleRestroAddress = (name, index) => {
    const restaurantName = values[index]?.restaurant_name;
    const concatenatedName = restaurantName
      ? `${restaurantName}, ${name}`
      : name;

    const updatedValues = values.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          business_address: name,
          address: concatenatedName,
        };
      } else {
        return item;
      }
    });
    setValues(updatedValues);
    setRestroAddressOptionsPopUp(false);
  };

  const handlefetchUrl = () => {
    const isValid = validateSearchForm();
    if (isValid) {
      setRestroAddressOptionsPopUp(false);
      setRestroOptionsPopUp(false);
      handleGetSourceUrl(values);
    }
  };

  // handle fetch business URLs
  const handleGetSourceUrl = async (values) => {
    if (values?.length > 0) {
      setModalType("loader");
      try {
        await dispatch(
          scrapeUrlAsync({
            title_name: values[0]?.restaurant_name,
            address:
              `${values[0]?.restaurant_name}, ${values[0]?.business_address}`?.toLowerCase(),
          })
        );
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("no any url found.");
        setModalType(null);
      } finally {
        setUrlFieldVisible(true);
        setModalType(null);
      }
    }
  };

  // handle submit business registration
  const handleSubmit = () => {
    const formDataObject = {
      id: userId,
      rest_address: values[0]?.business_address?.toLowerCase(),
      email: userEmail,
      first_name: userFirstName,
      address: values[0]?.address
        ? values[0]?.address?.toLowerCase()
        : `${values[0]?.restaurant_name?.toLowerCase()}, ${values[0]?.business_address?.toLowerCase()}`,
      title_name: values[0]?.restaurant_name
        ?.replace(/\s+/g, "")
        ?.toLowerCase(),
    };

    if (values[0]?.yelp_link && values[0]?.yelp_link !== "N/A") {
      formDataObject.yelp_url = values[0]?.yelp_link;
    }
    if (values[0]?.twitter_link && values[0]?.twitter_link !== "N/A") {
      formDataObject.twitter_url = values[0]?.twitter_link;
    }
    if (values[0]?.facebook_link && values[0]?.facebook_link !== "N/A") {
      formDataObject.facebook_url = values[0]?.facebook_link;
    }
    if (values[0]?.google_link && values[0]?.google_link !== "N/A") {
      formDataObject.google_url = values[0]?.google_link;
    }
    setChangeCommonId(true);
    dispatch(commonIdState("0"));
    setLoading(true);
    dispatch(scrapeAllAsync(formDataObject));
    setModalType(null);
  };

  useEffect(() => {
    if (
      Object.keys(getScrapeUrl)?.length != 0 &&
      getScrapeUrl?.status_code == 200 &&
      urlFieldVisible
    ) {
      toast.success("Please verify the autopopulate Urls.");
      setValues([{ ...values[0], ...getScrapeUrl?.data }]);
    } else if (getScrapeUrl?.status_code == 400) {
      toast.error("Could not find URls. Try again later");
    }
  }, [getScrapeUrl?.data, urlFieldVisible]);

  useEffect(() => {
    if (changeCommonId && getScrapeData?.status_code == 202) {
      const businessId = 0;
      localStorage.setItem("common_id", businessId?.toString());
      navigate("/scrape-page");
      toast.success("Reviews syncing initiated.");
      setUrlFieldVisible(false);
      dispatch(resetGetScrapeUrl());
      setLoading(false);
    } else if (getScrapeData?.status_code == 400) {
      toast.error("This business is already added.");
    }else if(getScrapeData?.status_code == 500){
      toast.error("Couldn't syncing business.");
    }
  }, [changeCommonId, getScrapeData]);

  return (
    <MDBox sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
      <DashboardLayoutCustom2>
        {loading && <Loader />}
        <DashboardNavbar />
        {urlFieldVisible && (
          <MDTypography my={2} fontWeight="regular">
            <div className="business-note">
              <b>
                {" "}
                Please make sure all the links below are correct. If any of the
                links are not correct, Kindly replace them with the correct one.
              </b>
            </div>
          </MDTypography>
        )}

        <MDBox sx={{ px: "1rem" }}>
          {values?.map((value, index) => {
            return (
              <MDBox
                sx={{
                  backgroundColor: "#ffffff",
                  py: 1,
                  borderRadius: "20px",
                  height: "600px",
                }}
                key={index}
              >
                <MDBox sx={{ borderBottom: "1px solid #E1EAFF", px: 2, py: 1 }}>
                  <MDTypography
                    mt={2}
                    mb={2}
                    sx={{
                      color: "#292929",
                      fontFamily: "Urbanist",
                      fontSize: "24px",
                      fontWeight: 700,
                      lineHeight: "28.8px",
                    }}
                  >
                    Provide details for your business
                  </MDTypography>
                </MDBox>

                <MDBox sx={{ px: 2, py: 2 }}>
                  <Grid container columnSpacing={5} rowSpacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox>
                        <MDTypography
                          variant="subtitle2"
                          fontWeight="regular"
                          sx={{ color: "#212121" }}
                          mt={1}
                        >
                          Business Name
                          <MDBox sx={{ color: "red" }} component="span">
                            *
                          </MDBox>
                        </MDTypography>
                        <MDInput
                          type="text"
                          placeholder="Business name"
                          value={value?.restaurant_name}
                          error={!!errors[index]?.restaurant_name}
                          onChange={(e) =>
                            inputHandler("restaurant_name", index, e)
                          }
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              borderRadius: "45px",
                              paddingRight: "41px",
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "45px",
                            },
                          }}
                        />
                      </MDBox>
                      {errors[index]?.restaurant_name ? (
                        <MDTypography px={1} variant="button" color="error">
                          {errors[index]?.restaurant_name}
                        </MDTypography>
                      ) : null}

                      {/* first */}
                      {restroOptionsPopUp && (
                        <div
                          style={{
                            height: "250px",
                            overflowY: "auto",
                            position: "absolute",
                            left: "55px",
                            background: "#fff",
                            zIndex: "999",
                            padding: "10px",
                            marginTop: "10px",
                            border: "1px solid #f2f2f2",
                            width: "29%",
                          }}
                          className="address"
                        >
                          {restroOptions?.length > 0 ? (
                            restroOptions.map((restroOption) => (
                              <li
                                key={restroOption}
                                style={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  marginTop: "5px",
                                  borderBottom: "1px solid #ccc", // Add a horizontal line at the bottom
                                  paddingBottom: "5px",
                                }}
                                onClick={() =>
                                  handleRestro(restroOption, index)
                                }
                              >
                                {restroOption}
                              </li>
                            ))
                          ) : (
                            <p
                              style={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              No Address found
                            </p>
                          )}
                        </div>
                      )}

                      {/* second  */}
                      {restroAddressOptionsPopUp && (
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "20px",
                            marginLeft: "10px",
                            color: "#000",
                          }}
                        >
                          Based on our database search, we've compiled a list of
                          businesses associated with the entered business name.
                        </p>
                      )}

                      {restroAddressOptionsPopUp && (
                        <div
                          style={{
                            height: "250px",
                            overflowY: "auto",
                            position: "absolute",
                            left: "55px",
                            background: "#fff",
                            zIndex: "999",
                            padding: "10px",
                            marginTop: "10px",
                            border: "1px solid #f2f2f2",
                            width: "29%",
                          }}
                          className="address"
                        >
                          {restroAddressOptions?.length > 0 ? (
                            restroAddressOptions.map((addressOption) => (
                              <li
                                key={addressOption}
                                style={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                  marginTop: "5px",
                                  borderBottom: "1px solid #ccc",
                                  paddingBottom: "5px",
                                }}
                                onClick={() =>
                                  handleRestroAddress(addressOption, index)
                                }
                              >
                                {addressOption}
                              </li>
                            ))
                          ) : (
                            <p
                              style={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              No Address found
                            </p>
                          )}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <MDBox sx={{ position: "relative" }}>
                        <MDTypography
                          variant="subtitle2"
                          fontWeight="regular"
                          sx={{ color: "#212121" }}
                          mt={1}
                        >
                          Business Address
                          <MDBox sx={{ color: "red" }} component="span">
                            *
                          </MDBox>
                        </MDTypography>
                        <MDInput
                          type="text"
                          placeholder="Business address"
                          id="address-input"
                          value={value?.business_address}
                          error={!!errors[index]?.business_address}
                          onChange={(e) =>
                            inputHandler("business_address", index, e)
                          }
                          onFocus={() => {
                            setRestroOptionsPopUp(false);
                            setRestroAddressOptionsPopUp(false);
                          }}
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              borderRadius: "45px",
                              paddingRight: "41px",
                            },
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "45px",
                            },
                          }}
                        />
                        {errors[index]?.business_address ? (
                          <MDTypography px={1} variant="button" color="error">
                            {errors[index]?.business_address}
                          </MDTypography>
                        ) : null}
                      </MDBox>
                    </Grid>
                    {urlFieldVisible && (
                      <>
                        <Grid item xs={12} md={6} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="subtitle2"
                              fontWeight="regular"
                              sx={{ color: "#212121" }}
                              mt={1}
                            >
                              Google 
                              {value?.google_link.includes("https://") && <a href={value?.google_link} target="_blank"> (Click here to verify)</a>}
                            </MDTypography>
                            <MDInput
                              type="text"
                              placeholder="https//www.google.com"
                              value={value?.google_link}
                              onChange={(e) =>
                                inputHandler("google_link", index, e)
                              }
                              fullWidth
                              sx={{
                                "& .MuiInputBase-root": {
                                  borderRadius: "45px",
                                  paddingRight: "41px",
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="subtitle2"
                              fontWeight="regular"
                              mt={1}
                              sx={{ color: "#212121" }}
                            >
                              Yelp
                              {value?.yelp_link.includes("https://") && <a href={value?.yelp_link} target="_blank"> (Click here to verify)</a>}
                            </MDTypography>
                            <MDInput
                              type="email"
                              placeholder="https//www.yelp.com"
                              value={value?.yelp_link}
                              onChange={(e) =>
                                inputHandler("yelp_link", index, e)
                              }
                              fullWidth
                              sx={{
                                "& .MuiInputBase-root": {
                                  borderRadius: "45px",
                                  paddingRight: "41px",
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="subtitle2"
                              fontWeight="regular"
                              sx={{ color: "#212121" }}
                              mt={1}
                            >
                              Facebook
                              {value?.facebook_link.includes("https://") && <a href={value?.facebook_link} target="_blank"> (Click here to verify)</a>}
                            </MDTypography>
                            <MDInput
                              type="text"
                              placeholder="https//www.facebook.com"
                              value={value?.facebook_link}
                              onChange={(e) =>
                                inputHandler("facebook_link", index, e)
                              }
                              fullWidth
                              sx={{
                                "& .MuiInputBase-root": {
                                  borderRadius: "45px",
                                  paddingRight: "41px",
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>

                        {/* <Grid item xs={12} md={6} lg={4}>
                          <MDBox>
                            <MDTypography
                              variant="subtitle2"
                              fontWeight="regular"
                              sx={{ color: "#212121" }}
                              mt={1}
                            >
                              Twitter
                            </MDTypography>
                            <MDInput
                              type="text"
                              placeholder="https//www.twitter.com"
                              value={value?.twitter_link}
                              onChange={(e) =>
                                inputHandler("twitter_link", index, e)
                              }
                              fullWidth
                              sx={{
                                "& .MuiInputBase-root": {
                                  borderRadius: "45px",
                                  paddingRight: "41px",
                                },
                              }}
                            />
                          </MDBox>
                        </Grid> */}
                      </>
                    )}
                  </Grid>
                  <>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: "70px",
                      }}
                    >
                      <MDButton
                        sx={{
                          fontFamily: "urbanist",
                          padding: 0,
                          color: "#318BEC",
                          "@media(max-width:786px)": { pt: 4, pb: 2 },
                        }}
                        component={Link}
                        className="backD"
                        to="/my-business"
                      >
                        <img
                          src={backDashboard}
                          style={{ marginRight: "10px", borderRadius: "20px" }}
                        />
                        Back
                      </MDButton>
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "end",
                          gap: "15px",
                          flexDirection: "row",
                          "@media(max-width:786px)": {
                            flexDirection: "column-reverse",
                            alignItems: "start",
                          },
                          pt: 5,
                          pb: 2,
                        }}
                      >
                        <MDButton
                          variant="gradient"
                          color="info"
                          sx={{
                            paddingLeft: "5rem",
                            paddingRight: "5rem",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            borderRadius: "45px",
                          }}
                          onClick={
                            urlFieldVisible ? handleReset : handlefetchUrl
                          }
                        >
                          {urlFieldVisible ? "Reset" : "Submit"}
                        </MDButton>

                        {urlFieldVisible && (
                          <MDButton
                            variant="gradient"
                            color="info"
                            sx={{
                              paddingLeft: "5rem",
                              paddingRight: "5rem",
                              paddingTop: "1rem",
                              paddingBottom: "1rem",
                              borderRadius: "45px",
                              marginLeft: "1rem", // Adjust as needed for spacing
                            }}
                            onClick={() => setModalType("confirmation")}
                          >
                            Submit
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </>
                </MDBox>
              </MDBox>
            );
          })}
        </MDBox>

        {/* modal */}
        <Modal
          open={modalType === "loader"}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox sx={style}>
            <MDBox sx={{ position: "relative", pt: 4 }}>
              <MDBox
                sx={{
                  position: "absolute",
                  top: "105px",
                  left: "35%",
                }}
              ></MDBox>
              <Typography
                id="modal-modal-title"
                sx={{
                  fontFamily: "urbanist",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "40.32px",
                  color: "#353535",
                  px: 4,
                }}
              >
                <CircularProgress color="inherit" />
                <br />
                Please wait
                <br />
              </Typography>
              <Typography>
                <p
                  sx={{
                    fontFamily: "urbanist",
                    fontSize: "12px",
                    fontWeight: 200,
                    lineHeight: "40.32px",
                    marginTop: "14px",
                    color: "#818181",
                  }}
                >
                  while we fetch your business’s social accounts.
                </p>
              </Typography>
            </MDBox>
          </MDBox>
        </Modal>

        <Modal
          open={modalType === "confirmation"}
          onClose={() => setModalType(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox sx={style}>
            <MDBox sx={{ position: "relative", pt: "15px" }}>
              <MDBox
                sx={{
                  position: "absolute",
                  top: "-15px",
                  left: "38%",
                }}
              ></MDBox>

              <MDBox>
                <h2
                  id="modal-modal-title"
                  style={{
                    fontFamily: "urbanist",
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#333",
                    textAlign: "center",
                  }}
                >
                  Confirm Links
                </h2>
                <p
                  id="modal-modal-description"
                  style={{
                    fontFamily: "urbanist",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "30px",
                    marginTop: "14px",
                    color: "#818181",
                    textAlign: "center",
                  }}
                >
                  Please confirm all the social media links are correct.
                </p>
              </MDBox>

              <MDButton
                variant="gradient"
                color="info"
                sx={{
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "45px",
                  marginTop: "30px",
                }}
                onClick={() => setModalType(null)}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                sx={{
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "45px",
                  marginTop: "30px",
                  marginLeft: "16px",
                }}
                onClick={handleSubmit}
              >
                Confirm
              </MDButton>
            </MDBox>
          </MDBox>
        </Modal>
      </DashboardLayoutCustom2>
    </MDBox>
  );
}

export default ClientDetail;
