import React from "react";
import { Drawer, Grid, TextField, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import GoogleAuth from "components/authModal";
import blueSparkles from "assets/images/icons/blue-sparkles.svg";
import headingblueSparkles from "assets/images/icons/heading-blue-sparkles.svg";
import aiProfile from "assets/images/icons/ai-pp.svg";
import drawerCross from "assets/images/icons/drawer-cross.svg";
import burceMars from "assets/images/dummy.png";

const ReviewDrawer = ({
  openDrawer,
  toggleDrawer,
  checkComment,
  singleReviewData,
  replyText,
  setReplyText,
  replayComment,
  handleCopyResponse,
  handleReply,
  loading,
  buttonText,
  postButton,
  sourceAuthModal,
  setSourceAuthModal,
  openNav,
  reverseNav,
  drawerRef,
  replies,
  handleSelectResponse,
  chatLoading,
  botReply,
  handleChat,
  handleReviewReply,
  error,
  getprofile,
  userProfileImage,
  checkReply,
}) => {
  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={toggleDrawer}
      sx={{
        ".MuiDrawer-paper": {
          width: "90%",
          "@media(min-width:576px)": { width: "45%" },
        },
        position: "relative",
      }}
    >
      <MDBox id="drawer-toggle">
        <MDBox>
          <Grid container>
            <Grid item xs={12}>
              <MDBox
                sx={{
                  borderBottom: "1px solid #E1EAFF",
                  py: 2.5,
                  px: 3,
                }}
              >
                <MDTypography
                  sx={{
                    color: "#292929",
                    fontFamily: "Urbanist",
                    fontSize: "30px",
                    fontWeight: 700,
                    lineHeight: "36px",
                    "@media(max-width:991px)": {
                      fontSize: "22px",
                      textWrap: "wrap",
                      lineHeight: "26px",
                    },
                    "@media(max-width:375px)": {
                      fontSize: "22px",
                      textWrap: "wrap",
                      lineHeight: "26px",
                      textAlign: "center",
                    },
                  }}
                >
                  Review Details
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox sx={{ p: 3, "@media(max-width:991px)": { py: 0 } }}>
                <MDBox>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <MDBox
                        sx={{
                          py: 2,
                          width: "100%",
                          borderBottom: "1px solid #E1EAFF",
                        }}
                      >
                        <MDBox
                          sx={{
                            borderRight: "1px solid #E1EAFF",
                            "@media(max-width:991px)": {
                              textAlign: "center",
                              borderRight: "none",
                            },
                          }}
                        >
                          <MDTypography
                            sx={{
                              color: "#292929",
                              fontFamily: "Urbanist",
                              fontSize: "18px",
                              fontWeight: 600,
                              lineHeight: "21.6px",
                              "@media(max-width:991px)": {
                                textWrap: "wrap",
                              },
                            }}
                          >
                            Needs Attention
                          </MDTypography>
                          <MDTypography
                            className={
                              singleReviewData?.[0]?.needs_Attention === "yes"
                                ? "needs-attention-yes"
                                : "needs-attention-no"
                            }
                          >
                            {singleReviewData?.[0]?.needs_Attention === "no"
                              ? "No"
                              : "Yes"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <MDBox
                        sx={{
                          py: 2,
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: "1px solid #E1EAFF",
                        }}
                      >
                        <MDBox
                          sx={{
                            borderRight: "1px solid #E1EAFF",
                            "@media(max-width:991px)": {
                              borderRight: "none",
                            },
                            width: "100%",
                          }}
                        >
                          <MDTypography
                            sx={{
                              color: "#292929",
                              fontFamily: "Urbanist",
                              fontSize: "18px",
                              fontWeight: 600,
                              lineHeight: "21.6px",
                            }}
                          >
                            Source
                          </MDTypography>
                          <MDTypography
                            sx={{
                              color: "#7d7d7d",
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.2px",
                              marginTop: "5px",
                            }}
                          >
                            {singleReviewData?.[0]?.Source == "g"
                              ? "Google"
                              : singleReviewData?.[0]?.Source == "y"
                              ? "Yelp"
                              : singleReviewData?.[0]?.Source == "f"
                              ? "Facebook"
                              : singleReviewData?.[0]?.Source == "t"
                              ? "X"
                              : "N/A"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <MDBox
                        sx={{
                          py: 2,
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: "1px solid #E1EAFF",
                        }}
                      >
                        <MDBox sx={{ width: "100%" }}>
                          <MDTypography
                            sx={{
                              color: "#292929",
                              fontFamily: "Urbanist",
                              fontSize: "18px",
                              fontWeight: 600,
                              lineHeight: "21.6px",
                            }}
                          >
                            Rating
                          </MDTypography>
                          <MDTypography
                            sx={{
                              color: "#7d7d7d",
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.2px",
                              marginTop: "5px",
                            }}
                          >
                            {singleReviewData?.[0]?.Rating || "N/A"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <MDBox sx={{ py: 2, width: "100%" }}>
                        <MDBox
                          sx={{
                            borderRight: "1px solid #E1EAFF",
                            borderBottom: "none",
                            "@media(max-width:991px)": {
                              textAlign: "center",
                              borderRight: "none",
                              borderBottom: "1px solid #E1EAFF",
                              pb: 2,
                            },
                            width: "100%",
                          }}
                        >
                          <MDTypography
                            sx={{
                              color: "#292929",
                              fontFamily: "Urbanist",
                              fontSize: "18px",
                              fontWeight: 600,
                              lineHeight: "21.6px",
                            }}
                          >
                            Reply Sent
                          </MDTypography>
                          <MDTypography
                            sx={{
                              color: "#7d7d7d",
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.2px",
                              marginTop: "5px",
                            }}
                          >
                            {(singleReviewData?.[0]?.reply_sent
                              ? "Yes"
                              : checkReply
                              ? "Yes"
                              : "No") || "N/A"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <MDBox
                        sx={{
                          py: 2,
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: "1px solid #E1EAFF",
                        }}
                      >
                        <MDBox
                          sx={{
                            borderRight: "1px solid #E1EAFF",
                            "@media(max-width:991px)": {
                              borderRight: "none",
                            },
                            width: "100%",
                          }}
                        >
                          <MDTypography
                            sx={{
                              color: "#292929",
                              fontFamily: "Urbanist",
                              fontSize: "18px",
                              fontWeight: 600,
                              lineHeight: "21.6px",
                            }}
                          >
                            Date
                          </MDTypography>
                          <MDTypography
                            sx={{
                              color: "#7d7d7d",
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.2px",
                              marginTop: "5px",
                            }}
                          >
                            {singleReviewData?.[0]?.Date || "N/A"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <MDBox
                        sx={{
                          py: 2,
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "@media(max-width:991px)": { pb: 2, pt: 0 },
                        }}
                      >
                        <MDBox>
                          <MDTypography
                            sx={{
                              color: "#292929",
                              fontFamily: "Urbanist",
                              fontSize: "18px",
                              fontWeight: 600,
                              lineHeight: "21.6px",
                            }}
                          >
                            Author Name
                          </MDTypography>
                          <MDTypography
                            sx={{
                              color: "#7d7d7d",
                              fontFamily: "Urbanist",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "19.2px",
                              marginTop: "5px",
                            }}
                          >
                            {singleReviewData?.[0]?.author || "N/A"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox
                  sx={{
                    borderTop: "1px solid #E1EAFF",
                    borderBottom: "1px solid #E1EAFF",
                    py: 3,
                  }}
                >
                  <MDTypography
                    sx={{
                      mb: 1.5,
                      color: "#292929",
                      fontFamily: "Urbanist",
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "21.6px",
                    }}
                  >
                    Review
                  </MDTypography>
                  <MDTypography
                    sx={{
                      color: "#7d7d7d",
                      fontFamily: "Urbanist",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      textWrap: "wrap",
                    }}
                  >
                    {singleReviewData?.[0]?.Review || "N/A"}
                  </MDTypography>
                </MDBox>
                {checkComment || singleReviewData?.[0]?.reply_sent ? (
                  <>
                    <MDBox
                      sx={{
                        borderTop: "1px solid #E1EAFF",
                        borderBottom: "1px solid #E1EAFF",
                        py: 3,
                      }}
                    >
                      <MDTypography
                        sx={{
                          mb: 1.5,
                          color: "#292929",
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                          fontWeight: 600,
                          lineHeight: "21.6px",
                        }}
                      >
                        Reply
                      </MDTypography>
                      <MDTypography
                        sx={{
                          color: "#7d7d7d",
                          fontFamily: "Urbanist",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "22px",
                          textWrap: "wrap",
                        }}
                      >
                        {singleReviewData?.[0]?.comment
                          ? singleReviewData?.[0]?.comment
                          : replayComment
                          ? replayComment
                          : "N/A"}
                      </MDTypography>
                    </MDBox>
                  </>
                ) : (
                  <>
                    <MDBox sx={{ py: 3 }}>
                      <MDTypography
                        sx={{
                          mb: 1.5,
                          color: "#292929",
                          fontFamily: "Urbanist",
                          fontSize: "18px",
                          fontWeight: 600,
                          lineHeight: "21.6px",
                        }}
                      >
                        Reply
                      </MDTypography>
                      <TextField
                        multiline
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        placeholder=""
                        defaultValue=""
                        rows={4}
                        maxRows={Infinity}
                        sx={{ width: "100%" }}
                        helperText={error}
                      />
                    </MDBox>
                    <MDBox sx={{ mt: 1, textAlign: "end" }}>
                      {singleReviewData?.[0]?.Review && (
                        <MDButton
                          onClick={openNav}
                          elevation={0}
                          sx={{
                            cursor: "pointer",
                            borderRadius: "45px",
                            backgroundColor: "transparent",
                            fontFamily: "urbanist",
                            textTransform: "capitalize",
                            border: "2px solid #318BEC",
                            color: "#318BEC",
                            py: 0,
                            px: 2,
                            "&:hover": {
                              boxShadow:
                                "0rem 0.875rem 1.625rem -0.75rem rgba(26, 115, 232, 0.4), 0rem 0.25rem 1.4375rem 0rem rgba(26, 115, 232, 0.15), 0rem 0.5rem 0.625rem -0.3125rem rgba(26, 115, 232, 0.2)",
                            },
                          }}
                        >
                          <img
                            src={blueSparkles}
                            style={{ marginRight: "5px" }}
                            alt="icon"
                          />
                          Generate Response
                        </MDButton>
                      )}
                      {replyText && (
                        <>
                          <MDButton
                            onClick={() =>
                              handleCopyResponse(singleReviewData?.[0])
                            }
                            color="info"
                            elevation={0}
                            sx={{
                              cursor: "pointer",
                              borderRadius: "45px",
                              backgroundColor: "#318BEC",
                              fontFamily: "urbanist",
                              textTransform: "capitalize",
                              ml: 3,
                            }}
                            disabled={loading || replyText?.trim() === ""}
                          >
                            {buttonText == "Copied" ? "Copied" : "Copy"}
                          </MDButton>
                          {postButton && (
                            <MDButton
                              onClick={(e) =>
                                handleReply(singleReviewData?.[0])
                              }
                              color="info"
                              elevation={0}
                              sx={{
                                cursor: "pointer",
                                borderRadius: "45px",
                                backgroundColor: "#318BEC",
                                fontFamily: "urbanist",
                                textTransform: "capitalize",
                                ml: 3,
                              }}
                              disabled={loading || replyText?.trim() === ""}
                            >
                              Post
                            </MDButton>
                          )}
                        </>
                      )}
                    </MDBox>
                    {sourceAuthModal && (
                      <GoogleAuth
                        sourceModal={sourceAuthModal}
                        setSourceAuthModal={setSourceAuthModal}
                      />
                    )}
                  </>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <div className="overlay-drawer" id="drawerchat">
        <div className="overlay-drawer-inner">
          <MDBox
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #E1EAFF",
              py: 2.5,
              px: 3,
            }}
          >
            <MDBox
              sx={{
                display: "flex",
              }}
            >
              <img
                src={headingblueSparkles}
                style={{ marginRight: "7px" }}
                alt="icon"
              />
              <MDTypography
                sx={{
                  color: "#292929",
                  fontFamily: "Urbanist",
                  fontSize: "30px",
                  fontWeight: 700,
                  lineHeight: "36px",
                  "@media(max-width:991px)": {
                    fontSize: "22px",
                    textWrap: "wrap",
                    lineHeight: "26px",
                  },
                  "@media(max-width:375px)": {
                    fontSize: "22px",
                    textWrap: "wrap",
                    lineHeight: "26px",
                    textAlign: "center",
                  },
                }}
              >
                Generate Response
              </MDTypography>
            </MDBox>
            <div onClick={reverseNav}>
              <img
                src={drawerCross}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                }}
              />
            </div>
          </MDBox>
          <div className="mainChatAreaInner-drawer">
            <Paper
              sx={{ background: "transparent" }}
              elevation={0}
              className="chatting"
            >
              {replies?.length > 0 &&
                replies?.map((ele, index) => {
                  return (
                    <div key={index} ref={drawerRef}>
                      {ele?.sender && (
                        <div
                          className="chatItem"
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "15px",
                            position: "relative",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <div
                              className="senderName"
                              style={{ fontWeight: "bold" }}
                            >
                              You
                              <span className="time">a few seconds ago</span>
                            </div>
                            <div className="message">
                              <div
                                variant="body1"
                                style={{
                                  flexWrap: "wrap",
                                  textWrap: "wrap",
                                  display: "flex",
                                }}
                              >
                                {ele?.sender}
                              </div>
                              <div className="userImage">
                                <img
                                  className="senderImageSize"
                                  src={
                                    getprofile?.userProfile?.profile_picture && getprofile?.userProfile?.profile_picture.startsWith('https://')
                                    ? getprofile?.userProfile?.profile_picture
                                      : burceMars
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {ele?.receiver && (
                        <>
                          <div
                            className="chatItem"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginBottom: "0px",
                              position: "relative",
                              justifyContent: "flex-start",
                              maxWidth: "100%",
                            }}
                          >
                            <div style={{ marginBottom: "0px" }}>
                              <div
                                className="senderName"
                                style={{ fontWeight: "bold" }}
                              >
                                AI Assistant
                              </div>
                              <div
                                className="message"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleSelectResponse(e, index)}
                              >
                                <div
                                  variant="body1"
                                  style={{
                                    flexWrap: "wrap",
                                    textWrap: "wrap",
                                    display: "flex",
                                  }}
                                >
                                  {ele?.receiver}
                                </div>
                                <div className="userImage">
                                  <img
                                    className="senderImageSize"
                                    src={aiProfile}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              fontSize: "12px",
                              marginLeft: "350px",
                              marginTop: "5px",
                              marginBottom: "20px",
                            }}
                          >
                            Tap on response to select
                          </span>
                        </>
                      )}
                    </div>
                  );
                })}
            </Paper>
          </div>
          <div className="textmsg-outer">
            {chatLoading ? (
              <div className="loader-container">
                <div className="loader-bar"></div>
              </div>
            ) : null}
            <div className="textmsg-inner">
              <input
                placeholder="Message Review Attendant"
                className="drawer-textField"
                value={botReply}
                onChange={(e) => handleChat(e)}
                onKeyPress={(event) =>
                  event.key === "Enter" ? handleReviewReply() : null
                }
              />
              <button
                onClick={handleReviewReply}
                className="drawer-submitBtn"
                style={{ cursor: "pointer" }}
              >
                Generate
              </button>
            </div>
            <div className="drawer-copyright">
              <p>© 2024 Review Attendant. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ReviewDrawer;
